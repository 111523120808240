// base
import React from 'react'
import './operationsHistoryHeader.css';

// components
import AnalyticalCard from '../../common_components/analytical_card/AnalyticalCard';

// icons
import { TbTimelineEventFilled } from "react-icons/tb";

export default function OperationsHistoryHeader({ count }) {
  return (
    <div className='operations-history-header-component'>
      <div className='item'>
        <AnalyticalCard title={'States'} icon={TbTimelineEventFilled} value={count} isActive={true} onClick={() => {}} />
      </div>
    </div>
  )
}
