import axios from 'axios';
import config from '../../config';

async function fetchScalarData(url) {
  try {
    url = config.apiBaseUrl + url;

    const response = await axios.get(url);
    return handleSuccessResponse(response.status, response.data);

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      return handleErrorResponse(error.response.status);
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred.',
        data: null
      };
    }
  }
}

async function fetchMultipleData(url, pageNumber = null) {
  try {
    url = config.apiBaseUrl + url;

    if (pageNumber !== null)
      url += `/${pageNumber}`;

    const response = await axios.get(url);
    return handleSuccessResponse(response.status, response.data);

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      return handleErrorResponse(error.response.status);
    } else {
      return {
        success: false,
        message: 'An unexpected error occurred.',
        data: null
      };
    }
  }
}

async function patchData(url) {
  try {
    url = config.apiBaseUrl + url;

    const response = await axios.patch(url);
    return handleSuccessResponse(response.status, response.data);

  } catch (error) {
    if (axios.isAxiosError(error)) {
      // حالة استجابة من الخادم
      if (error.response) {
        return handleErrorResponse(error.response.status);
      } 
      // حالة عدم استجابة من الخادم (مثل عدم الاتصال بالإنترنت)
      else if (error.request) {
        return {
          success: false,
          message: 'No response received from the server. Please check your internet connection.',
          data: null
        };
      } 
      // حالة وجود خطأ في الشبكة
      else if (error.message === 'Network Error') {
        return {
          success: false,
          message: 'Network error. Please check your internet connection.',
          data: null
        };
      } 
      // معالجة الأخطاء الأخرى
      else {
        return {
          success: false,
          message: error.message,
          data: null
        };
      }
    } 
    // معالجة الأخطاء غير المعروفة
    return {
      success: false,
      message: 'An unexpected error occurred. Please try again later.',
      data: null
    };
  }
}

async function patchDataWithHeader(url, { headers } = {}) {
  try {
    url = config.apiBaseUrl + url;

    const response = await axios.patch(url, {}, { headers });
    return handleSuccessResponse(response.status, response.data);

  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        return handleErrorResponse(error.response.status);
      } 
      else if (error.request) {
        return {
          success: false,
          message: 'No response received from the server. Please check your internet connection.',
          data: null
        };
      } 
      else if (error.message === 'Network Error') {
        return {
          success: false,
          message: 'Network error. Please check your internet connection.',
          data: null
        };
      } 
      else {
        return {
          success: false,
          message: error.message,
          data: null
        };
      }
    } 
    return {
      success: false,
      message: 'An unexpected error occurred. Please try again later.',
      data: null
    };
  }
}


async function deleteData(url) {
  try {
    url = config.apiBaseUrl + url;

    const response = await axios.delete(url);
    return handleSuccessResponse(response.status, response.data);

  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        return handleErrorResponse(error.response.status);
      } 
      else if (error.request) {
        return {
          success: false,
          message: 'No response received from the server. Please check your internet connection.',
          data: null
        };
      } 
      else if (error.message === 'Network Error') {
        return {
          success: false,
          message: 'Network error. Please check your internet connection.',
          data: null
        };
      } 
      else {
        return {
          success: false,
          message: error.message,
          data: null
        };
      }
    } 
    return {
      success: false,
      message: 'An unexpected error occurred. Please try again later.',
      data: null
    };
  }
}

function handleSuccessResponse(status, response) {
  switch (status) {
    case 200:
      return {
        success: true,
        message: "Success.",
        data: { Response: response }
      };
    case 201:
      return {
        success: true,
        message: "Created successfully.",
        data: { Response: response }
      };
    default:
      // إذا كانت هناك حالة نجاح غير معروفة، إرجاع null
      return {
        success: true,
        message: "Success.",
        data: { Response: response }
      };
  }
}

function handleErrorResponse(status) {
  switch (status) {
    case 400:
      return {
        success: false,
        message: "Bad Request.",
        data: null
      };
    case 404:
      return {
        success: false,
        message: "Not Found.",
        data: null
      };
    case 500:
      return {
        success: false,
        message: "Internal Server Error.",
        data: null
      };
    default:
      // التعامل مع الحالات غير المتوقعة أو الغير معروفة
      return {
        success: false,
        message: "Unknown Error.",
        data: null
      };
  }
}

export {
  fetchScalarData,
  fetchMultipleData,
  patchData,
  patchDataWithHeader,
  deleteData
};
