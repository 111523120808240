import { ref, uploadBytes, getDownloadURL, deleteObject  } from 'firebase/storage';
import { storage } from '../../firebaseStorageConfig';

// for add new and update
async function uploadFileToFirebase(file, path) {
  try 
  {
    if (!file || !path)
      return null;

    const storageRef = ref(storage, path);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  }
  catch (error) 
  {
    console.error('فشل رفع الملف:', error);
    return null;
  }
}

// for delete
async function deleteFileFromFirebase(path) {
  try {
    if (!path) return null;

    const storageRef = ref(storage, path);
    await deleteObject(storageRef);
    return true;
  } catch (error) {
    console.error('فشل في حذف الملف:', error);
    return false;
  }
}

export { 
  uploadFileToFirebase,
  deleteFileFromFirebase
};
