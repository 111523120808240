// base
import React from 'react';
import './userDetails.css';
import { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';

// icons
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import MailIcon from '@mui/icons-material/Mail';

// logic
import { getDate } from '../../../global/utils/functions/utils';
import {
  getUserByIdAsync,
  getUserSessionsAnalyticsAsync,
  getActiveSubscriptionAsync,
  getPaymentsAsync
} from './userDetailsLogic';

export default function UserDetails() {
  const { id } = useParams(); // from route.
  
  const [user, setUser] = useState({ 
    userId: null, 
    fullName: null, 
    email: null, 
    userName: null,
    profileImageURL: null,
    isDeleted: null,
    registrationDate: null,
    branchName: null
  })
  
  const [sessionData, setSessionData] = useState({
    activeSessions: 0,
    inActiveSessions: 0,
    totalSessions: 0,
  });

  const [subscription, setSubscription] = useState({
    subscriptionId: null,
    paymentId: null,
    planName: null,
    price: null,
    discount: null,
    finalPrice: null,
    subscriptionStart: null,
    subscriptionEnd: null,
    isActive: null,
    userId: null
  })

  const [payments, setPayments] = useState([]);

  useEffect(() => {
    const loadUserAsync = async () => {
      const userData = await getUserByIdAsync(id);
      if (userData) {
        setUser(userData);
      } else {
        setUser({
          userId: null, 
          fullName: null, 
          email: null, 
          userName: null,
          profileImageURL: null,
          isDeleted: null,
          registrationDate: null,
          branchName: null
        });
      }
    };
  
    const getSessionData = async () => {
      const data = await getUserSessionsAnalyticsAsync(id);
    
      if (data) {
        setSessionData({
          activeSessions: data.activeSessions,
          inActiveSessions: data.inActiveSessions,
          totalSessions: data.totalSessions
        })
      } else {
        setSessionData({
          activeSessions: -1,
          inActiveSessions: -1,
          totalSessions: -1,
        })
      }
    }
    
    const getActiveSubscription = async () => {
      const data = await getActiveSubscriptionAsync(id);

      if (data) {
        setSubscription(data)
      }
      else {
        setSubscription({
          subscriptionId: null,
          paymentId: null,
          planName: null,
          price: null,
          discount: null,
          finalPrice: null,
          subscriptionStart: null,
          subscriptionEnd: null,
          isActive: null,
          userId: null
        });
      }
    }

    const getPayments = async () => {
      const data = await getPaymentsAsync(id);
      if (data) {
        setPayments(data)
      }
      else {
        setPayments([])
      }
    }

    loadUserAsync();
    getSessionData();
    getActiveSubscription(id);
    getPayments();

    if (!user)
      <Navigate to={'users'} />
  }, [id]);

  return (
    <div className='user-details-container'>
      <div className='user-details-sub-container'>

        <div className='user-info'>
          <div className='user-details-profile'>
            <div className='profile-image-container'>
              {user.profileImageURL ? (
                <img src={user.profileImageURL} alt='User Profile' className='profile-image' />
              ) : (
                <p className='profile-initials'>img</p>
              )}
            </div>
            <div className='user-info-1'>
              <h1>{user.fullName || 'Fullname'}</h1>
              
              <div className='item-container'>
                <AlternateEmailIcon fontSize=''/>
                <p className='username'>{user.userName || 'username'}</p>
              </div>

              <div className='item-container'>
                <MailIcon fontSize=''/>
                <p className='email'>{user.email || 'email'}</p>
              </div>
            </div>
          </div>

          <div className='user-info-2'>
            <div className='user-info-item'>
              <p>Status</p>
              <p>{user.isDeleted ? 'Deleted' : 'Active'}</p>
            </div>
            <div className='user-info-item'>
              <p>Branch</p>
              <p>{user.branchName || 'Branch'}</p>
            </div>
            <div className='user-info-item'>
              <p>Joined on</p>
              <p>{getDate(user.registrationDate)}</p>
            </div>
          </div>
        </div>

        <div className='sessions-analytic-container'>
          <div className='analytic-container-title'>
            <h3>Sessions</h3>
          </div>
          <div className='analytic-container-body'>
            <div className='analytic-item'>
              <h4>Total Sessions</h4>
              <p>{sessionData.totalSessions}</p>
            </div>
            <div className='analytic-item'>
              <h4>Active Sessions</h4>
              <p>{sessionData.activeSessions}</p>
            </div>
            <div className='analytic-item'>
              <h4>Inactive Sessions</h4>
              <p>{sessionData.inActiveSessions}</p>
            </div>
          </div>
        </div>

        <div className='subscription-container'>
          <div className='subscription-title'>
            <h3>Active Subscription</h3>
          </div>
          <div className='subscription-body'>
            <table className="subscription-table">
              <thead>
                <tr className="subscription-header">
                  <th>#</th>
                  <th>Plan</th>
                  <th>Price</th>
                  <th>Discount</th>
                  <th>Final Price</th>
                  <th>Start</th>
                  <th>End</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {subscription.planName ? (
                    <tr className="subscription-item">
                    <td>1</td>
                    <td>{subscription.planName}</td>
                    <td>{subscription.price}</td>
                    <td>{subscription.discount}</td>
                    <td>{subscription.finalPrice}</td>
                    <td>{getDate(subscription.subscriptionStart)}</td>
                    <td>{getDate(subscription.subscriptionEnd)}</td>
                    <td><p className={subscription.isActive ? 'active' : 'in-active'}>{subscription.isActive ? 'Active' : 'Inactive'}</p></td>
                  </tr>
                ) : (
                <tr>
                  <td colSpan="8" style={{ textAlign: 'center' }}>
                    No subscriptions available.
                  </td>
                </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className='payments-container'>
          <div className='payments-title'>
            <h3>Payments</h3>
          </div>
          <div className='payments-body'>
            <table className="payments-table">
              <thead>
                <tr className="payments-header">
                  <th>#</th>
                  <th>Amount</th>
                  <th>Currency</th>
                  <th>Date</th>
                  <th>Method</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {payments.length === 0 ? (
                  <tr>
                    <td colSpan="6" style={{ textAlign: 'center' }}>
                      No payments available.
                    </td>
                  </tr>
                ) : (
                  payments.map((payment, index) => (
                    <tr key={payment.paymentId} className="payments-item">
                      <td>{index + 1}</td>
                      <td>{payment.amount}</td>
                      <td>{payment.currency}</td>
                      <td>{getDate(payment.paymentDate)}</td>
                      <td>{payment.paymentMethod}</td>
                      <td>{payment.description || '~'}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
  );
}