// base
import React from 'react'

// context
import { DeletionsProvider } from './deletionsContext'

// components
import DeletionsMain from '../../components/deletions_components/deletions_main/DeletionsMain';

export default function Deletions() {
  return (
    <DeletionsProvider>
      <DeletionsMain />
    </DeletionsProvider>
  )
}
