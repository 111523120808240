import React from 'react'
import './containedButton.css';

export default function ContainedButton({ title, onclickHandler, type = 'primary' }) {

  const onClick = () => {
    onclickHandler();
  }
  return (
    <div className='contained-button-component'>
      <button className={`contained-button-input ${type}`} onClick={onClick}>{title}</button>
    </div>
  )
}
