// base
import React from 'react'
import './operationsHistoryBody.css';

// components
import OperationsHistoryTimeline from '../operations_history_timeline/OperationsHistoryTimeline';

export default function OperationsHistoryBody({ transactions }) {
  return (
    <div className='operations-history-body-component'>
      <OperationsHistoryTimeline transactions={transactions} />
    </div>
  )
}
