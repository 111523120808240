import React, { useState, useContext } from 'react';
import './usersSearchbar.css';
import { UsersContext } from '../../../pages/users/users/usersContext';
import SearchInput from '../../inputs/search_input/SearchInput';
import Selector from '../../inputs/selector/Selector';
import {
  UsersModeEnum,
  getActiveUsersPagesCountAsync,
  getDeletionUsersPagesCountAsync,
  getActiveUsersAsync,
  getDeletionUsersAsync,
  getUsersByFullNameAsync,
  getUsersByUserNameOrEmailAsync
} from '../../../pages/users/users/usersLogic';

export default function UsersSearchbar() {
  const { 
    usersMode,
    setUsers,
    setUsersPagesCount,
    setCurrentPageNumber,
    setLoading
  } = useContext(UsersContext);

  const [searchValue, setSearchValue] = useState('');
  const [searchBy, setSearchBy] = useState({ value: 'fullname', label: 'Fullname' });

  const options = [
    { value: 'fullname', label: 'Fullname' },
    { value: 'emailOrUserName', label: 'Email or username' }
  ];

  const handleOnSearchValueChange = (newValue) => {
    setSearchValue(newValue);
    handleReset(newValue);
  }

  const handleReset = async (newValue) => {
    if (newValue.length === 0) {
      setCurrentPageNumber(1);
      if (usersMode === UsersModeEnum.ACTIVE_USERS) {
        setUsersPagesCount(await getActiveUsersPagesCountAsync());
        setUsers(await getActiveUsersAsync(1));
      } else if (usersMode === UsersModeEnum.DELETED_USERS) {
        setUsersPagesCount(await getDeletionUsersPagesCountAsync());
        setUsers(await getDeletionUsersAsync(1));
      }
    }
  }

  const handleOnSearchEnter = async () => {
    if (searchValue.length === 0) return;

    let users;
    if (searchBy.value === 'fullname') {
      users = await getUsersByFullNameAsync(searchValue, setLoading);
    } else if (searchBy.value === 'emailOrUserName') {
      users = await getUsersByUserNameOrEmailAsync(searchValue, setLoading);
    }
    setUsers(users);
  }

  const handleSearchBy = (option) => {
    setSearchBy(option);
  }

  return (
    <div className='users-search-bar-component'>
      <div className='users-search-input-container'>
        <SearchInput 
          placeholder={searchBy.label} 
          value={searchValue} 
          onChange={(newValue) => handleOnSearchValueChange(newValue)} 
          onEnter={handleOnSearchEnter} 
        />
      </div>
      <div className='users-selector-container'>
        <Selector title={'search by'} options={options} value={searchBy.value} onSelect={handleSearchBy} />
      </div>
    </div>
  )
}
