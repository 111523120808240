// base
import React from 'react'

// context
import { UpdatesProvider } from './updatesContext'

// components
import UpdatesMain from '../../components/updates_components/updates_main/UpdatesMain';

export default function Deletions() {
  return (
    <UpdatesProvider>
      <UpdatesMain />
    </UpdatesProvider>
  )
}
