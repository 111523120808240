// base
import React from 'react'

// context
import { ErrorsProvider } from './errorsContext'

// components
import ErrorsMain from '../../../components/errors_components/errors_main/ErrorsMain';

export default function Errors() {
  return (
    <ErrorsProvider>
      <ErrorsMain />
    </ErrorsProvider>
  )
}
