// base
import React from 'react'
import './transactionsHeader.css';

// components
import AnalyticalCard from '../../common_components/analytical_card/AnalyticalCard';

// icons
import { IoMdAddCircle } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";

// logic 
import {
  TransactionTypes
} from '../../../pages/transactions/transactions/transactionsLogic'

export default function TransactionsHeader({ count, type }) {
  return (
    <div className='transactions-header-component'>
      <div className='item'>
        <AnalyticalCard 
          title={
            type === TransactionTypes.ADDITIONS ? 'Additions' :
            type === TransactionTypes.DELETIONS ? 'Deletions' :
            type === TransactionTypes.UPDATES ? 'Updates' : 'Transactions'
          } 
          icon={
            type === TransactionTypes.ADDITIONS ? IoMdAddCircle :
            type === TransactionTypes.DELETIONS ? MdDelete :
            type === TransactionTypes.UPDATES ? MdEdit : MdDelete
          } 
          value={count === undefined || count === null ? '...' : count } 
          isActive={true} 
          onClick={() => {}} 
        />
      </div>
    </div>
  )
}
