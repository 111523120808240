import React from 'react';
import './loading.css';

export default function Loading({ isVisible = false }) {
  if (!isVisible) return null;
  
  return (
    <div className={`loader-container ${isVisible ? 'visible' : ''}`}>
      <div className="dot-loader">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
      {/* <p className="loading-text">Loading...</p> */}
    </div>
  );
}
