// base
import React from 'react'
import './adminsSearchBar.css'
import { useState } from 'react'

// components
import SearchInput from '../../inputs/search_input/SearchInput'
import Selector from '../../inputs/selector/Selector'

// logic
import { GetAllAdminsAsync } from './adminsSearchBarLogic';

export default function AdminsSearchBar({ adminsState, loadAllAdmins, setLoader }) {
  
  const [searchValue, setSearchValue] = useState('');
  const [searchBy, setSearchBy] = useState({ value: 'fullname', label: 'Fullname' })
  const options = [
    { value: 'fullname', label: 'Fullname' },
    { value: 'emailOrUserName', label: 'Email or username' }
  ];

  const handleOnSearchValueChange = (newValue) => {
    setSearchValue(newValue);
    handleReset(newValue);
  }
  
  const handleReset = (newValue) => {
    if (newValue.length === 0) {
      loadAllAdmins();
    }
  }
  
  async function handleOnSearchEnter() {
    if (searchValue.length === 0)
      return;

    await GetAllAdminsAsync(adminsState, searchValue, searchBy.value, setLoader)
  }

  const handleSearchBy = (option) => {
    setSearchBy(option);
  }

  return (
    <div className='admins-search-bar-component'>
      
      <div className='search-input-container'>
        <SearchInput placeholder={searchBy.label} value={searchValue} onChange={handleOnSearchValueChange} onEnter={handleOnSearchEnter} />
      </div>

      <div className='selector-container'>
        <Selector title={'search by'} options={options} value={searchBy.value}  onSelect={handleSearchBy}/>
      </div>
    </div>
  )
}
