// base
import React from 'react';
import './generalQuestionsHeader.css';

// logic
import {
  GeneralQuestionsMode
} from '../../../pages/general_questions/generalQuestionsLogic';

// components
import AnalyticalCard from '../../common_components/analytical_card/AnalyticalCard';
import AdditionCard from '../../common_components/addition_card/AdditionCard';

// icons
import { FaQuestion } from "react-icons/fa";


export default function GeneralQuestionsHeader({ count, setMode }) {
  return (
    <div className='general-question-header-component'>

      <div className={`general-questions-card-container`} onClick={() => {setMode(GeneralQuestionsMode.ADD)}}>
        <AdditionCard />
      </div>

      <div className={`general-questions-card-container`}>
        <AnalyticalCard 
          title={'General Questions'} 
          icon={FaQuestion} 
          value={count} 
          isActive={true} 
          onClick={() => {}} 
        />
      </div>

    </div>
  )
}
