import React from 'react'
import './sideBarSection.css'
import { useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function SideBarSection({ title, icon: Icon, children }) {

  const [sectionStatus, setSectionStatus] = useState(false);

  function HandleSectionStatus() {
    setSectionStatus(!sectionStatus);
  }

  return (
    <ul className='sidebar-section-container'>
      
      <li 
        className={`sidebar-section-title ${sectionStatus ? 'active' : ''}`} 
        onClick={HandleSectionStatus}
        >
        
        <div>
          {<Icon />}
          <span>{title}</span>
        </div>

        <KeyboardArrowDownIcon className={`${sectionStatus ? 'in-active-section-arrow' : ''}`} />

      </li>

      <ul className={`section-items-container ${sectionStatus ? '' : 'in-active'}`}>
        {children}
      </ul>
    </ul>
  )
}