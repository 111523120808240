// base
import React from 'react'
import './description.css'
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// material ui icons
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';

// components
import Model from '../../../model/Model';

// logic
import {
  handleDescription,
  setDescriptionAsync
  } from './descriptionLogic'

export default function Description({ adminId, description, reload, reloadState }) {
  
  const navigate = useNavigate();

  const currentAdminId = useSelector((state) => state.currentUser.adminId);
  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);
  
  const [loader, setLoader] = useState(false);
  const [isWriteMode, setWriteMode] = useState(false);
  const [message, setMessage] = useState({title: '', message: '', status: false});
  const [descriptionValue, setDescriptionValue] = useState(handleDescription(description));

  useEffect(() => {
    setDescriptionValue(handleDescription(description));
  }, [description]);

  return (
    <div className='description-component'>
      <Model title={message.title} message={message.message} status={message.status} setStatus={setMessage} />

      <div className='description-header-container'>
        
        <p className='description-header-label'>Description</p>

        <div className='header-icon-container'>
          {isWriteMode ? 
            <CloseIcon className='edit-description-icon' fontSize='' onClick={() => {setWriteMode(false)}} />
          : 
            <EditIcon className='edit-description-icon' fontSize='' onClick={() => {setWriteMode(true)}} />
          }
        </div>

      </div>

      <div className='description-body-container'>

        <p className='description-value'>
          {handleDescription(description)}
        </p>

        <div className={`set-description-container ${isWriteMode ? 'write-mode' : ''}`}>
          
          <textarea 
            className='set-description' 
            placeholder='Description' 
            maxLength={250} 
            rows="4" 
            value={descriptionValue} 
            onChange={(e) => {setDescriptionValue(e.target.value)}}
          />

          <div className='description-done-container'>
            <p className={`description-done ${loader ? 'loading' : ''}`} onClick={async () => {
              await setDescriptionAsync(adminId, descriptionValue, currentAdminId, currentAdminPermissions, reload, reloadState, setLoader, setMessage, navigate);
            }}>
              Done
            </p>
            <div className={`set-description-loader ${loader ? 'active' : ''}`}></div>
          </div>

        </div>
      </div>

    </div>
  )
}
