import config from '../../../global/config';
import axios from 'axios';
import { isPasswordValid } from '../../../global/utils/validation';

function validatePassword(input) {
  return isPasswordValid(input);
}

function onChangeHandler(field, newValue, state) {
  state(prevState => ({
    ...prevState,
    [field]: newValue
  }));
}

function setMessageState(isVisible, message, type, messageState) {
  messageState({ isVisible, message, type })
}

function setLoaderState(isVisible, loaderState) {
  loaderState(isVisible)
}

function handlePreviousClick(stepsState) {
  stepsState('2')
}

async function GetUserIdAsync(email, messageState, loaderState) {
  try
  {
    setLoaderState(true, loaderState)
    setMessageState(false, '', 'success', messageState);
    
    const url = `${config.apiBaseUrl}/Admins/GetAdminByUserNameOrEmail/${email}`;
    const response = await axios.get(url);
    
    return response.data.data.admin.adminId
  }
  catch (error) 
  {
    if (error.response) 
      {
      switch (error.response.status) 
      {
        case 404:
          setMessageState(true, 'The email you entered is incorrect. Please try again.', 'error', messageState);
          break;
        case 500:
          setMessageState(true, 'There is a problem with the server. Please try again later.', 'error', messageState);
          break;
        default:
          setMessageState(true, 'An unexpected error occurred: ' + error.response.status, 'error', messageState);
          break;
      }
    } 
    else 
    {
      setMessageState(true, 'Network error. Please check your internet connection and try again.', 'error', messageState);
    }
  }
  finally
  {
    setLoaderState(false, loaderState)
  }
}

async function SetUserPasswordAsync(userId, password, messageState, loaderState) {
  try {
    setLoaderState(true, loaderState);
    setMessageState(false, '', 'success', messageState);
    
    const url = `${config.apiBaseUrl}/Admins/SetAdminPassword/${userId}/${encodeURIComponent(password)}`;
    
    const response = await fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      setMessageState(true, errorData.message || 'An error occurred.', 'error', messageState);
      return;
    }

    const responseData = await response.json();
    return responseData.data.response === true;

  } catch (error) {
    setMessageState(true, 'An unexpected error occurred. Please try again.', 'error', messageState);
  } finally {
    setLoaderState(false, loaderState);
  }
}

async function handleChangeClickAsync(email, password, confirmPassword, messageState, loaderState) {

  if (!validatePassword(password)){
    setMessageState(true, 'Invalid password.', 'error', messageState);
    return;
  }

  if (!validatePassword(confirmPassword)){
    setMessageState(true, 'Invalid password.', 'error', messageState);
    return;
  }

  if (password !== confirmPassword) {
    setMessageState(true, 'Passwords do not match. Please try again.', 'error', messageState);
    return;
  }

  const userId = await(GetUserIdAsync(email, messageState, loaderState));
  
  if(userId > 0 && await SetUserPasswordAsync(userId, password, messageState, loaderState)){
    return true;
  }
}


export {
  validatePassword,
  onChangeHandler,
  handlePreviousClick,
  handleChangeClickAsync
}