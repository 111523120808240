// base
import React, { useContext } from 'react';
import './deletionsMain.css';

// context
import { DeletionsContext } from '../../../pages/deletions/deletionsContext';

// logic
import {
  resetMessage
} from '../../../pages/deletions/deletionsLogic';

// components
import DeletionsHeader from '../deletions_header/DeletionsHeader';
import DeletionBody from '../deletion_body/DeletionBody';
import Model  from '../../model/Model';
import MessageBox from '../../message_box/MessageBox';
import ContainedButton from '../../inputs/buttons/contained_button/ContainedButton';
import Loading from '../../loading/Loading';
import Pagination from '@mui/material/Pagination';

export default function DeletionsMain() {
  const { 
    modelMessage, 
    setModelMessage,
    messageBox,
    setMessageBox,
    loading,
    currentPageNumber,
    setCurrentPageNumber,
    deletionsPagesCount,
  } = useContext(DeletionsContext);

  const handlePaginationChange = (event, value) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setCurrentPageNumber(value)
  }

  return (
    <div className='deletions-main-component'>
      
      <Model 
        title={modelMessage.title} 
        message={modelMessage.message} 
        status={modelMessage.isVisible} 
        setStatus={setModelMessage} 
      />

      <MessageBox 
        title={messageBox.title} 
        message={messageBox.message} 
        isVisable={messageBox.isVisible} 
        onClose={() => {resetMessage(setMessageBox)}}
      >
        <div className='deletions-main-confirm-btn-container'>
          <ContainedButton title={'Confirm'} onclickHandler={() => {
            resetMessage(setMessageBox);
            // invoke method to do action.
          }} />
        </div>
      </MessageBox>

      <div className={`loading-container ${loading ? 'active' : ''}`}>
        <Loading isVisible={loading} />
      </div>

      <div className='deletions-header-container'>
        <DeletionsHeader />
      </div>

      <div className='deletions-body'>
        <DeletionBody />
      </div>

      <div className={`deletions-pagination-container ${deletionsPagesCount <= 0 ? 'hidden' : ''}`}>
        <Pagination 
          count={deletionsPagesCount} 
          page={currentPageNumber}
          variant="outlined" 
          shape="rounded" 
          color="primary" 
          showFirstButton 
          showLastButton 
          size="small"
          onChange={handlePaginationChange}
        />
      </div>

    </div>
  )
}
