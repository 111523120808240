import React, { createContext, useState } from 'react';
import { UpdatesModeEnum } from './updatesLogic';

export const UpdatesContext = createContext();

export const UpdatesProvider = ({ children }) => {

  const [updates, setUpdates] = useState([]);
  const [updatesCount, setUpdatesCount] = useState(0);
  const [updatesPagesCount, setUpdatesPagesCount] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [updatesMode, setUpdatesMode] = useState(UpdatesModeEnum.ALL_UPDATES);
  const [tableId, setTableId] = useState(null);
  const [tables, setTables] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modelMessage, setModelMessage] = useState({ title: '', message: '', isVisible: false });
  const [messageBox, setMessageBox] = useState({ title: '', message: '', isVisible: false });
  const [reload, setReload] = useState(false);

  return (
  <UpdatesContext.Provider
    value={{
      updates,
      setUpdates,
      updatesCount,
      setUpdatesCount,
      updatesPagesCount,
      setUpdatesPagesCount,
      currentPageNumber,
      setCurrentPageNumber,
      updatesMode,
      setUpdatesMode,
      tableId, 
      setTableId,
      tables,
      setTables,
      loading,
      setLoading,
      modelMessage,
      setModelMessage,
      messageBox,
      setMessageBox,
      reload,
      setReload
    }}
  >
      {children}
  </UpdatesContext.Provider>
  );
};
