// base
import React, { useState, useEffect } from 'react';
import './generalQuestionsBody.css';

// components
import GeneralQuestionCard from '../general_question_card/GeneralQuestionCard';

// logic
import {
  getAllGeneralQuestions
} from '../../../pages/general_questions/generalQuestionsLogic'


export default function GeneralQuestionsBody({ setQuestionIdForDelete, setQuestionForUpdate, setMode, setLoading, reload, setMessageBox, setGeneralQuestionsCount }) {
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    const fetchData = async() => {
      await getGeneralQuestions();
    }

    fetchData();
  }, [reload]);

  async function getGeneralQuestions() {
    try {
      setLoading(true);
      const data = await getAllGeneralQuestions();
      setQuestions(data);
      setGeneralQuestionsCount(data.length)
    } catch (error) {
      console.error('Error fetching general questions:', error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className='general-questions-body-component'>

      {questions.length > 0 ? (
        questions.map((question, index) => (
          <div key={question.questionId || index} className='general-question-card-container'>
            <GeneralQuestionCard
              question={question}
              _index={index + 1}
              setQuestionIdForDelete={setQuestionIdForDelete}
              setQuestionForUpdate={setQuestionForUpdate}
              setMode={setMode}
              messaegState={setMessageBox}
            />
          </div>
        ))
      ) : (
        <div className="no-questions-message">
          No general questions found.
        </div>
      )}

    </div>
  )
}
