import axios from 'axios';

import { 
  fetchMultipleData,
  deleteData
} from '../../global/utils/functions/apiService';

import {
  checkLength
} from '../../global/utils/validation';

import config from '../../global/config';

const GeneralQuestionsMode = Object.freeze({
  READ: 'read',
  ADD: 'add',
  UPDATE: 'update'
});

function resetMessage(state) {
  state({
    title: '',
    message: '',
    isVisible: false
  });
}

function handleInputChange(fieldName, value, setState) {
  setState((prev) => ({
    ...prev,
    [fieldName]: value
  }));
}

function validateQuestion(title) {
  return checkLength(1, 500, title);
}

function validateAnswer(question) {
  return question.length > 0;
}

async function newQuestionAsync(question, byAdmin, currentAdminPermissions, setMessage) {
  try {
    if ((currentAdminPermissions & 1024) !== 1024)
      return;
    
    const url = `${config.apiBaseUrl}/GeneralQuestions/NewGeneralQuestion`;

    const response = await axios.post(url, {
      question: question.question,
      answer: question.answer,
      byAdmin: byAdmin
    });

    if (response.status === 201) {
      setMessage({
        message: 'Question inserted successfully.',
        type: 'success',
        isVisible: true
      });
      return true;
    } else {
      setMessage({
        message: 'Failed to insert new question.',
        type: 'error',
        isVisible: true
      });
      return false;
    }
  } catch (error) {
    setMessage({
      message: `${error.response?.data?.message}` || 'An error occurred while processing your request.',
      type: 'error',
      isVisible: true
    });
    return false;
  }
};

async function updateQuestionAsync(question, byAdmin, currentAdminPermissions, setMessage) {
  try {
    if ((currentAdminPermissions & 1024) !== 1024)
      return;
    
    const url = `${config.apiBaseUrl}/GeneralQuestions/UpdateGeneralQuestion`;

    const response = await axios.put(url, {
      questionId: question.questionId,
      question: question.question,
      answer: question.answer,
      byAdmin: byAdmin
    });

    if (response.status === 200) {
      setMessage({
        message: 'Question updated successfully.',
        type: 'success',
        isVisible: true
      });
      return true;
    } else {
      setMessage({
        message: 'Failed to update question.',
        type: 'error',
        isVisible: true
      });
      return false;
    }
  } catch (error) {
    setMessage({
      message: `${error.response?.data?.message}` || 'An error occurred while processing your request.',
      type: 'error',
      isVisible: true
    });
    return false;
  }
};

async function getAllGeneralQuestions() {
  const url = '/GeneralQuestions/GetAllGeneralQuestions';

  const response = await fetchMultipleData(url);
  if (response.success) {
    return response.data.Response.data.generalQuestions;
  } else {
    console.log(response.message);
    return []
  }
}

async function deleteGeneralQuestionAsync(
  questionId, currentAdminId, currentAdminPermissions, messageState,
  loaderState, reload, setReload,
  ) {
  try {

    if ((currentAdminPermissions & 1024) !== 1024) {
      messageState({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        status: true,
      });
      return;
    }

    loaderState(true);
    const response = await deleteData(`/GeneralQuestions/DeleteGeneralQuestion/${questionId}/${currentAdminId}`);
    if (response.success && response.data.Response.data.response) {
      messageState({
        title: 'Success',
        message: 'General question has been deleted successfully.',
        isVisible: true
      });
      return true;
    } else {
      messageState({
        title: 'Error',
        message: response.message || 'Failed to delete general question. Please check your permissions and try again.',
        isVisible: true
      });
      return false;
    }
  } catch (error) {
    messageState({
      title: 'Error',
      message: error.message || 'An unexpected error occurred while deleting the general question. Please try again later.',
      isVisible: true
    });
    return false;
  } finally {
    loaderState(false);
    setReload(!reload);
  }
}

export {
  GeneralQuestionsMode,
  resetMessage,
  handleInputChange,
  validateQuestion,
  validateAnswer,
  newQuestionAsync,
  updateQuestionAsync,
  getAllGeneralQuestions,
  deleteGeneralQuestionAsync
};