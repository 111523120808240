import React from 'react'
import './messageLabel.css';

export default function MessageLabel({ message, type = 'success', isVisible = true }) {
  return (
    <span className={`message-label-component ${isVisible ? '' : 'not-visible'} ${type}`}>
      {message}
    </span>
  )
}
