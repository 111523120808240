import React from 'react'
import './error.css';
import { useNavigate } from 'react-router-dom';
import ContainedButton from '../../components/inputs/buttons/contained_button/ContainedButton';

export default function Error() {

  const navigate = useNavigate();

  const onclickHandler = () => {
    navigate('/home');
  }

  return (
    <div className='error-container'>
      
      <h2 className='error-primary-title'>404</h2>
      <h3 className='error-secondary-title'>Page Not Found‼️</h3>

      <p className='error-description'>We couldn't find the page you are looking for.</p>

      <div className='button-container'>
        <ContainedButton title={'Back To Home'} onclickHandler={onclickHandler} />
      </div>

    </div>
  )
}
