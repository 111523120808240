import { fetchScalarData, fetchMultipleData } from '../../../global/utils/functions/apiService';

async function AllAdminsCountAsync() {
  const result = await fetchScalarData('/Admins/GetAdminsCount');

  if (result.success) {
    return result.data.Response.data.count;
  } else {
    console.error('Error fetching all admins count:', result.message);
    return 0;
  }
}

async function DeletedAdminsCountAsync() {
  const result = await fetchScalarData('/Admins/GetDeletionAdminsCount');

  if (result.success) {
    return result.data.Response.data.count;
  } else {
    console.error('Error fetching deleted admins count:', result.message);
    return 0;
  }
}

async function BlockedAdminsCountAsync() {
  const result = await fetchScalarData('/Admins/GetInActiveAdminsCount');

  if (result.success) {
    return result.data.Response.data.count;
  } else {
    console.error('Error fetching blocked admins count:', result.message);
    return 0;
  }
}

async function GetAllAdminsPagesCountAsync(pagesCountState) {
  const url = '/Admins/GetAllAdminsPagesCount';

  const response = await fetchScalarData(url);
  
  if (response.success) {
    pagesCountState(response.data.Response.data.count);
  } else {
    pagesCountState(0)
    console.error(response.message);
  }
}

async function GetAllAdminsAsync(adminsState, pageNumber) {
  const url = '/Admins/GetAllAdmins';

  const response = await fetchMultipleData(url, pageNumber);
  
  if (response.success) {
    adminsState(response.data.Response.data.admins);
  } else {
    adminsState([])
    console.error(response.message);
  }
}

async function GetDeletionAdminsPagesCountAsync(pagesCountState) {
  const url = '/Admins/GetDeletionAdminsPagesCount';

  const response = await fetchScalarData(url);

  if (response.success) {
    pagesCountState(response.data.Response.data.count);
  } else {
    pagesCountState(0)
    console.error(response.message);
  }
}

async function GetDeletionAdminsAsync(adminsState, pageNumber) {
  const url = '/Admins/GetDeletionAdmins';

  const response = await fetchMultipleData(url, pageNumber);
  
  if (response.success) {
    adminsState(response.data.Response.data.admins);
  } else {
    adminsState([])
    console.error(response.message);
  }
}

async function GetBlockedAdminsPagesCountAsync(pagesCountState) {
  const url = '/Admins/GetInActivesAdminsPagesCount';

  const response = await fetchScalarData(url);
  if (response.success) {
    pagesCountState(response.data.Response.data.count);
  } else {
    pagesCountState(0)
    console.error(response.message);
  }
}

async function GetBlockedAdminsAsync(adminsState, pageNumber) {
  const url = '/Admins/GetInActivesAdmins';

  const response = await fetchMultipleData(url, pageNumber);
  
  if (response.success) {
    adminsState(response.data.Response.data.admins);
  } else {
    adminsState([])
    console.error(response.message);
  }
}

export {
  AllAdminsCountAsync,
  DeletedAdminsCountAsync,
  BlockedAdminsCountAsync,
  GetAllAdminsPagesCountAsync,
  GetAllAdminsAsync,
  GetDeletionAdminsPagesCountAsync,
  GetDeletionAdminsAsync,
  GetBlockedAdminsPagesCountAsync,
  GetBlockedAdminsAsync
}