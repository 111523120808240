import React from 'react';
import './profileDropDown.css';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { setCurrentUser } from '../../../../global/data/global_data';
import { removeCurrentAdminFromSession } from '../../../../global/utils/session_storage/sessionStorage';

import ProfileDropDownItem from '../profile_dropdown_item/ProfileDropDownItem';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import LogoutIcon from '@mui/icons-material/Logout';
import { MdHelpCenter } from "react-icons/md";

export default function ProfileDropDown({ status, itemOnClickHandler }) {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const adminId = useSelector((state) => state.currentUser.adminId);
  const fullName = useSelector((state) => state.currentUser.fullName);
  const email = useSelector((state) => state.currentUser.email);

  return (
    <ul className={`profile-dropdown-container ${status ? 'active' : 'in-active'}`}>

      <div className='profile-user-info'>
        <h4 className='profile-fullname'>{fullName}</h4>
        <p className='profile-email'>{email}</p>
      </div>

      <Link to={`${adminId ? `/admins/${adminId}` : '/login'}`} className='item-container'>
        <ProfileDropDownItem title={'My Profile'} path={'/home'} borderTop={true} itemOnClickHandler={itemOnClickHandler}>
          <AccountBoxIcon className='profile-dropdown-item-icon'/>
        </ProfileDropDownItem>
      </Link>

      <Link to={'/reset-password'} className='item-container' >
        <ProfileDropDownItem title={'Forget Password'} path={'/reset-password'} borderTop={true} itemOnClickHandler={itemOnClickHandler}>
            <MdHelpCenter className='profile-dropdown-item-icon'/>
          </ProfileDropDownItem>
      </Link>

      <Link to={'/login'} className='item-container'>
        <ProfileDropDownItem title={'Logout'} path={'/login'} borderTop={true} itemOnClickHandler={() => {
          itemOnClickHandler();
          removeCurrentAdminFromSession();
          dispatch(setCurrentUser(''));
          navigate('/login');
        }}>
          <LogoutIcon className='profile-dropdown-item-icon'/>
        </ProfileDropDownItem>
      </Link>

    </ul>
  )
}
