import React from 'react'
import './passwordInput.css'
import { useState } from 'react';
import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export default function PasswordInput({ title, value, onChangeHandler, validateInput, tooltipTitle, onEnter }) {
  const [error, setError] = useState('');
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const passwordId = Math.floor(Math.random() * (1 - 1000 + 1)) + 1;

  const onChange = (e) => {
    let newValue = e.target.value;

    if (validateInput(newValue)) {
      setError('')
    }
    else {
      if (newValue.length < 8)
        setError('Invalid password min length is 8 characters.')
      else if (newValue.length > 25)
        setError('Invalid password max length is 25 characters.')
    }

    onChangeHandler(newValue)
  }

  const togglePasswordVisibility = () => {
    setPasswordVisible(!isPasswordVisible);
  };

  const handleEnterDown = (e) => {
    if (e.key === 'Enter' && onEnter)
      onEnter();
  }

  return (
    <div className='password-input-component'>
      
      <label 
        className='password-title'
        htmlFor={passwordId}>
          
          <div>
            {title} 
            <span> *</span> 
          </div>
          
        { 
          tooltipTitle ? 
          (
            <Tooltip title={tooltipTitle}>
              <InfoIcon fontSize='small'/>
            </Tooltip>
          )
          : 
          null
        }

      </label>

      <div className='password-input-container'>
        
        <input id={passwordId} className='password-input'
            type={isPasswordVisible ? 'text' : 'password'} 
            placeholder={`Enter password`} 
            value={value}
            onChange={onChange}
            onKeyDown={handleEnterDown}
          />

        <span className='toggle-password-visibility' onClick={togglePasswordVisibility}>
          {isPasswordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </span>

      </div>

      <span className='error-label'>{error}</span>

    </div>  
  )
}
