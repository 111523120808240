import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  adminId: null,
  permissions: null,
  description: null,
  fullName: null,
  email: null,
  userName: null,
  password: null,
  profileImageURL: null,
  profileImageName: null,
  registrationDate: null
}

const currentUser_Reducer = createSlice({
  name: 'current_user',
  initialState: initialState,
  reducers: {
    setCurrentUser: (currentState, action) => {
      currentState.adminId = action.payload.adminId;
      currentState.permissions = action.payload.permissions;
      currentState.description = action.payload.description;
      currentState.fullName = action.payload.fullName;
      currentState.email = action.payload.email;
      currentState.userName = action.payload.userName;
      currentState.password = action.payload.password;
      currentState.profileImageURL = action.payload.profileImageURL;
      currentState.profileImageName = action.payload.profileImageName;
      currentState.registrationDate = action.payload.registrationDate;
    },
    updatePassword: (currentState, action) => {
      currentState.password = action.payload;
    }
  }
})

export const { setCurrentUser, updatePassword } = currentUser_Reducer.actions;
export default currentUser_Reducer.reducer;