// base
import React, { useContext, useEffect } from 'react'
import './usersHeader.css';

// context
import { UsersContext } from '../../../pages/users/users/usersContext';

// components
import AnalyticalCard from '../../common_components/analytical_card/AnalyticalCard';

// icons
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DeleteIcon from '@mui/icons-material/Delete';

// logic
import {
  UsersModeEnum,
  activeUsersCountAsync,
  deletionUsersCountAsync,
  getActiveUsersPagesCountAsync,
  getDeletionUsersPagesCountAsync,
  getActiveUsersAsync,
  getDeletionUsersAsync
} from '../../../pages/users/users/usersLogic';

export default function UsersHeader() {

  const {
    setUsers, 
    activeUsersCount,
    setActiveUsersCount,
    deletionsUsersCount,
    setDeletionsUsersCount,
    usersMode,
    setUsersMode,
    currentPageNumber,
    setUsersPagesCount,
    setLoading
  } = useContext(UsersContext);

  useEffect(() => {
    const fetchData = async () => {
      setActiveUsersCount(await activeUsersCountAsync());
      setDeletionsUsersCount(await deletionUsersCountAsync());
    };

    fetchData();
  }, []);

  useEffect(() => {
    GetUsersWithPagesCountAsync();
  }, [currentPageNumber, usersMode]);

  async function GetUsersWithPagesCountAsync() {
    try {
      setLoading(true);
      
      switch (usersMode) {
        case UsersModeEnum.ACTIVE_USERS:
          setUsers(await getActiveUsersAsync(currentPageNumber));
          setUsersPagesCount(await getActiveUsersPagesCountAsync());
          break;

        case UsersModeEnum.DELETED_USERS:
          setUsers(await getDeletionUsersAsync(currentPageNumber));
          setUsersPagesCount(await getDeletionUsersPagesCountAsync());
          break;
  
        default:
          console.warn("Unknown users mode:", usersMode);
          return;
      }
    } catch (error) {
      console.error('Error fetching users with pages count:', error);
    } finally {
      setLoading(false);
    }
  }

  const onActiveUsersClickHandler = () => {
    setUsersMode(UsersModeEnum.ACTIVE_USERS);
  }

  const onDeletionsUsersClickHandler = () => {
    setUsersMode(UsersModeEnum.DELETED_USERS);
  }

  return (
    <div className='users-header-component'>
      
      <div className={`users-card-container`}>
        <AnalyticalCard 
          title={'Active Users'} 
          icon={PeopleAltIcon} 
          value={activeUsersCount} 
          isActive={usersMode === UsersModeEnum.ACTIVE_USERS} 
          onClick={onActiveUsersClickHandler} 
        />
      </div>

      <div className={`users-card-container`}>
        <AnalyticalCard 
          title={'Deleted Users'} 
          icon={DeleteIcon} 
          value={deletionsUsersCount} 
          isActive={usersMode === UsersModeEnum.DELETED_USERS} 
          onClick={onDeletionsUsersClickHandler} 
        />
      </div>

    </div>
  )
}