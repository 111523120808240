import React from 'react'
import './codeVerification.css';
import { useState } from 'react';
import TextInput from '../../inputs/text_input/TextInput'
import Messagelabel from '../../message_label/MessageLabel'
import ContainedButton from '../../inputs/buttons/contained_button/ContainedButton';
import TextButton from '../../inputs/buttons/text_button/TextButton';
import {
  validateCode,
  handlePreviousClick,
  handleNextClick
} from './codeVerificationLogic'

export default function CodeVerification({ title, code, email, stepsState }) {

  const initMessage = `We've sent a password reset code to your email - ${email}.`;
  const [message, setMessage] = useState({isVisible: true, message: initMessage, type: 'info'});
  const [inputCode, setInputCode] = useState('');

  return (
    <div className='code-verification-component'>
      <h2 className='code-verification-title'>Code Verification</h2>

      <div className='code-verification-message'>
        <Messagelabel message={message.message} isVisible={message.isVisible} type={message.type} />
      </div>

      <TextInput 
        title={title} 
        value={inputCode}
        onChangeHandler={(newValue) => {setInputCode(newValue)}}
        validateInput={validateCode}
        mandatory={true}
        onEnter={async () => { handleNextClick(inputCode, code, setMessage, stepsState)}}
      />

      <div className='code-verification-buttons-container'>
        <div className='code-verification-Previous-button'>
          <TextButton title='Previous' onclickHandler={() => {handlePreviousClick(stepsState)}}/>
        </div>

        <div className='code-verification-next-button'>
          <ContainedButton title='Next' onclickHandler={async () => { handleNextClick(inputCode, code, setMessage, stepsState)}}/>
        </div>
      </div>

    </div>
  )
}
