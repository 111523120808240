import { patchData, deleteData } from '../../../global/utils/functions/apiService'

function dropdownStatusHandler(state, setState) {
  setState(!state)
}

function renderCardProfileImage(profileImageURL, userName) {
  if (profileImageURL) {
    return <img src={profileImageURL} alt='profile' className='profile-image' />;
  } else {
    const initials = userName ? userName.slice(0, 2).toUpperCase() : '';
    return (
      <div className='profile-initials'>
        {initials}
      </div>
    );
  }
};

function resetMessageBoxState(messageBoxState, newStateValue) {
  messageBoxState(({title: newStateValue.title, message: newStateValue.message, visibility: newStateValue.visibility}))
}

const handlePermissionAction = (action, confirmationMessage, currentAdminPermissions, setPermissionsMessage, messageBoxState) => {
  const permissionMap = {
    'clear-permissions': 2,
    'deactivate': 4,
    'activate': 4,
    'delete': 8
  };

  if (currentAdminPermissions >= 0) {
    if (!checkPermissions(currentAdminPermissions, permissionMap[action])) {
      setPermissionsMessage({
        title: 'Permission Denied',
        message: `You do not have permissions to ${action.replace('-', ' ')} this admin.`,
        visibility: true
      });
    }
    else {
      resetMessageBoxState(messageBoxState, {
        title: `Confirm ${action.replace('-', ' ')}`,
        message: confirmationMessage,
        visibility: true
      });
      return (action);
    }
  }
  else {
    resetMessageBoxState(messageBoxState, {
      title: `Confirm ${action.replace('-', ' ')}`,
      message: confirmationMessage,
      visibility: true
    });
    return (action);
  }
};

function checkPermissions(adminPermissions, actionPermissions) {
  return (adminPermissions & actionPermissions) === actionPermissions
}

async function clearPermissionsAsync(adminId, byAdmin, messageState, loaderState, onAdminsChange) {
  try {
    loaderState(true);

    const response = await patchData(`/Admins/ClearPermissions/${adminId}/${byAdmin}`);

    if (response.success && response.data.Response.data.response) {
      messageState({
        title: 'Success',
        message: 'Permissions have been cleared successfully.',
        visibility: true
      });
      onAdminsChange();
    } else {
      messageState({
        title: 'Error',
        message: response.message || 'Failed to clear permissions. Please check your permissions and try again.',
        visibility: true
      });
    }
  } catch (error) {
    messageState({
      title: 'Error',
      message: error.message || 'An unexpected error occurred. Please try again later.',
      visibility: true
    });
  } finally {
    loaderState(false);
  }
}

async function activateAdminAsync(adminId, byAdmin, messageState, loaderState, onAdminsChange) {
  try {
    loaderState(true);

    const response = await patchData(`/Admins/SetAsActive/${adminId}/${byAdmin}`);

    if (response.success && response.data.Response.data.response) {
      messageState({
        title: 'Success',
        message: 'Admin has been activated successfully.',
        visibility: true
      });
      onAdminsChange();
    } else {
      messageState({
        title: 'Error',
        message: response.message || 'Failed to activate admin. Please check your permissions and try again.',
        visibility: true
      });
    }
  } catch (error) {
    messageState({
      title: 'Error',
      message: error.message || 'An unexpected error occurred while activating the admin. Please try again later.',
      visibility: true
    });
  } finally {
    loaderState(false);
  }
}

async function deactivateAdminAsync(adminId, byAdmin, messageState, loaderState, onAdminsChange) {
  try {
    loaderState(true);

    const response = await patchData(`/Admins/SetAsInActive/${adminId}/${byAdmin}`);

    if (response.success && response.data.Response.data.response) {
      messageState({
        title: 'Success',
        message: 'Admin has been deactivated successfully.',
        visibility: true
      });
      onAdminsChange();
    } else {
      messageState({
        title: 'Error',
        message: response.message || 'Failed to deactivate admin. Please check your permissions and try again.',
        visibility: true
      });
    }
  } catch (error) {
    messageState({
      title: 'Error',
      message: error.message || 'An unexpected error occurred while deactivating the admin. Please try again later.',
      visibility: true
    });
  } finally {
    loaderState(false);
  }
}

async function deleteAdminAsync(adminId, byAdmin, messageState, loaderState, onAdminsChange) {
  try {
    loaderState(true);
    
    const response = await deleteData(`/Admins/DeleteAdmin/${adminId}/${byAdmin}`);
    
    if (response.success && response.data.Response.data.response) {
      messageState({
        title: 'Success',
        message: 'Admin has been deleted successfully.',
        visibility: true
      });
      onAdminsChange();
    } else {
      messageState({
        title: 'Error',
        message: response.message || 'Failed to delete admin. Please check your permissions and try again.',
        visibility: true
      });
    }
  } catch (error) {
    messageState({
      title: 'Error',
      message: error.message || 'An unexpected error occurred while deleting the admin. Please try again later.',
      visibility: true
    });
  } finally {
    loaderState(false);
  }
}

async function onConfirmMessageBox(action, adminId, byAdmin, messageState, loaderState, onAdminsChange) {

  switch (action){
    case 'clear-permissions':
      return await clearPermissionsAsync(adminId, byAdmin, messageState, loaderState, onAdminsChange)

    case 'activate':
      return await activateAdminAsync(adminId, byAdmin, messageState, loaderState, onAdminsChange);

    case 'deactivate':
      return await deactivateAdminAsync(adminId, byAdmin, messageState, loaderState, onAdminsChange);

    case 'delete':
      return await deleteAdminAsync(adminId, byAdmin, messageState, loaderState, onAdminsChange);
    
    default:
      return false;
  }
  
}

export {
  handlePermissionAction,
  checkPermissions,
  dropdownStatusHandler,
  renderCardProfileImage,
  resetMessageBoxState,
  onConfirmMessageBox
}