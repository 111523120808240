// base
import React, { useState, useEffect } from 'react';
import './addUpdateComponent.css';
import { useSelector } from 'react-redux';

// components
import MessageLabel from '../../message_label/MessageLabel';
import ContainedButton from '../../inputs/buttons/contained_button/ContainedButton';
import TextButton from '../../inputs/buttons/text_button/TextButton';

// logic
import {
  GeneralQuestionsMode,
  handleInputChange,
  validateQuestion,
  validateAnswer,
  newQuestionAsync,
  updateQuestionAsync
} from '../../../pages/general_questions/generalQuestionsLogic';

export default function AddUpdateComponent({ question, mode, setMode, reload, setReload }) {
  const currentAdminId = useSelector((state) => state.currentUser.adminId);
  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);

  const [newQuestion, setNewQuestion] = useState({
    questionId: 0,
    question: '',
    answer: ''
  })

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    message: 'Please fill in the required fields to add a new general question. All fields marked with * are mandatory.',
    type: 'info',
    isVisible: true
  });

  useEffect(() => {

    if (mode === GeneralQuestionsMode.ADD) {
      setNewQuestion({
        questionId: 0,
        question: '',
        answer: ''
      })
    }

    if (mode === GeneralQuestionsMode.UPDATE) {
      setNewQuestion({
        questionId: question.questionId,
        question: question.question,
        answer: question.answer
      })

      setMessage({
        message: 'Please fill in the required fields to update general question. All fields marked with * are mandatory.',
        type: 'info',
        isVisible: true
      })
    }
  }, [mode])

  const handleNewQuestion = async() => {
    if (await newQuestionAsync(newQuestion, currentAdminId, currentAdminPermissions, setMessage) === true) {
      setNewQuestion({
        questionId: 0,
        question: '',
        answer: ''
      });
      setReload(!reload);
    }
  }

  const handleUpdateQuestion = async() => {
    if (await updateQuestionAsync(newQuestion, currentAdminId, currentAdminPermissions, setMessage) === true) {
      setReload(!reload);
    }
  }

  const handleAddUpdateClick = async () => {

    if ((currentAdminPermissions & 1024) !== 1024){
      setMessage({
        message: 'You do not have sufficient permissions to perform this action.',
        type: 'info',
        isVisible: true,
      });
      return;
    }

    setLoading(true);
    try {

      if (!validateQuestion(newQuestion.question)) {
        setMessage({
          message: 'Your question cannot be empty and should not exceed 500 characters. Please review and try again.',
          type: 'error',
          isVisible: true
        });
        return;
      }

      else if (!validateAnswer(newQuestion.answer)) {
        setMessage({
          message: 'Your answer cannot be empty. Please review and try again.',
          type: 'error',
          isVisible: true
        });
        return;
      }

      if (mode === GeneralQuestionsMode.ADD) {
        await handleNewQuestion();
      }
      else if (mode === GeneralQuestionsMode.UPDATE) {
        await handleUpdateQuestion();
      }

    } catch (error) {
      setMessage({
        message: 'An error occurred while processing your request. Please try again later.',
        type: 'error',
        isVisible: true
      });
    } finally {
      setLoading(false);
    }
  }

  const handleCancelClick = () => {
    setNewQuestion({
      questionId: 0,
      question: '',
      answer: ''
    });
    setMode(GeneralQuestionsMode.READ);
  }

  return (
    <div className='general-questions-add-update-component'>
      <div className='general-question-add-update-content'>
        
        <div className='new-question-header'>
          <h3>{mode === GeneralQuestionsMode.ADD ? 'Quick Add' : 'Quick Update'}</h3>
          <div className={`save-loader ${loading ? 'active' : ''}`}></div>
        </div>

        <div className='new-question-message'>
          <MessageLabel message={message.message} type={message.type} isVisible={message.isVisible} />
        </div>

        <div className={`new-question-body ${loading ? 'loading' : ''}`}>
          
          <div className={`input-container`}>
            <div className='input-container-header'>
              <p className='label'>Question <strong>*</strong></p>
              <p className='latters-counter'>{500 -newQuestion.question.length}/500</p>
            </div>
            
            <textarea 
                className='question-input' 
                placeholder='Enter question' 
                maxLength={500} 
                value={newQuestion.question} 
                onChange={(e) => {
                  handleInputChange('question', e.target.value, setNewQuestion);
                }}
              />
          </div>

          <div className={`input-container`}>
            <p className='label'>Answer <strong>*</strong></p>
            <textarea 
                className='answer-input' 
                placeholder='Enter answer' 
                value={newQuestion.answer} 
                onChange={(e) => {
                  handleInputChange('answer', e.target.value, setNewQuestion);
                }}
              />
          </div>

        </div>

        <div className={`new-question-footer ${loading ? 'loading' : ''}`}>
          
          <div className='new-question-add-button-container'>
            <ContainedButton title={mode === GeneralQuestionsMode.ADD ? 'Add' : 'Update'} onclickHandler={() => {handleAddUpdateClick()}} />
          </div>

          <div className='new-question-cancel-button-container'>
            <TextButton title={'Cancel'} onclickHandler={() => {handleCancelClick()}} />
          </div>
        </div>

      </div>
    </div>
  )
}
