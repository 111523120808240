import React from 'react'
import './textButton.css'

export default function TextButton({ title, onclickHandler }) {

  const onClick = () => {
    onclickHandler();
  }

  return (
    <div className='text-button-component'>
      <button className='text-button-input' onClick={onClick}>{title}</button>
    </div>
  )
}
