// base
import React, { useContext, useEffect, useRef, useState } from 'react'
import './updatesHeader.css';

// context
import { UpdatesContext } from '../../../pages/updates/updatesContext';

// icons
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { IoMdListBox } from "react-icons/io"; /* all */
import { MdAllInclusive } from "react-icons/md"; /* by table */

// components 
import AnalyticalCard from '../../common_components/analytical_card/AnalyticalCard';

// logic
import {
  UpdatesModeEnum,
  getAllUpdatesCount,
  getUpdatesPagesCountAll,
  getAllUpdates,
  getTables,
  getUpdatesPagesCountByTable,
  getUpdatesByTable
} from '../../../pages/updates/updatesLogic';

export default function UpdatesHeader() {
  const {
    setUpdates,
    updatesMode,
    updatesCount,
    setUpdatesCount,
    tables,
    setTables,
    setUpdatesMode,
    currentPageNumber,
    setCurrentPageNumber,
    setUpdatesPagesCount,
    setLoading
  } = useContext(UpdatesContext);

  const containerRef = useRef(null);
  const [activeCardId, setActiveCardId] = useState(0);

  const scrollLeft = () => {
    const containerWidth = containerRef.current.offsetWidth;
    const scrollAmount = window.innerWidth <= 900 
      ? containerWidth * 0.6
      : containerWidth * 0.23;
      containerRef.current.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
  };

  const scrollRight = () => {
    const containerWidth = containerRef.current.offsetWidth;
    const scrollAmount = window.innerWidth <= 900 
      ? containerWidth * 0.6
      : containerWidth * 0.23
    containerRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
  };

  useEffect(() => {
    const fetchData = async () => {
      await getUpdatesWithPagesCountAsync();
    }

    fetchData();
  }, [updatesMode, currentPageNumber, activeCardId])

  async function getUpdatesWithPagesCountAsync() {
    try {
      setLoading(true);
      
      switch (updatesMode) {
        case UpdatesModeEnum.ALL_UPDATES:
          setUpdatesCount(await getAllUpdatesCount());
          setUpdatesPagesCount(await getUpdatesPagesCountAll());
          setTables(await getTables());
          setUpdates(await getAllUpdates(currentPageNumber));
          break;

        case UpdatesModeEnum.BY_TABLE:
          setUpdatesPagesCount(await getUpdatesPagesCountByTable(activeCardId));
          setUpdates(await getUpdatesByTable(activeCardId, currentPageNumber));
          break;

        default:
          console.warn("Unknown errors mode:");
          return;
      }
    } catch (error) {
      console.error('Error fetching updates with pages count:', error);
    } finally {
      setLoading(false);
    }
  }

  const onAllupdatesClickHandler = () => {
    setActiveCardId(0)
    setCurrentPageNumber(1);
    setUpdatesMode(UpdatesModeEnum.ALL_UPDATES);
  }

  const onUpdatesByTableClickHandler = (tableId) => {
    setActiveCardId(tableId)
    setCurrentPageNumber(1);
    setUpdatesMode(UpdatesModeEnum.BY_TABLE);
  }

  return (
    <div className="updates-header-component">
      <div className='scroll-container left'>
        <IoIosArrowBack className='icon' fontSize={''} onClick={scrollLeft} />
      </div>

      <div className='items-container' ref={containerRef}>

        <div className="item-container">
          <AnalyticalCard 
            title={'All Updates'} 
            icon={MdAllInclusive} 
            value={updatesCount} 
            isActive={updatesMode === UpdatesModeEnum.ALL_UPDATES} 
            onClick={onAllupdatesClickHandler} />
        </div>

        {tables
          .filter((table) => table.tableName !== "System Tables") // فلترة العناصر
          .sort((a, b) => a.tableName.localeCompare(b.tableName)) // ترتيب حسب الأحرف تصاعدياً
          .map((table) => (
            <div key={table.tableId} className="item-container">
              <AnalyticalCard
                title={table.tableName}
                icon={IoMdListBox}
                value={'~'}
                isActive={activeCardId === table.tableId}
                onClick={() => onUpdatesByTableClickHandler(table.tableId)}
              />
            </div>
          ))
        }
        
      </div>

      <div className='scroll-container right'>
        <IoIosArrowForward className='icon' fontSize={''} onClick={scrollRight} />
      </div>
    </div>
  )
}
