import React from 'react';
import './selector.css';

export default function Selector({ title, options, value, onSelect }) {

  const handleChange = (event) => {
    const selectedOption = options.find(option => option.value === event.target.value);
    if (selectedOption) {
      onSelect({ value: selectedOption.value, label: selectedOption.label });
    }
  };

  return (
    <div className='selector-component'>
      <label className='selector-title'>{title}</label>

      <select 
        className='selector-dropdown' 
        value={value} 
        onChange={handleChange}
      >
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      
    </div>
  );
}
