import React from 'react'
import './login.css';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MessageLabel from '../../components/message_label/MessageLabel';
import TextInput from '../../components/inputs/text_input/TextInput';
import PasswordInput from '../../components/inputs/password_input/PasswordInput';
import CheckBox from '../../components/inputs/checkbox/CheckBox';
import ContainedButton from '../../components/inputs/buttons/contained_button/ContainedButton';
import Loading from '../../components/loading/Loading';

import {
  getFromLocalStorage,
  isLocalStorageHasData,
  onChangeHandler, 
  onRememberMeChangeHandler, 
  validateEmailOrUserName,
  validatePassword, 
  handleSignInAsync
  } from './loginLogic';

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userInput, setUserInput] = useState(getFromLocalStorage());
  const [rememberMe, setRememberMe] = useState(isLocalStorageHasData);
  const [error, setError] = useState({isVisible: false, message: ''});
  const [loader, setLoader] = useState(false);

  return (
    <div className='login-container'>
      
      <div className='form'>

        <h2 className='login-title'>Sign In</h2>

        <div className='message-label-container'>
          <MessageLabel type='error' message={error.message} isVisible={error.isVisible}/>
        </div>

        <div className='email-username-input-container'>
      
          <TextInput
            title={'Email or username'} 
            value={userInput.emailOrUserName}
            onChangeHandler={(newValue) => {onChangeHandler('emailOrUserName', newValue, setUserInput)}} 
            validateInput={validateEmailOrUserName}
            mandatory={true}
            tooltipTitle={'Please enter either a username (4 to 25 characters) or an email (5 to 150 characters)'}
            onEnter={async () => {await handleSignInAsync(userInput, rememberMe, setError, setLoader, dispatch, navigate)}}
            />
      
        </div>

        <div className='password-container'>
      
          <PasswordInput 
            title={'Password'}
            value={userInput.password}
            onChangeHandler={(newValue) => {onChangeHandler('password', newValue, setUserInput)}}
            validateInput={validatePassword}
            tooltipTitle={'Please enter a password (8 to 25 characters)'}
            onEnter={async () => {await handleSignInAsync(userInput, rememberMe, setError, setLoader, dispatch, navigate)}}
          />
      
        </div>

        <div className='checkbox-container'>
      
          <CheckBox 
            title={'Remember me'}
            checked={rememberMe}
            onChangeHandler={(newValue) => {onRememberMeChangeHandler(newValue, setRememberMe)}}
          />
      
        </div>

        <div className='button-container'>
          
          <ContainedButton 
            title={'Sign In'}
            onclickHandler={async () => {await handleSignInAsync(userInput, rememberMe, setError, setLoader, dispatch, navigate)}}
          />

        </div>

        <div className='forget-password'>
          <span>
            Forget <Link className='link' to={'/reset-password'}>password</Link>?
          </span>
        </div>

      </div>
      
      <Loading isVisible={loader} />
    </div>
  )
}
