// base
import React, { useContext } from 'react';
import './additionsMain.css';

// context
import { AdditionsContext } from '../../../pages/additions/additionsContext';

import {
  resetMessage
} from '../../../pages/additions/additionsLogic';

// components
import AdditionsHeader from '../additions_header/AdditionsHeader';
import AdditionsBody from '../additions_body/AdditionsBody';
import Model  from '../../model/Model';
import MessageBox from '../../message_box/MessageBox';
import ContainedButton from '../../inputs/buttons/contained_button/ContainedButton';
import Loading from '../../loading/Loading';
import Pagination from '@mui/material/Pagination';

export default function AdditionsMain() {
  const { 
    modelMessage, 
    setModelMessage,
    messageBox,
    setMessageBox,
    loading,
    currentPageNumber,
    setCurrentPageNumber,
    additionsPagesCount,
  } = useContext(AdditionsContext);

  const handlePaginationChange = (event, value) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setCurrentPageNumber(value)
  }

  return (
    <div className='additions-main-component'>
      
      <Model 
        title={modelMessage.title} 
        message={modelMessage.message} 
        status={modelMessage.isVisible} 
        setStatus={setModelMessage} 
      />

      <MessageBox 
        title={messageBox.title} 
        message={messageBox.message} 
        isVisable={messageBox.isVisible} 
        onClose={() => {resetMessage(setMessageBox)}}
      >
        <div className='additions-main-confirm-btn-container'>
          <ContainedButton title={'Confirm'} onclickHandler={() => {
            resetMessage(setMessageBox);
            // invoke method to do action.
          }} />
        </div>
      </MessageBox>

      <div className={`loading-container ${loading ? 'active' : ''}`}>
        <Loading isVisible={loading} />
      </div>

      <div className='additions-header-container'>
        <AdditionsHeader />
      </div>

      <div className='additions-body'>
        <AdditionsBody />
      </div>

      <div className={`additions-pagination-container ${additionsPagesCount <= 0 ? 'hidden' : ''}`}>
        <Pagination 
          count={additionsPagesCount} 
          page={currentPageNumber}
          variant="outlined" 
          shape="rounded" 
          color="primary" 
          showFirstButton 
          showLastButton 
          size="small"
          onChange={handlePaginationChange}
        />
      </div>

    </div>
  )
}
