// base
import React, { useContext, useEffect, useRef, useState } from 'react'
import './deletionsHeader.css';

// context
import { DeletionsContext } from '../../../pages/deletions/deletionsContext';

// icons
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { IoMdListBox } from "react-icons/io"; /* all */
import { MdAllInclusive } from "react-icons/md"; /* by table */

// components 
import AnalyticalCard from '../../common_components/analytical_card/AnalyticalCard';

// logic
import {
  DeletionsModeEnum,
  getAllDeletionsCount,
  getDeletionsPagesCountAll,
  getAllDeletions,
  getTables,
  getDeletionsPagesCountByTable,
  getDeletionsByTable
} from '../../../pages/deletions/deletionsLogic';

export default function DeletionsHeader() {
  const {
    setDeletions,
    deletionsMode,
    deletionsCount,
    setDeletionsCount,
    tables,
    setTables,
    setDeletionsMode,
    currentPageNumber,
    setCurrentPageNumber,
    setDeletionsPagesCount,
    setLoading
  } = useContext(DeletionsContext);

  const containerRef = useRef(null);
  const [activeCardId, setActiveCardId] = useState(0);

  const scrollLeft = () => {
    const containerWidth = containerRef.current.offsetWidth;
    const scrollAmount = window.innerWidth <= 900 
      ? containerWidth * 0.6
      : containerWidth * 0.23;
      containerRef.current.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
  };

  const scrollRight = () => {
    const containerWidth = containerRef.current.offsetWidth;
    const scrollAmount = window.innerWidth <= 900 
      ? containerWidth * 0.6
      : containerWidth * 0.23
    containerRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
  };

  useEffect(() => {
    const fetchData = async () => {
      await getDeletionsWithPagesCountAsync();
    }

    fetchData();
  }, [deletionsMode, currentPageNumber, activeCardId])

  async function getDeletionsWithPagesCountAsync() {
    try {
      setLoading(true);
      
      switch (deletionsMode) {
        case DeletionsModeEnum.ALL_DELETIONS:
          setDeletionsCount(await getAllDeletionsCount());
          setDeletionsPagesCount(await getDeletionsPagesCountAll());
          setTables(await getTables());
          setDeletions(await getAllDeletions(currentPageNumber));
          break;

        case DeletionsModeEnum.BY_TABLE:
          setDeletionsPagesCount(await getDeletionsPagesCountByTable(activeCardId));
          setDeletions(await getDeletionsByTable(activeCardId, currentPageNumber));
          break;

        default:
          console.warn("Unknown errors mode:");
          return;
      }
    } catch (error) {
      console.error('Error fetching deletions with pages count:', error);
    } finally {
      setLoading(false);
    }
  }

  const onAllDeletionsClickHandler = () => {
    setActiveCardId(0)
    setCurrentPageNumber(1);
    setDeletionsMode(DeletionsModeEnum.ALL_DELETIONS);
  }

  const onDeletionsByTableClickHandler = (tableId) => {
    setActiveCardId(tableId)
    setCurrentPageNumber(1);
    setDeletionsMode(DeletionsModeEnum.BY_TABLE);
  }

  return (
    <div className="deletions-header-component">
      <div className='scroll-container left'>
        <IoIosArrowBack className='icon' fontSize={''} onClick={scrollLeft} />
      </div>

      <div className='items-container' ref={containerRef}>

        <div className="item-container">
          <AnalyticalCard 
            title={'All Deletions'} 
            icon={MdAllInclusive} 
            value={deletionsCount} 
            isActive={deletionsMode === DeletionsModeEnum.ALL_DELETIONS} 
            onClick={onAllDeletionsClickHandler} />
        </div>

        {tables
          .filter((table) => table.tableName !== "Errors" && table.tableName !== "System Tables") // فلترة العناصر
          .sort((a, b) => a.tableName.localeCompare(b.tableName)) // ترتيب حسب الأحرف تصاعدياً
          .map((table) => (
            <div key={table.tableId} className="item-container">
              <AnalyticalCard
                title={table.tableName}
                icon={IoMdListBox}
                value={'~'}
                isActive={activeCardId === table.tableId}
                onClick={() => onDeletionsByTableClickHandler(table.tableId)}
              />
            </div>
          ))
        }
        
      </div>

      <div className='scroll-container right'>
        <IoIosArrowForward className='icon' fontSize={''} onClick={scrollRight} />
      </div>
    </div>
  )
}
