// base
import React, { useState } from 'react';
import './generalQuestionCard.css';
import { Link } from 'react-router-dom';

// icons
import { FaRegArrowAltCircleDown } from "react-icons/fa";
import { FaRegArrowAltCircleUp } from "react-icons/fa";

// components
import ContainedButton from '../../inputs/buttons/contained_button/ContainedButton';

// logic
import {
  GeneralQuestionsMode
} from '../../../pages/general_questions/generalQuestionsLogic'

export default function GeneralQuestionCard({ question, _index, setQuestionIdForDelete, setQuestionForUpdate, setMode, messaegState  }) {
  const [openMode, setOpenMode] = useState(false);

  return (
    <div className='general-question-card-component'>
      
      <div className='general-question-header' onClick={() => {setOpenMode(!openMode)}}>

        <div className='general-questions-header-info'>
          <div className='label-index'>
            <p className='label index'>#/{_index}</p>
          </div>

          <div className='label-question'>
            <p className='label'>Question</p>
            <p className='value'>{question.question}</p>
          </div>
        </div>

        <div className='icon-container'>
          {openMode ? (
            <FaRegArrowAltCircleUp className='card-icon' fontSize={''} onClick={() => {setOpenMode(!openMode)}} />
          ) : (
            <FaRegArrowAltCircleDown className='card-icon' fontSize={''} onClick={() => {setOpenMode(!openMode)}} />
          )}
        </div>
      </div>
      
      <div className={`card-body ${openMode ? 'opened' : ''}`}>

        <div className='item question'>
          <pre>
            <p className='label'>Question</p>
            <p className='value'>{question.question}</p>
          </pre>
        </div>

        <div className='item answer'>
          <pre>
            <p className='label'>Answer</p>
            <p className='value'>{question.answer}</p>
          </pre>
        </div>

        <div className='item update'>
          <p className='label'>Update</p>

          <div className='btn-container'>
            <ContainedButton title={'Update'} onclickHandler={() => {
              setQuestionForUpdate({
                questionId: question.questionId,
                question: question.question,
                answer: question.answer
              });
              setMode(GeneralQuestionsMode.UPDATE)
            }} />
          </div>

        </div>

        <div className='item delete'>
          <p className='label'>Delete</p>

          <div className='btn-container'>
            <ContainedButton title={'Delete'} type='danger' onclickHandler={() => {
              setQuestionIdForDelete(question.questionId);
              messaegState({
                title: 'Delete General Question',
                message: 'Are you sure you want to delete this General Question? This action cannot be undone.',
                isVisible: true
              });
            }} />
          </div>

        </div>

        <div className='item history'>
          <p className='label'>View History</p>

          <Link to={`/transactions-history/${question.questionId}/General Questions`}>
            <p className='value'>history</p>
          </Link>
        </div>

      </div>

    </div>
  )
}
