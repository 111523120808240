import { isInt } from "../../../global/utils/validation";

function validateCode(input) {
  return isInt(input);
}

function setMessageState(isVisible, message, type, messageState) {
  messageState({ isVisible, message, type })
}

function handlePreviousClick(stepsState) {
  stepsState('1')
}

function handleNextClick(inputCode, code, messageState, stepsState) {

  setMessageState(false, '', 'success', messageState);

  if (!validateCode(inputCode)){
    setMessageState(true, 'invalid code', 'error', messageState);
    return;
  }

  if (inputCode !== code)
    setMessageState(true, 'The code you entered is incorrect. Please check the code and try again.', 'error', messageState);

  else
  stepsState('3');
}

export {
  validateCode,
  handlePreviousClick,
  handleNextClick
}