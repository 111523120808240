// base
import React, { useEffect, useState } from 'react'
import './adminProfile.css';
import { Link, Navigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// components
import Model from '../../../components/model/Model';
import MessageBox from '../../../components/message_box/MessageBox';
import ContainedButton from '../../../components/inputs/buttons/contained_button/ContainedButton';
import BaseInfoCard from '../../../components/admins_components/admins_profiles_components/base_info_card/BaseInfoCard';
import Description from '../../../components/admins_components/admins_profiles_components/description/Description';
import Permissions from '../../../components/admins_components/admins_profiles_components/permissions/Permissions';

// icons
import { GiBroom } from "react-icons/gi";
import { MdKey } from "react-icons/md";
import { MdKeyOff } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { MdAttachEmail } from "react-icons/md";
import { FaFileCirclePlus } from "react-icons/fa6";
import { FaFileCircleMinus } from "react-icons/fa6";
import { FaFilePen } from "react-icons/fa6";
import { TbTimelineEventFilled } from "react-icons/tb";

// logic 
import {
  GetAdminByIdAsync,
  clearPermissionsAsync,
  activateAdminAsync,
  deactivateAdminAsync,
  deleteAdminAsync
} from './adminProfileLogic';

export default function AdminProfile() {

  const navigate = useNavigate();
  const currentAdminId = useSelector((state) => state.currentUser.adminId);
  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);

  const { adminId } = useParams();
  const [message, messageState] = useState({title: '', message: '', status: false});
  const [messageBox, setMessageBox] = useState({title: '', message: '', status: false});
  const [actionType, setActionType] = useState('');
  const [actionLoader, setActionLoader] = useState(false);
  const [reload, setReload] = useState(false);

  const [admin, setAdmin] = useState({ 
    adminId: null, 
    isActive: null, 
    permissions: null, 
    description: null,
    fullName: null,
    email: null,
    userName: null,
    profileImageURL: null,
    isDeleted: null,
    registrationDate: null
  })

  useEffect(() => {
    const loadAdminAsync = async () => {
      const adminData = await GetAdminByIdAsync(adminId);
      if (adminData) {
        setAdmin(adminData);
      } else {
        setAdmin({
          adminId: null,
          isActive: null,
          permissions: null,
          description: null,
          fullName: null,
          email: null,
          userName: null,
          profileImageURL: null,
          isDeleted: null,
          registrationDate: null,
        });
      }
    };
  
    loadAdminAsync();
    
    if (!admin)
      <Navigate to={'admins'} />

  }, [adminId, reload]);

  const handleAction = async() => {
    switch (actionType) {
      case 'clear-permissions':
        await clearPermissionsAsync(
          admin.adminId, admin.permissions, currentAdminId,
          currentAdminPermissions, messageState, setActionLoader,
          reload, setReload, navigate
        );
        return;
      case 'activate':
        await activateAdminAsync(
          admin.adminId, admin.permissions, currentAdminId,
          currentAdminPermissions, messageState, setActionLoader,
          reload, setReload, navigate
        );
        return;
      case 'deactivate':
        await deactivateAdminAsync(
          admin.adminId, admin.permissions, currentAdminId,
          currentAdminPermissions, messageState, setActionLoader,
          reload, setReload, navigate
        );
        return;
      case 'delete':
        await deleteAdminAsync(
          admin.adminId, admin.permissions, currentAdminId,
          currentAdminPermissions, messageState, setActionLoader,
          reload, setReload, navigate
        );
        return;
      default:
        return;
    }
  }

  return (
    <div className='admin-profile-container'>
      <Model title={message.title} message={message.message} status={message.status} setStatus={messageState} />
      
      <MessageBox title={messageBox.title} message={messageBox.message} isVisable={messageBox.status} onClose={() => {setMessageBox(false)}}>
        <div className='confirm-button-container'>
          <ContainedButton title={'confirm'} onclickHandler={async() => {
            setMessageBox(false);
            await handleAction();
          }} />
        </div>
      </MessageBox>

      <div className='admin-profile-sub-container'>

        <div className='left-side'>

          <div className='base-info-container'>
            <BaseInfoCard 
              admin={admin}
            />
          </div>

          <div className='description-container'>
            <Description adminId={admin.adminId} description={admin.description} reload={reload} reloadState={setReload}/>
          </div>

          <div className='permissions-container'>
            <Permissions adminId={admin.adminId} adminFullname={admin.fullName} adminPermissions={admin.permissions} reload={reload} reloadState={setReload} />
          </div>

        </div>

        <div className='right-side'>

        <div className='actions-container'>

          <div className={`actions-sub-container ${actionLoader ? 'loading' : ''}`}>

            <p className='actions-header'>
              Actions
            </p>

            <div className={`actions-item ${admin.permissions === -2 || admin.isDeleted ? 'desabled' : ''}`} 
              onClick={() => {
                setActionType('clear-permissions');
                setMessageBox({
                  title: 'Confirm',
                  message: `Are you sure to clear ${admin.fullName} permissions?`,
                  status: true
                })
              }}>
              <p className='actions-clear-permissions'>Clear Permissions</p>
              <GiBroom fontSize={'large'} />
            </div>

            <div className={`actions-item ${admin.permissions === -2 || admin.isDeleted ? 'desabled' : ''}`}
              onClick={() => {
                setActionType(`${admin.isActive ? 'deactivate' : 'activate'}`);
                setMessageBox({
                  title: 'Confirm',
                  message: `Are you sure to ${admin.isActive ? 'deactivate' : 'activate'} ${admin.fullName}?`,
                  status: true
                })
              }}>
              <p className='actions-status'>{admin.isActive ? 'Deactivate' : 'Activate'}</p>
              {admin.isActive ? <MdKeyOff fontSize={'large'} /> : <MdKey fontSize={'large'} />}
            </div>

            <div className={`actions-item delete-item ${admin.permissions === -2 || admin.isDeleted ? 'desabled' : ''}`}
              onClick={() => {
                setActionType('delete');
                setMessageBox({
                  title: 'Confirm',
                  message: `Are you sure to delete ${admin.fullName}?`,
                  status: true
                })
              }}>
              <p className='transactions-delete'>Delete</p>
              <MdDelete fontSize={'large'} />
            </div>

          </div>

          <div className={`actions-loader ${actionLoader ? 'active' : ''}`}></div>

        </div>

          <div className='communication-container'>
            <p className='communication-header'>
              Communication
            </p>

            <div className='communication-item' onClick={() => window.open(`mailto:${admin.email}`)}>
              <p className='send-email'>Send Email</p>
              <MdAttachEmail fontSize={'large'} />
            </div>
          </div>

          <div className='history-container'>
            <p className='history-header'>
              History
            </p>

            <Link to={`/transactions-history/${admin.adminId}/Admins`}>
              <div className='history-item'>
                <p className='history'>Admin History</p>
                <TbTimelineEventFilled fontSize={'large'} />
              </div>
            </Link>
          </div>

          <div className='transactions-container'>

            <p className='transactions-header'>
              Transactions
            </p>

            <Link to={`/transactions/${adminId}/additions`}>
              <div className='transactions-item'>
                <p className='transactions-additions'>Additions</p>
                <FaFileCirclePlus fontSize={'large'} />
              </div>
            </Link>
            
            <Link to={`/transactions/${adminId}/deletions`}>
              <div className='transactions-item'>
                <p className='transactions-deletions'>Deletions</p>
                <FaFileCircleMinus fontSize={'large'} />
              </div>
            </Link>

            <Link to={`/transactions/${adminId}/updates`}>
              <div className='transactions-item'>
                <p className='transactions-updates'>Updates</p>
                <FaFilePen fontSize={'large'} />
              </div>
            </Link>

          </div>

        </div>

      </div>
    </div>
  )
}
