import {
  fetchMultipleData
} from '../../../global/utils/functions/apiService';

const TimelineItemType = Object.freeze({
  ADD: 'add',
  UPDATE: 'update',
  DELETE: 'delete'
});

async function getOperationsHistories(targetId, tableName) {
  const url = `/SystemTransactions/GetTransactionsByTarget/${targetId}/${tableName}`;

  const response = await fetchMultipleData(url);
  if (response.success) {
    return response.data.Response.data.transactions;
  } else {
    console.log(response.message);
    return []
  }
}

export {
  TimelineItemType,
  getOperationsHistories
}
