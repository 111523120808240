// base
import React from 'react';
import './adminCard.css';
import { Link } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

// logic
import { copyToClipboard } from '../../../global/utils/functions/utils';
import { 
  handlePermissionAction,
  dropdownStatusHandler,
  renderCardProfileImage,
  resetMessageBoxState,
  onConfirmMessageBox
} from './adminCardLogic';

// components
import ContainedButton from '../../inputs/buttons/contained_button/ContainedButton';
import CardDropDown from '../../card_dropdown/card_dropdown_menu/CardDropDown';
import CardDropdownItem from '../../card_dropdown/card_Dropdown_item/CardDropdownItem';
import MessageBox from '../../message_box/MessageBox';

// material ui components
import { Tooltip } from '@mui/material';

// material ui icons
import PersonIcon from '@mui/icons-material/Person';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyOffIcon from '@mui/icons-material/KeyOff';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';

export default function AdminCard({
  adminId,
  isActive,
  fullName,
  userName, 
  profileImageURL,
  isDeleted,
  loaderState,
  onAdminsChange
}) {

  const currentAdminId = useSelector((state) => state.currentUser.adminId);
  let currentAdminPermissions = useSelector((state) => state.currentUser.permissions);
  const [dropdownStatus, setDropdownStatus] = useState(false);
  const [tooltipText, setTooltipText] = useState('Click to copy');
  const [messageBox, setMessageBox] = useState({ title: 'Confirm', message: '', visibility: false});
  const [permissionsMessage, setPermissionsMessage] = useState({ title: '', message: '', visibility: false })
  const [confirmMessageBoxAction, setConfirmMessageBoxAction] = useState('');

  let menuRef  = useRef(null);

  useEffect(() => {
    let handler = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)){
        setDropdownStatus(false);
      }
    }

    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    }
  }, [])

  return (
    <div className='admin-card-component'>
      
      <div className='admin-card-header'>

        <div className='admin-card-more-container' ref={menuRef}>
          <MoreHorizIcon className='admin-card-more' onClick={() => {dropdownStatusHandler(dropdownStatus, setDropdownStatus)}}/>

          <CardDropDown status={dropdownStatus} >

            <Link className={`show-profile-container ${currentAdminId === adminId ? 'disabled' : ''}`} to={`profile/${adminId}`}>
              <CardDropdownItem  title={'Show Profile'}><PersonIcon /></CardDropdownItem>
            </Link>
            
            <CardDropdownItem 
              title={'Clear Permissions'}
              isDisabled={adminId === 1 || currentAdminId === adminId || isDeleted}
              onClick={() => {
                setDropdownStatus(false);
                const action = 'clear-permissions';
                const confirmationMessage = 'Are you sure you want to clear all permissions for this admin? This action cannot be undone.';
                handlePermissionAction(
                  action,
                  confirmationMessage,
                  currentAdminPermissions,
                  setPermissionsMessage,
                  setMessageBox
                );

                setConfirmMessageBoxAction(action);
              }} >
              <KeyOffIcon />
            </CardDropdownItem>

            <CardDropdownItem 
                title={isActive ? 'Deactivate' : 'Activate'}
                isDisabled={currentAdminId === adminId || adminId === 1 || isDeleted}
                onClick={() => {
                  setDropdownStatus(false);
                  const action = isActive ? 'deactivate' : 'activate';
                  const confirmationMessage = `Are you sure you want to ${action} this admin? This action will change their status.`;
                  
                  handlePermissionAction(
                    action,
                    confirmationMessage,
                    currentAdminPermissions,
                    setPermissionsMessage,
                    setMessageBox
                  );

                  setConfirmMessageBoxAction(action);
                }} >
                {isActive ? <ToggleOffIcon /> : <ToggleOnIcon />}
            </CardDropdownItem>

            <CardDropdownItem 
                isDanger={true} 
                title={'Delete'}
                isDisabled={currentAdminId === adminId || adminId === 1 || isDeleted}
                onClick={() => {
                  setDropdownStatus(false);
                  const action = 'delete';
                  const confirmationMessage = 'Are you sure you want to delete this admin? This action cannot be undone.';
                  
                  handlePermissionAction(
                    action,
                    confirmationMessage,
                    currentAdminPermissions,
                    setPermissionsMessage,
                    setMessageBox
                  );

                  setConfirmMessageBoxAction(action);
                }}
                >
                <DeleteIcon />
            </CardDropdownItem>

          </CardDropDown>

        </div>

        <div className='admin-card-img-container'>
          {renderCardProfileImage(profileImageURL, userName)}
        </div>

        <p className='admin-card-fullname'>{fullName}</p>
        
      </div>

      <div className='admin-card-body'>
        
        <div className='admin-card-info-container'>
        <div className='admin-card-info'>
          <p>Username</p>
          <Tooltip title={tooltipText}>
            <p className='admin-card-username' onClick={() => {copyToClipboard(userName, setTooltipText)}}>{userName}</p>
          </Tooltip>
        </div>

        <div className='admin-card-info'>
          <p>Is active</p>
          <p>{isActive ? <CheckIcon fontSize='' className='success' /> : <CloseIcon fontSize='' className='danger' />}</p>
        </div>

        <div className='admin-card-info'>
          <p>Is deleted</p>
          <p>{isDeleted ? <CheckIcon fontSize='' className='success' /> : <CloseIcon fontSize='' className='danger' />}</p>
        </div>
        </div>

        <div className='admin-card-btns'>
          
          <Tooltip title={'Additions history'} placement="top">
            <Link to={`/transactions/${adminId}/additions`} className='card-transaction-btn'>
              <AddBoxIcon fontSize='' />
            </Link>
          </Tooltip>

          <Tooltip title={'Deletions history'} placement="top">
            <Link to={`/transactions/${adminId}/deletions`} className='card-transaction-btn'>
              <DeleteIcon fontSize='' />
            </Link>
          </Tooltip>

          <Tooltip title={'Updates history'} placement="top">
            <Link to={`/transactions/${adminId}/updates`} className='card-transaction-btn'>
              <EditIcon fontSize='' />
            </Link>
          </Tooltip>

        </div>

      </div>

      <MessageBox onClose={() => {resetMessageBoxState(setMessageBox, {title: '', message: '', visibility: false})}} isVisable={messageBox.visibility} title={messageBox.title} message={messageBox.message}>
        
        <div className='message-box-btn-container'>
          <ContainedButton title={'Confirm'} onclickHandler={async () => {
            await onConfirmMessageBox(confirmMessageBoxAction, adminId, currentAdminId, setPermissionsMessage, loaderState, onAdminsChange);
            resetMessageBoxState(setMessageBox, {title: '', message: '', visibility: false})
          }}/>
        </div>

      </MessageBox>

      <MessageBox onClose={() => {setPermissionsMessage({title: '', message: '', visibility: false})}} isVisable={permissionsMessage.visibility} title={permissionsMessage.title} message={permissionsMessage.message}>
      </MessageBox>

    </div>
  )
}
