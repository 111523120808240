// base
import React, { useState, useEffect } from 'react'
import './operationsHistory.css';
import { useParams } from 'react-router-dom';

// components
import OperationsHistoryHeader from '../../../components/operations_history_components/operations_history_header/OperationsHistoryHeader';
import OperationsHistoryBody from '../../../components/operations_history_components/operations_history_body/OperationsHistoryBody';
import Loading from '../../../components/loading/Loading';

// logic
import {
  getOperationsHistories
} from './operationsHistoryLogic'

export default function OperationsHistory() {
  const { id, table } = useParams(); // from route.
  const [historyData, setHistoryData] = useState([])

  const [loader, setLoader] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoader(true);
        const data = await getOperationsHistories(id, table);

        if (data) {
          setHistoryData(data);
        }
      } catch (error) {
        console.error('An error occurred while fetching data:', error);
      } finally {
        setLoader(false);
      }
    };
  
    fetchData();
  }, [id, table]);

  return (
    <div className='operations-history-page-container'>
      <div className={`loading-container ${loader ? 'active' : ''}`}>
        <Loading isVisible={loader} />
      </div>

      <div className='operations-header-container'>
        <OperationsHistoryHeader count={historyData.length}/>
      </div>

      <div className='operations-body-container'>
        <OperationsHistoryBody transactions={historyData} />
      </div>

    </div>
  )
}
