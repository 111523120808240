// base
import React, { useContext } from 'react';
import './additionsBody.css';

// components
import AdditionCard from '../addition_card/AdditionCard';

// context
import { AdditionsContext } from '../../../pages/additions/additionsContext';

export default function AdditionsBody() {
  const {
    additions
  } = useContext(AdditionsContext)
  return (
    <div className='additions-body-component'>

      {additions.length > 0 ? (
        additions.map((addition, index) => (
          <div key={addition.additionId || index} className='addition-card-container'>
            <AdditionCard 
              addition={addition}
              _index={index + 1}
            />
          </div>
        ))
      ) : (
        <div className="no-additions-message">
          No additions found.
        </div>
      )}

    </div>
  )
}
