// base
import React from 'react'

// context
import { UsersProvider } from './usersContext';

// components
import UsersMain from '../../../components/users_components/users_main/UsersMain';

export default function Users() {
  return (
    <UsersProvider>
      <UsersMain />
    </UsersProvider>
  )
}
