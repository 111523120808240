import {
  fetchScalarData, 
  fetchMultipleData
} from '../../global/utils/functions/apiService';

const UpdatesModeEnum = Object.freeze({
  ALL_UPDATES: 'all-updates',
  BY_TABLE: 'by-table',
});

function resetMessage(state) {
  state({
    title: '',
    message: '',
    isVisible: false
  });
}

async function getAllUpdatesCount() {
  const result = await fetchScalarData('/Updates/GetUpdatesCount');
  if (result.success) {
    return result.data.Response.data.count;
  } else {
    console.log('Error fetching all updates count:', result.message);
    return 0;
  }
}

async function getUpdatesPagesCountAll() {
  const result = await fetchScalarData('/Updates/GetUpdatesPagesCount_All');
  if (result.success) {
    return result.data.Response.data.count;
  } else {
    console.log('Error fetching all updates pages count:', result.message);
    return 0;
  }
}

async function getAllUpdates(pageNumber) {
  const url = '/Updates/GetAllUpdates';

  const response = await fetchMultipleData(url, pageNumber);
  
  if (response.success) {
    const updates = response?.data?.Response?.data?.updates || [];

    return updates;
  } else {
    console.log(response.message);
    return [];
  }
}

async function getTables() {
  const url = '/SystemTables/GetAllTables';

  const response = await fetchMultipleData(url);
  
  if (response.success) {
    const tables = response?.data?.Response?.data?.tables || [];

    return tables;
  } else {
    console.log(response.message);
    return [];
  }
}

async function getUpdatesPagesCountByTable(tableId) {
  const result = await fetchScalarData(`/Updates/GetUpdatesPagesCountByTable/${tableId}`);
  if (result.success) {
    return result.data.Response.data.count;
  } else {
    console.log('Error fetching updates pages count:', result.message);
    return 0;
  }
}

async function getUpdatesByTable(tableId, pageNumber) {
  const url = `/Updates/GetUpdatesByTable/${tableId}`;

  const response = await fetchMultipleData(url, pageNumber);
  
  if (response.success) {
    const updates = response?.data?.Response?.data?.updates || [];

    return updates;
  } else {
    console.log(response.message);
    return [];
  }
}

export {
  UpdatesModeEnum,
  resetMessage,
  getAllUpdatesCount,
  getUpdatesPagesCountAll,
  getAllUpdates,
  getTables,
  getUpdatesPagesCountByTable,
  getUpdatesByTable
}