// base
import './adminsHeader.css';
import React from 'react'
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// logic
import {
  AllAdminsCountAsync,
  DeletedAdminsCountAsync,
  BlockedAdminsCountAsync,
  GetAllAdminsPagesCountAsync,
  GetAllAdminsAsync,
  GetDeletionAdminsPagesCountAsync,
  GetDeletionAdminsAsync,
  GetBlockedAdminsPagesCountAsync,
  GetBlockedAdminsAsync
} from './adminsHeaderLogic';

// components
import AnalyticalCard from '../../common_components/analytical_card/AnalyticalCard'
import AdditionCard from '../../common_components/addition_card/AdditionCard';

// icons
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import BlockIcon from '@mui/icons-material/Block';

export default function AdminsHeader({ adminsState, pageNumber, pageNumberState, pagesCountState, setLoader, setNewAdminMode }) {

  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);
  const [adminsLoaded, setAdminsLoaded] = useState('all-admins');

  const [data, setData] = useState({
    allAdmins: 0,
    DeletedAdmins: 0,
    BlockedAdmins: 0
  })

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      const allAdmins = await AllAdminsCountAsync();
      const deletedAdmins = await DeletedAdminsCountAsync();
      const blockedAdmins = await BlockedAdminsCountAsync();
      setLoader(false);
      
      setData({
        allAdmins: allAdmins || 0,
        DeletedAdmins: deletedAdmins || 0,
        BlockedAdmins: blockedAdmins || 0
      });
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
  
      try {
        if (adminsLoaded === 'all-admins') {
          await GetAllAdminsPagesCountAsync(pagesCountState);
          await GetAllAdminsAsync(adminsState, pageNumber);
        } else if (adminsLoaded === 'deleted-admins') {
          await GetDeletionAdminsPagesCountAsync(pagesCountState);
          await GetDeletionAdminsAsync(adminsState, pageNumber);
        } else if (adminsLoaded === 'blocked-admins') {
          await GetBlockedAdminsPagesCountAsync(pagesCountState);
          await GetBlockedAdminsAsync(adminsState, pageNumber);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoader(false);
      }
    };
  
    fetchData();
  }, [pageNumber, adminsLoaded]);
  

  const handleNewAdminClick = () => {
    if ((currentAdminPermissions & 32) !== 32)
      return ;
    else
      setNewAdminMode(true)
  }

  return (
    <div className='admins-header-component'>

      <div className={`admins-card-container new-admin-container ${(currentAdminPermissions & 32) !== 32 ? 'disable' : ''}`} onClick={handleNewAdminClick}>
        <AdditionCard />
      </div>

      <div className={`admins-card-container`}>
        <AnalyticalCard title={'Active Admins'} icon={PeopleAltIcon} value={data.allAdmins} isActive={adminsLoaded === 'all-admins'} onClick={() => {
          setAdminsLoaded('all-admins')
          pageNumberState(1);
        }}/>
      </div>

      <div className={`admins-card-container`}>
        <AnalyticalCard title={'Deleted Admins'} icon={DeleteIcon} value={data.DeletedAdmins} isActive={adminsLoaded === 'deleted-admins'} onClick={() => {
          setAdminsLoaded('deleted-admins')
          pageNumberState(1);
        }}/>
      </div>

      <div className={`admins-card-container`}>
        <AnalyticalCard title={'Blocked Admins'} icon={BlockIcon} value={data.BlockedAdmins} isActive={adminsLoaded === 'blocked-admins'} onClick={() => {
          setAdminsLoaded('blocked-admins');
          pageNumberState(1);
        }}/>
      </div>

    </div>
  )
}
