function saveCurrentAdminToSession(admin) {
  sessionStorage.setItem('currentAdmin', JSON.stringify(admin));
}

function getCurrentAdminFromSession() {
  const admin = sessionStorage.getItem('currentAdmin');
  return admin ? JSON.parse(admin) : null;
}

function removeCurrentAdminFromSession() {
  sessionStorage.removeItem('currentAdmin');
}

export {
  saveCurrentAdminToSession,
  getCurrentAdminFromSession,
  removeCurrentAdminFromSession
}