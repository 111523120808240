import {
  fetchScalarData, 
  fetchMultipleData
} from '../../global/utils/functions/apiService';

const DeletionsModeEnum = Object.freeze({
  ALL_DELETIONS: 'all-deletions',
  BY_TABLE: 'by-table',
});

function resetMessage(state) {
  state({
    title: '',
    message: '',
    isVisible: false
  });
}

async function getAllDeletionsCount() {
  const result = await fetchScalarData('/Deletions/GetDeletionsCount');
  if (result.success) {
    return result.data.Response.data.count;
  } else {
    console.log('Error fetching all deletion count:', result.message);
    return 0;
  }
}

async function getDeletionsPagesCountAll() {
  const result = await fetchScalarData('/Deletions/GetDeletionsPagesCount_All');
  if (result.success) {
    return result.data.Response.data.count;
  } else {
    console.log('Error fetching all deletions pages count:', result.message);
    return 0;
  }
}

async function getAllDeletions(pageNumber) {
  const url = '/Deletions/GetAllDeletions';

  const response = await fetchMultipleData(url, pageNumber);
  
  if (response.success) {
    const deletions = response?.data?.Response?.data?.deletions || [];

    return deletions;
  } else {
    console.log(response.message);
    return [];
  }
}

async function getTables() {
  const url = '/SystemTables/GetAllTables';

  const response = await fetchMultipleData(url);
  
  if (response.success) {
    const tables = response?.data?.Response?.data?.tables || [];

    return tables;
  } else {
    console.log(response.message);
    return [];
  }
}

async function getDeletionsPagesCountByTable(tableId) {
  const result = await fetchScalarData(`/Deletions/GetDeletionsPagesCountByTable/${tableId}`);
  if (result.success) {
    return result.data.Response.data.count;
  } else {
    console.log('Error fetching deletions pages count:', result.message);
    return 0;
  }
}

async function getDeletionsByTable(tableId, pageNumber) {
  const url = `/Deletions/GetDeletionsByTable/${tableId}`;

  const response = await fetchMultipleData(url, pageNumber);
  
  if (response.success) {
    const deletions = response?.data?.Response?.data?.deletions || [];

    return deletions;
  } else {
    console.log(response.message);
    return [];
  }
}

export {
  DeletionsModeEnum,
  resetMessage,
  getAllDeletionsCount,
  getDeletionsPagesCountAll,
  getAllDeletions,
  getTables,
  getDeletionsPagesCountByTable,
  getDeletionsByTable
}