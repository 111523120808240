import {
  fetchScalarData, 
  fetchMultipleData
} from '../../global/utils/functions/apiService';

const AdditionsModeEnum = Object.freeze({
  ALL_ADDITIONS: 'all-additions',
  BY_TABLE: 'by-table',
});

function resetMessage(state) {
  state({
    title: '',
    message: '',
    isVisible: false
  });
}

async function getAllAdditionsCount() {
  const result = await fetchScalarData('/Additions/GetAdditionsCount');
  if (result.success) {
    return result.data.Response.data.count;
  } else {
    console.log('Error fetching all additions count:', result.message);
    return 0;
  }
}

async function getAdditionsPagesCountAll() {
  const result = await fetchScalarData('/Additions/GetAdditionsPagesCount_All');
  if (result.success) {
    return result.data.Response.data.count;
  } else {
    console.log('Error fetching all additions pages count:', result.message);
    return 0;
  }
}

async function getAllAdditions(pageNumber) {
  const url = '/Additions/GetAllAdditions';

  const response = await fetchMultipleData(url, pageNumber);
  
  if (response.success) {
    const additions = response?.data?.Response?.data?.additions || [];

    return additions;
  } else {
    console.log(response.message);
    return [];
  }
}

async function getTables() {
  const url = '/SystemTables/GetAllTables';

  const response = await fetchMultipleData(url);
  
  if (response.success) {
    const tables = response?.data?.Response?.data?.tables || [];

    return tables;
  } else {
    console.log(response.message);
    return [];
  }
}

async function getAdditionsPagesCountByTable(tableId) {
  const result = await fetchScalarData(`/Additions/GetAdditionsPagesCountByTable/${tableId}`);
  if (result.success) {
    return result.data.Response.data.count;
  } else {
    console.log('Error fetching additions pages count:', result.message);
    return 0;
  }
}

async function getAdditionsByTable(tableId, pageNumber) {
  const url = `/Additions/GetAdditionsByTable/${tableId}`;

  const response = await fetchMultipleData(url, pageNumber);
  
  if (response.success) {
    const additions = response?.data?.Response?.data?.additions || [];

    return additions;
  } else {
    console.log(response.message);
    return [];
  }
}

export {
  AdditionsModeEnum,
  resetMessage,
  getAllAdditionsCount,
  getAdditionsPagesCountAll,
  getAllAdditions,
  getTables,
  getAdditionsPagesCountByTable,
  getAdditionsByTable
}