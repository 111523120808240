// base
import React, { useState } from 'react'
import './generalQuestions.css';
import { useSelector } from 'react-redux';

// logic
import {
  GeneralQuestionsMode,
  resetMessage,
  deleteGeneralQuestionAsync
} from './generalQuestionsLogic';

// components
import Model  from '../../components/model/Model';
import MessageBox from '../../components/message_box/MessageBox';
import ContainedButton from '../../components/inputs/buttons/contained_button/ContainedButton';
import Loading from '../../components/loading/Loading';
import AddUpdateComponent from '../../components/general_questions_components/add_update_component/AddUpdateComponent';
import GeneralQuestionsHeader from '../../components/general_questions_components/general_questions_header/GeneralQuestionsHeader';
import GeneralQuestionsBody from '../../components/general_questions_components/general_questions_body/GeneralQuestionsBody';

export default function GeneralQuestions() {
  const currentAdminId = useSelector((state) => state.currentUser.adminId);
  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);

  const [loading, setLoading] = useState(false);
  const [modelMessage, setModelMessage] = useState({ title: '', message: '', isVisible: false });
  const [messageBox, setMessageBox] = useState({ title: '', message: '', isVisible: false });
  const [reload, setReload] = useState(false);
  const [pageMode, setPageMode] = useState(GeneralQuestionsMode.READ);
  const [generalQuestionsCount, setGeneralQuestionsCount] = useState(0);

  const [questionForUpdate, setQuestionForUpdate] = useState({
    questionId: 0,
    question: '',
    answer: ''
  });

  const [questionIdForDelete, setQuestionIdForDelete] = useState(0);

  const deleteGeneralQuestion = async() => {
    await deleteGeneralQuestionAsync(questionIdForDelete, currentAdminId, currentAdminPermissions,setModelMessage, setLoading, reload, setReload);
  }

  return (
    <div className='general-questions-container'>
      
      <Model 
        title={modelMessage.title} 
        message={modelMessage.message} 
        status={modelMessage.isVisible} 
        setStatus={setModelMessage} 
      />

      <MessageBox 
        title={messageBox.title} 
        message={messageBox.message} 
        isVisable={messageBox.isVisible} 
        onClose={() => {resetMessage(setMessageBox)}}
      >
        <div className='confirm-btn-container'>
          <ContainedButton title={'Confirm'} onclickHandler={async() => {
            resetMessage(setMessageBox);
            await deleteGeneralQuestion();
          }} />
        </div>
      </MessageBox>

      <div className={`loading-container ${loading ? 'active' : ''}`}>
        <Loading isVisible={loading} />
      </div>

      <div className={`add-update-container ${pageMode === GeneralQuestionsMode.ADD ? '' : 'in-active'}`}>
        <AddUpdateComponent question={questionForUpdate} mode={pageMode} setMode={setPageMode} reload={reload} setReload={setReload} />
      </div>

      <div className={`add-update-container ${pageMode === GeneralQuestionsMode.UPDATE ? '' : 'in-active'}`}>
        <AddUpdateComponent question={questionForUpdate} mode={pageMode} setMode={setPageMode} reload={reload} setReload={setReload} />
      </div>

      <div className='general-questions-header-container'>
        <GeneralQuestionsHeader count={generalQuestionsCount} setMode={setPageMode} />
      </div>

      <div className='general-questions-body-container'>
        <GeneralQuestionsBody 
          setQuestionIdForDelete={setQuestionIdForDelete}
          setQuestionForUpdate={setQuestionForUpdate}
          setMode={setPageMode}
          reload={reload}
          setReload={setReload}
          setMessageBox={setMessageBox}
          setLoading={setLoading}
          setGeneralQuestionsCount={setGeneralQuestionsCount}
        />
      </div>

    </div>
  )
}
