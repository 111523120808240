// base
import './admins.css';
// import { Outlet } from 'react-router-dom';
import { useState } from 'react';

// logic
import { GetAllAdminsAsync, GetAllAdminsPagesCountAsync } from '../../../components/admins_components/admins_header/adminsHeaderLogic';

// components
import AdminsHeader from '../../../components/admins_components/admins_header/AdminsHeader';
import AdminsSearchBar from '../../../components/admins_components/admins_search_bar/AdminsSearchBar';
import AdminsBody from '../../../components/admins_components/admins_body/AdminsBody';
import Loading from '../../../components/loading/Loading';
import NewAdmin from '../../../components/admins_components/new_admin/NewAdmin';

// material components
import Pagination from '@mui/material/Pagination';


export default function Admins() {

  const [admins, setAdmins] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pagesCount, setPagesCount] = useState(1);
  const [pageNumber, setPageNumber] = useState(pagesCount > 0 ? 1 : 0);
  const [isAddNewMode, setAddNewMode] = useState(false);

  const loadAdmins = async (page = 1) => {
    setLoader(true);
    await GetAllAdminsAsync(setAdmins, page);
    setLoader(false);
  };

  const handlePaginationChange = (event, value) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setPageNumber(value)
    loadAdmins(value)
    GetAllAdminsPagesCountAsync(setPagesCount)
  }

  return (
    <div className='admins-container'>
      {/* <Outlet /> */}

      <NewAdmin newAdminMode={isAddNewMode} setNewAdminMode={setAddNewMode} />

      <div className='admins-header'>
        <AdminsHeader adminsState={setAdmins} pageNumber={pageNumber} pageNumberState={setPageNumber} pagesCountState={setPagesCount} setLoader={setLoader} setNewAdminMode={setAddNewMode} />
      </div>

      <div className='admins-body'>
        <AdminsSearchBar 
          adminsState={setAdmins} 
          loadAllAdmins={loadAdmins} 
          setLoader={setLoader} 
        />
        <AdminsBody 
          admins={admins} 
          onAdminsChange={loadAdmins} 
        />
      </div>

      <div className={`admins-pagination-container ${pagesCount <= 0 ? 'hidden' : ''}`}>
        <Pagination 
          count={pagesCount} 
          page={pageNumber}
          variant="outlined" 
          shape="rounded" 
          color="primary" 
          showFirstButton 
          showLastButton 
          size="small"
          onChange={handlePaginationChange}
        />
      </div>

      <Loading isVisible={loader} />
    </div>
  );
}
