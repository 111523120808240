// base
import React from 'react'
import './permissions.css';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// icons
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { IoIosCheckmarkCircle } from "react-icons/io";

// logic
import {
  GetPermissionsAsync,
  setPermissionsAsync
} from './permissionsLogic';

// components
import Model from '../../../model/Model';
import MessageBox from '../../../message_box/MessageBox';
import ContainedButton from '../../../inputs/buttons/contained_button/ContainedButton';

export default function Permissions({ adminId, adminFullname, adminPermissions, reload, reloadState }) {

  const navigate = useNavigate();
  const currentAdminId = useSelector((state) => state.currentUser.adminId);
  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);
  const [adminPermissionsList, setAdminPermissionsList] = useState([]);
  const [currentAdminPermissionsList, setCurrentAdminPermissionsList] = useState([]);
  const [saveLoader, setSaveLoader] = useState(false);
  const [isWriteMode, setWriteMode] = useState(false);
  const [totalPermissions, setTotalPermissions] = useState(0);
  const [messageLabel, setMessageLabel] = useState({title: '', message: '', status: false});
  const [messageBox, setMessageBox] = useState({title: '', message: '', status: false});

  useEffect(() => {
    const GetPermissions = async () => {
      if (adminId) {
        const adminPermissionsList = await GetPermissionsAsync(adminId);
        setAdminPermissionsList(adminPermissionsList);
        setTotalPermissions(calculateTotalPermissions(adminPermissionsList))

        setCurrentAdminPermissionsList([]);
        setWriteMode(false);

      } else {
        setAdminPermissionsList([]);
        setCurrentAdminPermissionsList([]);
        setTotalPermissions(0);
        setWriteMode(false);
      }
    };
  
    GetPermissions();
  }, [adminId, adminPermissions]);

  function calculateTotalPermissions (permissions) {
    if (!permissions || permissions.length === 0) 
      return 0;
  
    let total = 0;
    for (let i = 0; i < permissions.length; i++) {
      if (permissions[i].permissionValus) {
        total += Number(permissions[i].permissionValus);
      }
    }
  
    return total;
  }

  const handleInputChange = (value, isChecked) => {
    setTotalPermissions(prevTotal => isChecked ? prevTotal + Number(value) : prevTotal - Number(value));
  }  

  return (
    <div className='permissions-component'>

      <Model title={messageLabel.title} message={messageLabel.message} status={messageLabel.status} setStatus={setMessageLabel} />

      <MessageBox title={messageBox.title} message={messageBox.message} isVisable={messageBox.status} onClose={() => {setMessageBox(false)}}>
        <div className='confirm-button-container'>
          <ContainedButton title={'confirm'} onclickHandler={async() => {
            setMessageBox(false);
            await setPermissionsAsync(
              adminId, totalPermissions, currentAdminId, currentAdminPermissions, 
              reload, reloadState, setSaveLoader, setMessageLabel, navigate
            )
          }} />
        </div>
      </MessageBox>

      <div className='permissions-header-container'>
        
        <p className='permissions-header-label'>Permissions</p>
        
        <div className='header-icon-container'>
          {isWriteMode ? 
            <CloseIcon className='edit-permissions-icon' fontSize='' onClick={() => {setWriteMode(false)}} />
          : 
            <EditIcon 
              className={`edit-permissions-icon ${adminPermissions === -2 || (currentAdminPermissions & 2) !== 2 ? 'desabled' : ''}`} 
              fontSize='' 
              onClick={async() => {
              const permissions = await GetPermissionsAsync(currentAdminId);
              
              if (permissions) {
                setCurrentAdminPermissionsList(permissions);
                setWriteMode(true);
              }
              else{
                setCurrentAdminPermissionsList([]);
                setWriteMode(false);
              }
            }} />
          }
        </div>

      </div>

      <div className='permissions-body-container'>
        <div className='permissions-items-container'>
          {
            adminPermissions < 0 ? 
            (
              <p className='no-permissions'>The administrator has full permissions.</p>
            ) 
            : 
            (adminPermissionsList.length > 0 ? (
              adminPermissionsList.map((permission, index) => (
                  <div className='permission' key={`permission-${index}`}>
                    <IoIosCheckmarkCircle className='permission-accept-icon' fontSize='large'/>
                    <p className='permission-title'>{permission.permissionName}</p>
                  </div>
                ))
              ) : <p className='no-permissions'>No permissions available.</p>
            )
          }
        </div>

        <div className={`set-permissions-container ${adminPermissions === -2 || (currentAdminPermissions & 2) !== 2 ? 'desabled' : ''} ${isWriteMode ? 'write-mode' : ''}`}>
          {currentAdminPermissionsList.map((permission, index) => {
            const isChecked = adminPermissionsList.some(p => p.permissionName === permission.permissionName);

            return (
              <div className='permission-input-container' key={`permission-${index}`}>
                <input 
                  id={`permission-${index}`} 
                  className='permission-input' 
                  type='checkbox' 
                  defaultChecked={isChecked}
                  value={permission.permissionValus}
                  onChange={(e) => {
                    handleInputChange(permission.permissionValus, e.target.checked);
                  }}
                />
                <label htmlFor={`permission-${index}`} className='permission-input-label'>
                  {permission.permissionName}
                </label>
              </div>
            );
          })}

          <div className='permissions-done-container'>
            <p className={`permissions-done ${saveLoader ? 'loading' : ''}`} onClick={() => {setMessageBox({
              title: 'Confirm', message: `Are you sure to change ${adminFullname} permissions.`, status: true
            })}}>
              Done
            </p>
            <div className={`set-permissions-loader ${saveLoader ? 'active' : ''}`}></div>
          </div>

        </div>
      </div>

    </div>
  )
}
