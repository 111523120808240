import React from 'react'
import './main.css';

export default function Main() {
  return (
    <div className='main-container'>
      Main
    </div>
  )
}
