// base
import React from 'react';
import './baseInfoCard.css';

// logic
import { getDate } from '../../../../global/utils/functions/utils';

// material components
import Tooltip from '@mui/material/Tooltip';

// material ui icons
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import MailIcon from '@mui/icons-material/Mail';
import { FaUserShield } from "react-icons/fa";
import { MdVerifiedUser } from "react-icons/md"; // active
import { SiAdblock } from "react-icons/si"; // in active
import { MdRemoveModerator } from "react-icons/md"; // in active

export default function BaseInfoCard({ admin }) {

  function renderCardProfileImage(profileImageURL, userName) {
    if (profileImageURL) {
      return <img src={profileImageURL} alt='profile' className='profile-image' />;
    } else {
      const initials = userName ? userName.slice(0, 2).toUpperCase() : '';
      return (
        <div className='profile-initials'>
          {initials}
        </div>
      );
    }
  };

  const renderAdminStatus = () => {
    if (admin.isDeleted) {
      return <Tooltip title={'Deleted Admin'}><p><MdRemoveModerator className='status-message deleted' /></p></Tooltip>;
    } else if (admin.isActive) {
      return <Tooltip title={'Active Admin'}><p><MdVerifiedUser className='status-message active' /></p></Tooltip>;
    } else {
      return <Tooltip title={'Inactive Admin'}><p><SiAdblock className='status-message in-active' /></p></Tooltip>;
    }
  };

  const renderAdminRole = (permissions) => {
    if (permissions === -2) {
      return 'Master';
    } else if (permissions === -1) {
      return 'Administrator';
    } else if (permissions === 0) {
      return 'Pending Admin';
    } else if (permissions > 0) {
      return 'Admin';
    } else {
      return 'Unknown Role';
    }
  };

  return (
    <div className='base-info-card-component'>
      
      <div className='img-container'>
        {renderCardProfileImage(admin.profileImageURL, admin.userName)}
      </div>

      <div className='info-container'>

        <div className='info-1'>

          <div className='fullname-container'>
            <h2 className='fullname'>{admin.fullName}</h2>
            <div className='user-status'>
              {renderAdminStatus()}
            </div>
          </div>

          <div className='info'>
            <FaUserShield fontSize='large' />
            <h3 className='admin-role'>{renderAdminRole(admin.permissions)}</h3>
          </div>

          <div className='info'>
            <AlternateEmailIcon fontSize=''/>
            <h3 className='username'>{admin.userName}</h3>
          </div>

          <div className='info'>
            <MailIcon fontSize=''/>
            <p className='email'>{admin.email}</p>
          </div>

        </div>

        <div className='info-2'>
          <p>Joined On</p>
          <p className='registration-date'>{getDate(admin.registrationDate)}</p>
        </div>

      </div>

    </div>
  )
}
