import React from 'react'
import './passwordVerification.css';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PasswordInput from '../../inputs/password_input/PasswordInput'
import Messagelabel from '../../message_label/MessageLabel'
import ContainedButton from '../../inputs/buttons/contained_button/ContainedButton';
import TextButton from '../../inputs/buttons/text_button/TextButton';
import Loading from '../../loading/Loading'
import {
  validatePassword,
  onChangeHandler,
  handlePreviousClick,
  handleChangeClickAsync
} from './passwordVerificationLogic'

export default function PasswordVerification({ email, stepsState }) {
  
  const initMessage = 'Create a strong password and confirm it to secure your account.';
  const navigate = useNavigate();
  const [message, setMessage] = useState({isVisible: true, message: initMessage, type: 'success'});
  const [password, setPassword] = useState({password: '', confirmPassword: ''});
  const [loader, setLoader] = useState(false);

  async function handleOnClick() {
      if (await handleChangeClickAsync(email, password.password, password.confirmPassword, setMessage, setLoader, stepsState)){
        setMessage({
          isVisible: true,
          message: 'Your password has been changed successfully. You can now log in with your new password.',
          type: 'success'
        });

        setTimeout(() => {
          navigate('/login');
        }, 5000);
    }
  }

  return (
    <div className='password-verification-component'>
      <h2 className='password-verification-title'>New Password</h2>

      <div className='password-verification-message'>
        <Messagelabel message={message.message} isVisible={message.isVisible} type={message.type} />
      </div>

      <div className='password-verification-inputs-container'>
        <PasswordInput 
          title={'Password'} 
          value={password.password}
          onChangeHandler={(newValue) => {onChangeHandler('password', newValue, setPassword)}}
          validateInput={validatePassword}
          tooltipTitle={'Choose a strong password, at least 8 characters long.'}
          onEnter={handleOnClick}
          />

        <PasswordInput 
          title={'Confirm password'} 
          value={password.confirmPassword}
          onChangeHandler={(newValue) => {onChangeHandler('confirmPassword', newValue, setPassword)}}
          validateInput={validatePassword}
          tooltipTitle={'Re-enter your password to confirm.'}
          onEnter={handleOnClick}
          />
      </div>

      <div className='password-verification-buttons-container'>
        <div className='password-verification-Previous-button'>
          <TextButton title='Previous' onclickHandler={() => {handlePreviousClick(stepsState)}}/>
        </div>

        <div className='password-verification-change-button'>
          <ContainedButton title='Change' onclickHandler={handleOnClick}/>
        </div>
      </div>

      <Loading isVisible={loader} />
    </div>
  )
}
