import React, { createContext, useState } from 'react';
import { ErrorsModeEnum } from './errorsLogic';

export const ErrorsContext = createContext();

export const ErrorsProvider = ({ children }) => {

  const [errors, setErrors] = useState([]);
  const [errorsMode, setErrorsMode] = useState(ErrorsModeEnum.ALL_ERRORS);
  const [errorsPagesCount, setErrorsPagesCount] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [modelMessage, setModelMessage] = useState({ title: '', message: '', isVisible: false });
  const [messageBox, setMessageBox] = useState({ title: '', message: '', isVisible: false });
  const [reload, setReload] = useState(false);

  return (
  <ErrorsContext.Provider
    value={{
      errors,
      setErrors,
      errorsMode,
      setErrorsMode,
      errorsPagesCount,
      setErrorsPagesCount,
      currentPageNumber,
      setCurrentPageNumber,
      loading,
      setLoading,
      modelMessage,
      setModelMessage,
      messageBox,
      setMessageBox,
      reload,
      setReload
    }}
  >
      {children}
  </ErrorsContext.Provider>
  );
};
