import React from 'react'
import './cardDropdownItem.css';

export default function CardDropdownItem({ title, isDanger, isDisabled , onClick, children }) {
  return (
    <div className={`card-dropdown-item-component ${isDanger ? 'danger' : ''} ${isDisabled ? 'disabled' : ''}`} onClick={onClick}>
      
      {children}
      <p>{title}</p>

    </div>
  )
}
