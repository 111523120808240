// base
import React, { useContext } from 'react';
import './updateBody.css';

// components
import UpdateCard from '../update_card/UpdateCard';

// context
import { UpdatesContext } from '../../../pages/updates/updatesContext';

export default function UpdateBody() {
  const {
    updates
  } = useContext(UpdatesContext)
  return (
    <div className='updates-body-component'>

      {updates.length > 0 ? (
        updates.map((update, index) => (
          <div key={update.updateId || index} className='update-card-container'>
            <UpdateCard 
              update={update}
              _index={index + 1}
            />
          </div>
        ))
      ) : (
        <div className="no-updates-message">
          No updates found.
        </div>
      )}

    </div>
  )
}
