import React from 'react'
import './additionCard.css';
import AddIcon from '@mui/icons-material/Add';

export default function AdditionCard({ onClick }) {
  return (
    <div className='addition-card-component'>
      <AddIcon className='add-icon' fontSize=''/>
    </div>
  )
}
