// base
import React, { useContext, useState } from 'react';
import './usersBody.css';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

// context
import { UsersContext } from '../../../pages/users/users/usersContext';

// icons
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { TbListDetails } from "react-icons/tb";

// logic
import { getDate } from '../../../global/utils/functions/utils';
import { getBackgroundColor } from '../../../pages/users/users/usersLogic';

// components 
import CardDropDown from '../../card_dropdown/card_dropdown_menu/CardDropDown';
import CardDropdownItem from '../../card_dropdown/card_Dropdown_item/CardDropdownItem';

export default function UsersBody() {
  const navigate = useNavigate();
  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);

  const { users } = useContext(UsersContext);
  const [openRowId, setOpenRowId] = useState(null); 

  const toggleDropdown = (userId) => {
    setOpenRowId(openRowId === userId ? null : userId);
  };

  const isAdminHasPermissions = () => {
    if (currentAdminPermissions != null) {
        if (currentAdminPermissions < 0) {
            return true;
        }

        const hasPermission = (currentAdminPermissions & 64) === 64;
        if (!hasPermission) {
          return false;
        }
        return hasPermission;
    } else {
        navigate('/login');
        return false;
    }
};

  return (
    <div className='users-body-component'>
      <div className='users-table-container'>
        <table className="users-table" onClick={() => {setOpenRowId(null)}}>
        <thead>
          <tr>
            <th className='first-column'>#</th>
            <th>Name</th>
            <th>Username</th>
            <th>Branch</th>
            <th>Joined on</th>
            <th>Status</th>
            <th className='header-actions last-column'>Actions</th>
          </tr>
        </thead>

        <tbody>
          {users && users.length > 0 ?
            (
              users.map((user, index) => {
                const initials = user.userName ? user.userName.slice(0, 2).toUpperCase() : "!";
                const backgroundColor = getBackgroundColor(user.userId);

                return (
                  <tr key={user.userId}>
                  <td>{index + 1}</td>

                  <td className="user-name">
                    {user.profileImageURL ? 
                    (
                      <img
                        src={user.profileImageURL}
                        alt="Avatar"
                        className="user-avatar"
                      />
                    ) 
                    : 
                    (
                      <div
                        className="user-avatar-placeholder"
                        style={{ backgroundColor }}
                      >
                        {initials}
                      </div>
                    )}
                    <div>
                      <span>{user.fullName}</span>
                    </div>
                  </td>

                  <td>{user.userName}</td>
                  <td>{user.branchName}</td>
                  <td>{getDate(user.registrationDate)}</td>
                  <td>
                    <span className={`status ${user.isDeleted ? 'deleted' : 'active'}`}>
                      {user.isDeleted ? "Deleted" : "Active"}
                    </span>
                  </td>
                  <td className="actions">
                    <div className='actions-dropdown-container'>
                      {openRowId === user.userId && (
                        <CardDropDown status={true}>
                          <Link to={`${user.userId ? `/users/${user.userId}` : '/login'}`} className='drop-down-item-link'>
                            <CardDropdownItem title={'Details'} isDanger={false} isDisabled={!isAdminHasPermissions()}>
                              <TbListDetails fontSize={'20px'} />
                            </CardDropdownItem>
                          </Link>
                        </CardDropDown>
                      )}
                    </div>
                    <MoreHorizIcon className='more-options-btn' fontSize='' onClick={(event) => {
                      event.stopPropagation();
                      toggleDropdown(user.userId);
                    }} />
                  </td>
                </tr>
                );
                })
            )
            :
            (
              <tr>
                <td className='no-users-available' colSpan="7">No users available.</td>
              </tr>
            )}
        </tbody>
        </table>

      </div>
    </div>
  )
}