// base
import React, { useContext, useEffect } from 'react'
import './errorsHeader.css';

// context 
import { ErrorsContext } from '../../../pages/errors/errors/errorsContext';

// logic
import {
  ErrorsModeEnum,
  getErrorsPagesCount_All,
  getErrorsPagesCount_Handled,
  getErrorsPagesCount_NotHandled,
  getAllErrors,
  getHandledErrors,
  getNotHandledErrors
} from '../../../pages/errors/errors/errorsLogic';

// components
import AnalyticalCard from '../../common_components/analytical_card/AnalyticalCard'

// icons
import { MdListAlt } from "react-icons/md";
import { MdCheckCircle } from "react-icons/md";
import { MdError } from "react-icons/md";

export default function ErrorsHeader() {
  const {
    setErrors, 
    errorsMode,
    setErrorsMode,
    currentPageNumber,
    setCurrentPageNumber,
    setErrorsPagesCount,
    setLoading
  } = useContext(ErrorsContext);

  useEffect(() => {
    getErrorsWithPagesCountAsync();
  }, [currentPageNumber, errorsMode]);

  async function getErrorsWithPagesCountAsync() {
    try {
      setLoading(true);
      
      switch (errorsMode) {
        case ErrorsModeEnum.ALL_ERRORS:
          setErrors(await getAllErrors(currentPageNumber));
          setErrorsPagesCount(await getErrorsPagesCount_All());
          break;

        case ErrorsModeEnum.HANDLED_ERRORS:
          setErrors(await getHandledErrors(currentPageNumber));
          setErrorsPagesCount(await getErrorsPagesCount_Handled());
          break;
  
          case ErrorsModeEnum.NOT_HANDLED_ERRORS:
            setErrors(await getNotHandledErrors(currentPageNumber));
            setErrorsPagesCount(await getErrorsPagesCount_NotHandled());
            break;

        default:
          console.warn("Unknown errors mode:", errorsMode);
          return;
      }
    } catch (error) {
      console.error('Error fetching errors with pages count:', error);
    } finally {
      setLoading(false);
    }
  }

  const onAllErrorsClickHandler = () => {
    setCurrentPageNumber(1);
    setErrorsMode(ErrorsModeEnum.ALL_ERRORS);
  }

  const onHandledErrorsClickHandler = () => {
    setCurrentPageNumber(1);
    setErrorsMode(ErrorsModeEnum.HANDLED_ERRORS);
  }

  const onNotHandledErrorsClickHandler = () => {
    setCurrentPageNumber(1);
    setErrorsMode(ErrorsModeEnum.NOT_HANDLED_ERRORS);
  }

  return (
    <div className='errors-header-component'>

      <div className={`errors-card-container`}>
        <AnalyticalCard 
          title={'All Errors'} 
          icon={MdListAlt} 
          value={'~'} 
          isActive={errorsMode === ErrorsModeEnum.ALL_ERRORS} 
          onClick={onAllErrorsClickHandler} 
        />
      </div>

      <div className={`errors-card-container`}>
        <AnalyticalCard 
          title={'Resolved Errors'} 
          icon={MdCheckCircle} 
          value={'~'} 
          isActive={errorsMode === ErrorsModeEnum.HANDLED_ERRORS} 
          onClick={onHandledErrorsClickHandler} 
        />
      </div>

      <div className={`errors-card-container`}>
        <AnalyticalCard 
          title={'Unresolved Errors'} 
          icon={MdError} 
          value={'~'} 
          isActive={errorsMode === ErrorsModeEnum.NOT_HANDLED_ERRORS} 
          onClick={onNotHandledErrorsClickHandler} 
        />
      </div>

    </div>
  )
}
