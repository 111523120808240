// base
import React, { useContext } from 'react';
import './deletionBody.css';

// components
import DeletionCard from '../deletion_card/DeletionCard';

// context
import { DeletionsContext } from '../../../pages/deletions/deletionsContext';

export default function DeletionBody() {
  const {
    deletions
  } = useContext(DeletionsContext)
  return (
    <div className='deletions-body-component'>

      {deletions.length > 0 ? (
        deletions.map((deletion, index) => (
          <div key={deletion.deletionId || index} className='deletion-card-container'>
            <DeletionCard 
              deletion={deletion}
              _index={index + 1}
            />
          </div>
        ))
      ) : (
        <div className="no-deletions-message">
          No deletions found.
        </div>
      )}

    </div>
  )
}
