// base
import React, { useContext } from 'react';
import './usersMain.css';

// users context
import { UsersContext } from '../../../pages/users/users/usersContext';

// components
import Pagination from '@mui/material/Pagination';
import UsersHeader from '../users_header/UsersHeader';
import UsersSearchbar from '../users_searchbar/UsersSearchbar';
import UsersBody from '../users_body/UsersBody';
import Model  from '../../model/Model';
import MessageBox from '../../message_box/MessageBox';
import ContainedButton from '../../inputs/buttons/contained_button/ContainedButton';
import Loading from '../../loading/Loading';

// logic
import {
  resetMessage,
  actionExecuter
} from '../../../pages/users/users/usersLogic';

export default function UsersMain() {
  const { 
    usersPagesCount,
    currentPageNumber,
    setCurrentPageNumber,
    modelMessage, 
    setModelMessage,
    messageBox,
    setMessageBox,
    action,
    loading,
  } = useContext(UsersContext);

  const handlePaginationChange = (event, value) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setCurrentPageNumber(value)
  }

  return (
    <div className='users-main-container'>

      <Model 
        title={modelMessage.title} 
        message={modelMessage.message} 
        status={modelMessage.isVisible} 
        setStatus={setModelMessage} 
      />

      <MessageBox 
        title={messageBox.title} 
        message={messageBox.message} 
        isVisable={messageBox.isVisible} 
        onClose={() => {resetMessage(setMessageBox)}}
      >
        <div className='users-main-confirm-btn-container'>
          <ContainedButton title={'Confirm'} onclickHandler={() => {
            resetMessage(setMessageBox);
            actionExecuter(action);
          }} />
        </div>
      </MessageBox>

      <div className={`loading-container ${loading ? 'active' : ''}`}>
        <Loading isVisible={loading} />
      </div>

      <div className='users-header'>
        <UsersHeader />
      </div>

      <div className='users-body'>
        <UsersSearchbar />
        <UsersBody />
      </div>

      <div className={`users-pagination-container ${usersPagesCount <= 0 ? 'hidden' : ''}`}>
        <Pagination 
          count={usersPagesCount} 
          page={currentPageNumber}
          variant="outlined" 
          shape="rounded" 
          color="primary" 
          showFirstButton 
          showLastButton 
          size="small"
          onChange={handlePaginationChange}
        />
      </div>

    </div>
  );
}