import { fetchMultipleData } from "../../../global/utils/functions/apiService";

async function GetAllAdminsAsync(adminsState, searchValue, searchBy, setLoader) {
  setLoader(true);

  try {
    const url = searchBy === 'fullname' 
      ? `/Admins/GetAdminsByFullName/${searchValue}` 
      : `/Admins/GetAdminByUserNameOrEmail/${searchValue}`;

    const response = await fetchMultipleData(url);

    if (response.success) {
      if (searchBy === 'fullname') {
        adminsState(response.data.Response.data.admins || []);
      } else if (searchBy === 'emailOrUserName') {
        adminsState([response.data.Response.data.admin] || []);
      }
    } else {
      console.error(`Error: ${response.message}`);
    }
  } catch (error) {
    console.error("An unexpected error occurred:", error);
  } finally {
    setLoader(false);
  }
}

export {
  GetAllAdminsAsync
};
