// base
import React, { useState } from 'react';
import './updateCard.css';
import { Link } from 'react-router-dom';

// icons
import { FaRegArrowAltCircleDown } from "react-icons/fa";
import { FaRegArrowAltCircleUp } from "react-icons/fa";
import CopyAllIcon from '@mui/icons-material/CopyAll';

// mui 
import { Tooltip } from '@mui/material';

// logic
import { getDate } from '../../../global/utils/functions/utils';

export default function UpdateCard({ update, _index}) {

  const [tooltipText, setTooltipText] = useState("Copy");

  const [openMode, setOpenMode] = useState(false);

  const dataJson = () => {
    if (!update.updatedData)
      return null;

    try {
      const parsedData = JSON.parse(update.updatedData);
      return JSON.stringify(parsedData, null, 2);
    } catch {
      return update.updatedData;
    }
  }

  const handleClick = () => {
    navigator.clipboard.writeText(update.updatedData)
    .then(() => {
      setTooltipText("Copied");
      setTimeout(() => {
        setTooltipText("Copy");
      }, 2000);
    })
    .catch((err) => {
      console.log("Failed to copy text: ", err);
    });
  };

  return (
    <div className='update-card-component'>

      <div className='update-card-header' onClick={() => {setOpenMode(!openMode)}}>
        <div className='label-index'>
          <p className='label index'>#/{_index}</p>
        </div>

        <div className='label-table-name'>
          <p className='label'>Table Name</p>
          <p className='value'>{update.tableName}</p>
        </div>

        <div className='label-user-name'>
          <p className='label'>By User Name</p>
          <p className='value'>{update.userName}</p>
        </div>

        <div className='icon-container'>
          {openMode ? (
            <FaRegArrowAltCircleUp className='card-icon' fontSize={''} onClick={() => {setOpenMode(!openMode)}} />
          ) : (
            <FaRegArrowAltCircleDown className='card-icon' fontSize={''} onClick={() => {setOpenMode(!openMode)}} />
          )}
        </div>
      </div>

      <div className={`card-body ${openMode ? 'opened' : ''}`}>

        <div className='item'>
          <p className='label'>Updated data</p>

          <pre className='json-container'>
            <p className='item-data-header'>
              {
                update.updatedData ? 'updated data' : 'no data'
              } 

              <Tooltip title={tooltipText}>
                <CopyAllIcon fontSize={'small'} className={`icon ${update.updatedData ? '' : 'not-visible'}`} onClick={handleClick} onMouseLeave={() => {setTooltipText("Copy")}}/>
              </Tooltip>
            </p>

            {dataJson()}
          </pre>
        </div>
        
        <div className='item update-history'>
          <p className='label'>View History</p>

          <Link to={`/transactions-history/${update.targetId}/${update.tableName}`}>
            <p className='value'>history</p>
          </Link>

        </div>

        <div className='item date'>
          <p className='label'>Registrated on</p>
          <p className='value'>{getDate(update.updateDate)}</p>
        </div>

      </div>

    </div>
  )
}
