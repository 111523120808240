import React, { createContext, useState } from 'react';
import { UsersModeEnum, ActionEnum } from './usersLogic';

export const UsersContext = createContext();

export const UsersProvider = ({ children }) => {

  const [users, setUsers] = useState([]);
  const [activeUsersCount, setActiveUsersCount] = useState(0);
  const [deletionsUsersCount, setDeletionsUsersCount] = useState(0);
  const [usersMode, setUsersMode] = useState(UsersModeEnum.ACTIVE_USERS);
  const [usersPagesCount, setUsersPagesCount] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
	const [action, setAction] = useState(ActionEnum.NULL);
  const [loading, setLoading] = useState(false);
  const [modelMessage, setModelMessage] = useState({ title: '', message: '', isVisible: false });
  const [messageBox, setMessageBox] = useState({ title: '', message: '', isVisible: false });
  const [reload, setReload] = useState(false);

  return (
  <UsersContext.Provider
    value={{
      users,
      setUsers,
      activeUsersCount,
      setActiveUsersCount,
      deletionsUsersCount,
      setDeletionsUsersCount,
      usersMode,
      setUsersMode,
      usersPagesCount,
      setUsersPagesCount,
      currentPageNumber,
      setCurrentPageNumber,
      action,
      setAction,
      loading,
      setLoading,
      modelMessage,
      setModelMessage,
      messageBox,
      setMessageBox,
      reload,
      setReload
    }}
  >
      {children}
  </UsersContext.Provider>
  );
};
