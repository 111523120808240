// base
import React, { useContext, useEffect, useRef, useState } from 'react'
import './additionsHeader.css';

// context
import { AdditionsContext } from '../../../pages/additions/additionsContext';

// icons
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { IoMdListBox } from "react-icons/io"; /* all */
import { MdAllInclusive } from "react-icons/md"; /* by table */

// components 
import AnalyticalCard from '../../common_components/analytical_card/AnalyticalCard';

// logic
import {
  AdditionsModeEnum,
  getAllAdditionsCount,
  getAdditionsPagesCountAll,
  getAllAdditions,
  getTables,
  getAdditionsPagesCountByTable,
  getAdditionsByTable
} from '../../../pages/additions/additionsLogic';

export default function AdditionsHeader() {
  const {
    setAdditions, 
    additionsMode,
    additionsCount,
    setAdditionsCount,
    tables, 
    setTables,
    setAdditionsMode,
    currentPageNumber,
    setCurrentPageNumber,
    setAdditionsPagesCount,
    setLoading
  } = useContext(AdditionsContext);

  const containerRef = useRef(null);
  const [activeCardId, setActiveCardId] = useState(0);

  const scrollLeft = () => {
    const containerWidth = containerRef.current.offsetWidth;
    const scrollAmount = window.innerWidth <= 900 
      ? containerWidth * 0.6
      : containerWidth * 0.23;
      containerRef.current.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
  };

  const scrollRight = () => {
    const containerWidth = containerRef.current.offsetWidth;
    const scrollAmount = window.innerWidth <= 900 
      ? containerWidth * 0.6
      : containerWidth * 0.23
    containerRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
  };

  useEffect(() => {
    const fetchData = async () => {
      await getAdditionsWithPagesCountAsync();
    }

    fetchData();
  }, [additionsMode, currentPageNumber, activeCardId])

  async function getAdditionsWithPagesCountAsync() {
    try {
      setLoading(true);
      
      switch (additionsMode) {
        case AdditionsModeEnum.ALL_ADDITIONS:
          setAdditionsCount(await getAllAdditionsCount());
          setAdditionsPagesCount(await getAdditionsPagesCountAll());
          setTables(await getTables());
          setAdditions(await getAllAdditions(currentPageNumber));
          break;

        case AdditionsModeEnum.BY_TABLE:
          setAdditionsPagesCount(await getAdditionsPagesCountByTable(activeCardId));
          setAdditions(await getAdditionsByTable(activeCardId, currentPageNumber));
          break;

        default:
          console.warn("Unknown errors mode:");
          return;
      }
    } catch (error) {
      console.error('Error fetching additions with pages count:', error);
    } finally {
      setLoading(false);
    }
  }

  const onAllAdditionsClickHandler = () => {
    setActiveCardId(0)
    setCurrentPageNumber(1);
    setAdditionsMode(AdditionsModeEnum.ALL_ADDITIONS);
  }

  const onAdditionsByTableClickHandler = (tableId) => {
    setActiveCardId(tableId)
    setCurrentPageNumber(1);
    setAdditionsMode(AdditionsModeEnum.BY_TABLE);
  }

  return (
    <div className="additions-header-component">
      <div className='scroll-container left'>
        <IoIosArrowBack className='icon' fontSize={''} onClick={scrollLeft} />
      </div>

      <div className='items-container' ref={containerRef}>

        <div className="item-container">
          <AnalyticalCard 
            title={'All Additions'} 
            icon={MdAllInclusive} 
            value={additionsCount} 
            isActive={additionsMode === AdditionsModeEnum.ALL_ADDITIONS} 
            onClick={onAllAdditionsClickHandler} />
        </div>

        {tables
          .filter((table) => table.tableName !== "Errors" && table.tableName !== "System Tables") // فلترة العناصر
          .sort((a, b) => a.tableName.localeCompare(b.tableName)) // ترتيب حسب الأحرف تصاعدياً
          .map((table) => (
            <div key={table.tableId} className="item-container">
              <AnalyticalCard
                title={table.tableName}
                icon={IoMdListBox}
                value={'~'}
                isActive={activeCardId === table.tableId}
                onClick={() => onAdditionsByTableClickHandler(table.tableId)}
              />
            </div>
          ))
        }
        
      </div>

      <div className='scroll-container right'>
        <IoIosArrowForward className='icon' fontSize={''} onClick={scrollRight} />
      </div>
    </div>
  )
}
