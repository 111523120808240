// base
import React, { useState } from 'react'
import './newAdmin.css'
import { useSelector } from 'react-redux';

// logic 
import {
  handleInputChange,
  validateEmail,
  validateUserName,
  validatePassword,
  validateFullName, 
  validateVerificationCode,
  validateAdmin,
  resetAdmin,
  isEmailExistAsync,
  isUserNameExistAsync,
  sendEmailAsync,
  NewAdminAsync
} from './newAdminLogic';

// components
import MessageLabel from '../../message_label/MessageLabel';
import ContainedButton from '../../inputs/buttons/contained_button/ContainedButton';
import TextButton from '../../inputs/buttons/text_button/TextButton';
import TextInput from '../../inputs/text_input/TextInput';
import PasswordInput from '../../inputs/password_input/PasswordInput';

export default function NewAdmin({ newAdminMode, setNewAdminMode }) {

  const currentAdminId = useSelector((state) => state.currentUser.adminId);
  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);

  const [sentVerificationCode, setVerificationCode] = useState('');
  const [newAdmin, setNewAdmin] = useState
  (
    {
      fullname: '', username: '', email: '', verificationCode: '', password: '', confirmPassword: ''
    }
  );

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    message: 'Please fill in the required fields to add a new admin user. All fields marked with * are mandatory.',
    type: 'info',
    isVisible: true
  });

  const [stage, setStage] = useState('s1');

  const handleAddClick = async () => {
    setLoading(true);
  
    try {
      if (stage === 's1') {
        if (!validateAdmin(newAdmin)) {
          setMessage({
            message: 'The admin data provided is invalid. Please check all required fields.',
            type: 'error',
            isVisible: true
          });
          return;
        } 
  
        if (newAdmin.password !== newAdmin.confirmPassword) {
          setMessage({
            message: 'The passwords do not match. Please ensure both password fields are identical.',
            type: 'error',
            isVisible: true
          });
          return;
        }      

        const isUsernameExist = await isUserNameExistAsync(newAdmin.username);

        if (isUsernameExist) {
          setMessage({
            message: 'The username is already in use. Please provide a different username.',
            type: 'error',
            isVisible: true
          });
          return;
        }

        const isEmailExist = await isEmailExistAsync(newAdmin.email);
  
        if (isEmailExist) {
          setMessage({
            message: 'The email address is already in use. Please provide a different email.',
            type: 'error',
            isVisible: true
          });
          return;
        }

        setVerificationCode(await sendEmailAsync(newAdmin.email, newAdmin.username));

        if (sentVerificationCode === null || sentVerificationCode === undefined) {
          setMessage({
            message: 'Failed to send the email. Please try again later.',
            type: 'error',
            isVisible: true
          });
          return;
        }
        else {
          setMessage({
            message: 'Email sent successfully! Please check your inbox for the verification code.',
            type: 'info',
            isVisible: true
          });
          setStage('s2');
        }                
      } else if (stage === 's2') {
        if (!validateVerificationCode(newAdmin.verificationCode)) {
          return;
        }
        alert(sentVerificationCode)
        if (newAdmin.verificationCode !== sentVerificationCode) {
          setMessage({
            message: 'The verification code you entered is incorrect. Please check your email and try again.',
            type: 'error',
            isVisible: true
          });
          return;
        }

        const response = await NewAdminAsync(newAdmin, currentAdminId, currentAdminPermissions);

        if (response.success) {
          setMessage({
            message: 'The admin user has been created successfully with no permissions assigned. Please assign the necessary permissions to enable access to features.',
            type: 'success',
            isVisible: true
          });
          resetAdmin(setNewAdmin, setVerificationCode, setStage);
        } else {
          setMessage({
            message: response.message,
            type: 'error',
            isVisible: true
          });
        }
      }
    } catch (error) {
      setMessage({
        message: 'An error occurred while processing your request. Please try again later.',
        type: 'error',
        isVisible: true
      });
    } finally {
      setLoading(false);
    }
  }
  

  const handleCancelClick = () => {
    if (stage === 's1')
      setNewAdminMode(false);
    else
      setStage('s1');
  }

  return (
    <div className={`new-admin-component ${newAdminMode ? 'opened' : ''}`}>
      <div className='new-admin-content'>
        
        <div className='new-admin-header'>
          <h3>Quick Add</h3>
          <div className={`save-loader ${loading ? 'active' : ''}`}></div>
        </div>

        <div className='new-admin-message'>
          <MessageLabel message={message.message} type={message.type} isVisible={message.isVisible} />
        </div>

        <div className={`new-admin-body ${loading ? 'loading' : ''}`}>
          
          <div className={`new-admin-input-container fullname ${stage === 's1' ? '' : 'disable'}`}>
            <TextInput 
              title={'FullName'}
              value={newAdmin.fullname}
              onChangeHandler={(value) => {handleInputChange('fullname', value, setNewAdmin, stage)}}
              validateInput={validateFullName}
              tooltipTitle={''}
              mandatory={true}
              onEnter={handleAddClick}
            />
          </div>

          <div className={`new-admin-input-container username ${stage === 's1' ? '' : 'disable'}`}>
            <TextInput 
              title={'UserName'}
              value={newAdmin.username}
              onChangeHandler={(value) => {handleInputChange('username', value, setNewAdmin, stage)}}
              validateInput={validateUserName}
              tooltipTitle={''}
              mandatory={true}
              onEnter={handleAddClick}
            />
          </div>

          <div className={`new-admin-input-container email`}>
            
            <div className={`new-admin-email-input ${stage === 's1' ? '' : 'disable'}`}>
              <TextInput 
                title={'Email Address'}
                value={newAdmin.email}
                onChangeHandler={(value) => {handleInputChange('email', value, setNewAdmin, stage)}}
                validateInput={validateEmail}
                tooltipTitle={''}
                mandatory={true}
                onEnter={handleAddClick}
              />
            </div>

            <div className={`new-admin-verification-code-input ${stage === 's1' ? 'disable' : ''}`}>
              <TextInput 
                title={'Verification Code'}
                value={newAdmin.verificationCode}
                onChangeHandler={(value) => {handleInputChange('verificationCode', value, setNewAdmin, stage)}}
                validateInput={validateVerificationCode}
                tooltipTitle={''}
                mandatory={true}
                onEnter={handleAddClick}
              />
            </div>

          </div>

          <div className='new-admin-input-container password'>

            <div className={`new-admin-password-input ${stage === 's1' ? '' : 'disable'}`}>
              <PasswordInput
                title={'Password'}
                value={newAdmin.password}
                onChangeHandler={(value) => {handleInputChange('password', value, setNewAdmin, stage)}}
                validateInput={validatePassword}
                tooltipTitle={''}
                onEnter={handleAddClick}
              />
            </div>

            <div className={`new-admin-confirm-password-input ${stage === 's1' ? '' : 'disable'}`}>
              <PasswordInput
                title={'Confirm Password'}
                value={newAdmin.confirmPassword}
                onChangeHandler={(value) => {handleInputChange('confirmPassword', value, setNewAdmin, stage)}}
                validateInput={validatePassword}
                tooltipTitle={''}
                onEnter={handleAddClick}
              />
            </div>

          </div>

        </div>

        <div className={`new-admin-footer ${loading ? 'loading' : ''}`}>
          
          <div className='new-admin-add-button-container'>
            <ContainedButton title={stage === 's1' ? 'Verify Email' : 'Add'} onclickHandler={handleAddClick} />
          </div>

          <div className='new-admin-cancel-button-container'>
            <TextButton title={stage === 's1' ? 'Cancel' : 'Previous'} onclickHandler={handleCancelClick} />
          </div>

        </div>
        
      </div>
    </div>
  )
}
