import axios from 'axios';
import config from '../../../global/config';
import { fetchScalarData, patchData, patchDataWithHeader } from '../../../global/utils/functions/apiService';

import {
  checkLength,
  isInt,
  isUsernameValid,
  isEmailValid,
  isPasswordValid
} from '../../../global/utils/validation'

function setState(field, newValue, setState) {
  setState(prevState => ({
    ...prevState,
    [field]: {
      ...prevState[field],
      ...newValue
    }
  }));
}

const validateFullname = (value) => {
  return checkLength(1, 25, value);
}

const validateUsername = (value) => {
  return isUsernameValid(value);
}

const validateEmail = (value) => {
  return isEmailValid(value);
}

const validateINT = (value) => {
  return isInt(value);
}

const validatePassword = (value) => {
  return isPasswordValid(value);
}

function saveNewPasswordInSessionStorage(newPassword) {
  let adminData = sessionStorage.getItem('currentAdmin');

  if (adminData && newPassword) {
    adminData = JSON.parse(adminData);
    adminData.password = newPassword;
    sessionStorage.setItem('currentAdmin', JSON.stringify(adminData));
  }
}

async function setFullNameAsync(adminId, fullname, reload, reloadState, messageState) {
  try {

    const url = `/Admins/SetAdminFullName/${adminId}/${fullname}`;
    const response = await patchData(url);

    if (response.success && response.data.Response.data.response) {
      messageState({
        title: 'Success',
        message: 'The fullname has been updated successfully.',
        status: true
      });
    } else {
      messageState({
        title: 'Error',
        message: response.message || 'Failed to update the fullname. Please check the input information and try again.',
        status: true
      });
    }
  } catch (error) {
    messageState({
      title: 'Error',
      message: error.message || 'An unexpected error occurred. Please try again later.',
      status: true
    });
  } finally {
    reloadState(!reload);
  }
}

async function isUserNameExistAsync(username, messageState) {
  try
  {
    const url = `${config.apiBaseUrl}/Admins/IsAdminUserNameExist/${username}`;
    const response = await axios.get(url);

    return response.data.data.isExist || false;
  }
  catch (error) 
  {
    if (error.response) 
      {
      switch (error.response.status) 
      {
        case 404:
          messageState({
            title: 'Error',
            message: 'The username you entered is incorrect. Please try again.',
            status: true
          })
          break;
        case 500:
          messageState({
            title: 'Error',
            message: 'There is a problem with the server. Please try again later',
            status: true
          })
          break;
        default:
          messageState({
            title: 'Error',
            message: 'An unexpected error occurred: ' + error.response.status,
            status: true
          })
          break;
      }
    } 
    else 
    {
      messageState({
        title: 'Network Error',
        message: 'Please check your internet connection and try again.',
        status: true
      })
    }
  }
}

async function setUserNameAsync(adminId, username, reload, reloadState, messageState) {
  try {

    if (await isUserNameExistAsync(username, messageState)) {
      messageState({
        title: 'Username Availability',
        message: 'The username already exists. Please choose a different one.',
        status: true
      });
      return;
    }

    const url = `/Admins/SetAdminUserName/${adminId}/${username}`;
    const response = await patchData(url);

    if (response.success && response.data.Response.data.response) {
      messageState({
        title: 'Success',
        message: 'The username has been updated successfully.',
        status: true
      });
      return true;
    } else {
      messageState({
        title: 'Error',
        message: response.message || 'Failed to update the username. Please check the input information and try again.',
        status: true
      });
      return false;
    }
  } catch (error) {
    messageState({
      title: 'Error',
      message: error.message || 'An unexpected error occurred. Please try again later.',
      status: true
    });
    return false;
  } finally {
    reloadState(!reload);
  }
}

async function isEmailExistAsync(email, messageState) {
  try
  {
    const url = `${config.apiBaseUrl}/Admins/IsAdminEmailExist/${email}`;
    const response = await axios.get(url);

    return response.data.data.isExist || false;
  }
  catch (error) 
  {
    if (error.response) 
      {
      switch (error.response.status) 
      {
        case 404:
          messageState({
            title: 'Error',
            message: 'The email you entered is incorrect. Please try again.',
            status: true
          })
          break;
        case 500:
          messageState({
            title: 'Error',
            message: 'There is a problem with the server. Please try again later',
            status: true
          })
          break;
        default:
          messageState({
            title: 'Error',
            message: 'An unexpected error occurred: ' + error.response.status,
            status: true
          })
          break;
      }
    } 
    else 
    {
      messageState({
        title: 'Network Error',
        message: 'Please check your internet connection and try again.',
        status: true
      })
    }
  }
}

async function setEmailAsync(adminId, email, reload, reloadState, messageState) {
  try {

    const url = `/Admins/SetAdminEmail/${adminId}/${email}`;
    const response = await patchData(url);

    if (response.success && response.data.Response.data.response) {
      messageState({
        title: 'Success',
        message: 'Email has been verified successfully.',
        status: true
      });
      return true;
    } else {
      messageState({
        title: 'Error',
        message: response.message || 'Failed to verify the email. Please check the input information and try again.',
        status: true
      });
      return false;
    }
  } catch (error) {
    messageState({
      title: 'Error',
      message: error.message || 'An unexpected error occurred. Please try again later.',
      status: true
    });
    return false;
  } finally {
    reloadState(!reload);
  }
}

async function sendEmailAsync(email, username, messageState) {
  try
  {
    const url = `${config.apiBaseUrl}/Notifications/SendRegistrationConfirmationEmail/${email}/${username}`;
    const response = await axios.get(url);
    
    if (response.data.data.virificationCode === undefined || response.data.data.virificationCode === null) {
      messageState({
        title: 'Error',
        message: `The message was not sent to ${username}.`,
        status: true
      });
      return null;
    }

    return response.data.data.virificationCode
  }
  catch (error) 
  {
    if (error.response) 
      {
      switch (error.response.status) 
      {
        case 500:
          messageState({
            title: 'Error',
            message: 'There is a problem with the server. Please try again later.',
            status: true
          });
          break;
        default:
          messageState({
            title: 'Error',
            message: 'An unexpected error occurred: ' + error.response.status,
            status: true
          });
          break;
      }
    } 
    else 
    {
      messageState({
        title: 'Error',
        message: 'Network error. Please check your internet connection and try again.',
        status: true
      });
    }
  }
}

async function HashSHAAsync(plainText) {
  const url = `/Encryption/HashSHA/${plainText}`;

  try {
    const response = await fetchScalarData(url);

    if (response.data.Response.data.response === true) {
      return response.data.Response.data.hash;
    } else {
      return null;
    }
  } catch (error) {
    console.error('An error occurred while fetching the hash:', error);
    return null;
  }
}

async function SetPasswordAsync(adminId, password, reload, reloadState, messageState) {
  try {

    const url = `/Admins/SetAdminPassword/${adminId}/${password}`;
    const response = await patchData(url);

    if (response.success && response.data.Response.data.response) {
      messageState({
        title: 'Password Updated',
        message: 'Your password has been updated successfully.',
        status: true
      });
      return true;
    } else {
      messageState({
        title: 'Error',
        message: response.message || 'Failed to update your password. Please check the input information and try again.',
        status: true
      });
      return false;
    }
  } catch (error) {
    messageState({
      title: 'Error',
      message: error.message || 'An unexpected error occurred. Please try again later.',
      status: true
    });
    return false;
  } finally {
    reloadState(!reload);
  }
}

async function setAdminProfileImageAsync(adminId, imageURL, imageName) {
  try {
    const url = `/Admins/SetAdminImage`;
    const headers = {
      'adminId': adminId,
      'imageURL': imageURL,
      'imageName': imageName,
    };

    const response = await patchDataWithHeader(url, { headers });

    if (response.data.Response.data.response) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
}

async function deleteImageAsync(adminId) {
  try {
    const url = `/Admins/DeleteAdminImage/${adminId}`;
    const response = await patchData(url);
    const isDeleted = response.data.Response?.data?.response;
    return !!isDeleted;
  } catch {
    return false;
  }
}


export {
  setState,
  validateFullname,
  validateUsername,
  validateEmail,
  validateINT,
  validatePassword,
  saveNewPasswordInSessionStorage,
  setFullNameAsync,
  setUserNameAsync,
  sendEmailAsync,
  setEmailAsync,
  isEmailExistAsync,
  HashSHAAsync,
  SetPasswordAsync,
  setAdminProfileImageAsync,
  deleteImageAsync
}