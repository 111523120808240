import './header.css';
import React from 'react';
import Logo from '../logo/Logo';
import UserAvater from '../user_avater/user_avater/UserAvater';

export default function Header({ resetActiveItem }) {
  return (
    <header id='header-container' className='header-container'>

      <Logo resetActiveItem={resetActiveItem} />
      <UserAvater />

    </header>
  )
}
