import { 
  fetchMultipleData,
  patchData
} from "../../../../global/utils/functions/apiService";

async function GetPermissionsAsync(adminId) {
  
  try {
    const url = `/SystemPermissions/GetSystemPermissions/${adminId}`;
    const response = await fetchMultipleData(url);

    if (response.success) {
      return response.data.Response.data.permissions;
    } else {
      console.error(response.message);
      return [];
    }
  } catch (error) {
    console.error("Error fetching permissions:", error);
    return [];
  } finally {
  }
}

async function setPermissionsAsync(
  adminId, permissions, currentAdminId, currentAdminPermissions, 
  reload, setReloadState, setLoaderState, setMessageState, navigate
) {
  try {

    if (!currentAdminPermissions) {
      navigate('/login');
      return;
    } 

    if ((currentAdminPermissions & 2) !== 2) {
      setMessageState({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        status: true,
      });
      return;
    }

    const url = `/Admins/SetPermissions/${adminId}/${permissions}/${currentAdminId}`;
    setLoaderState(true);
    const response = await patchData(url);

    // معالجة الاستجابة
    if (response?.success && response?.data?.Response?.data?.response) {
      setMessageState({
        title: 'Success',
        message: 'The admin permissions updated successfully.',
        status: true
      });
    } else {
      setMessageState({
        title: 'Error',
        message: response?.message || 'Failed to update the permissions. Please check your permissions and try again.',
        status: true
      });
    }
  } catch (error) {
    setMessageState({
      title: 'Error',
      message: error.message || 'An unexpected error occurred. Please try again later.',
      status: true
    });
  } finally {
    setLoaderState(false);
    setReloadState(!reload);
  }
}

export {
  GetPermissionsAsync,
  setPermissionsAsync
}