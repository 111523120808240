import { fetchMultipleData, patchData, deleteData } from "../../../global/utils/functions/apiService";

async function GetAdminByIdAsync(adminId) {
  const url = `/Admins/GetAdminById/${adminId}`;

  const response = await fetchMultipleData(url);
  if (response.success) {
    return(response.data.Response.data.admin);
  } else {
    console.error(response.message);
    return null;
  }
}

async function clearPermissionsAsync(
    adminId, adminPermissions, currentAdminId, currentAdminPermissions, messageState,
    loaderState, reload, setReload, navigate
  ) {
  try {

    if (!currentAdminPermissions) {
      navigate('/login');
    }
    else if ((currentAdminPermissions & 2) !== 2 || (adminPermissions && adminPermissions === -2)) {
      messageState({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        status: true,
      });
      return;
    }

    loaderState(true);
    const response = await patchData(`/Admins/ClearPermissions/${adminId}/${currentAdminId}`);

    if (response.success && response.data.Response.data.response) {
      messageState({
        title: 'Success',
        message: 'Permissions have been cleared successfully.',
        status: true
      });
    } else {
      messageState({
        title: 'Error',
        message: response.message || 'Failed to clear permissions. Please check your permissions and try again.',
        status: true
      });
    }
  } catch (error) {
    messageState({
      title: 'Error',
      message: error.message || 'An unexpected error occurred. Please try again later.',
      status: true
    });
  } finally {
    loaderState(false);
    setReload(!reload);
  }
}

async function activateAdminAsync(
  adminId, adminPermissions, currentAdminId, currentAdminPermissions, messageState,
  loaderState, reload, setReload, navigate
  ) {
  try {

    if (!currentAdminPermissions) {
      navigate('/login');
    }
    else if ((currentAdminPermissions & 4) !== 4 || (adminPermissions && adminPermissions === -2)) {
      messageState({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        status: true,
      });
      return;
    }

    loaderState(true);
    const response = await patchData(`/Admins/SetAsActive/${adminId}/${currentAdminId}`);

    if (response.success && response.data.Response.data.response) {
      messageState({
        title: 'Success',
        message: 'Admin has been activated successfully.',
        status: true
      });
    } else {
      messageState({
        title: 'Error',
        message: response.message || 'Failed to activate admin. Please check your permissions and try again.',
        status: true
      });
    }
  } catch (error) {
    messageState({
      title: 'Error',
      message: error.message || 'An unexpected error occurred while activating the admin. Please try again later.',
      status: true
    });
  } finally {
    loaderState(false);
    setReload(!reload);
  }
}

async function deactivateAdminAsync(
  adminId, adminPermissions, currentAdminId, currentAdminPermissions, messageState,
  loaderState, reload, setReload, navigate
  ) {
  try {

    if (!currentAdminPermissions) {
      navigate('/login');
    }
    else if ((currentAdminPermissions & 4) !== 4 || (adminPermissions && adminPermissions === -2)) {
      messageState({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        status: true,
      });
      return;
    }

    loaderState(true);
    const response = await patchData(`/Admins/SetAsInActive/${adminId}/${currentAdminId}`);

    if (response.success && response.data.Response.data.response) {
      messageState({
        title: 'Success',
        message: 'Admin has been deactivated successfully.',
        status: true
      });
    } else {
      messageState({
        title: 'Error',
        message: response.message || 'Failed to deactivate admin. Please check your permissions and try again.',
        status: true
      });
    }
  } catch (error) {
    messageState({
      title: 'Error',
      message: error.message || 'An unexpected error occurred while deactivating the admin. Please try again later.',
      status: true
    });
  } finally {
    loaderState(false);
    setReload(!reload);
  }
}

async function deleteAdminAsync(
  adminId, adminPermissions, currentAdminId, currentAdminPermissions, messageState,
  loaderState, reload, setReload, navigate
  ) {
  try {

    if (!currentAdminPermissions) {
      navigate('/login');
    }
    else if ((currentAdminPermissions & 8) !== 8 || (adminPermissions && adminPermissions === -2)) {
      messageState({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        status: true,
      });
      return;
    }

    loaderState(true);
    const response = await deleteData(`/Admins/DeleteAdmin/${adminId}/${currentAdminId}`);
    
    if (response.success && response.data.Response.data.response) {
      messageState({
        title: 'Success',
        message: 'Admin has been deleted successfully.',
        status: true
      });
    } else {
      messageState({
        title: 'Error',
        message: response.message || 'Failed to delete admin. Please check your permissions and try again.',
        status: true
      });
    }
  } catch (error) {
    messageState({
      title: 'Error',
      message: error.message || 'An unexpected error occurred while deleting the admin. Please try again later.',
      status: true
    });
  } finally {
    loaderState(false);
    setReload(!reload);
  }
}

export {
  GetAdminByIdAsync,
  clearPermissionsAsync,
  activateAdminAsync,
  deactivateAdminAsync,
  deleteAdminAsync
}