import React from 'react';
import './permissionsDenied.css';
import { useNavigate } from 'react-router-dom';
import ContainedButton from '../../components/inputs/buttons/contained_button/ContainedButton';

export default function PermissionsDenied() {
  const navigate = useNavigate();

  const onclickHandler = () => {
    navigate('/login');
  };

  return (
    <div className='permissions-denied-container'>
      <h2 className='permissions-denied-primary-title'>403</h2>
      <h3 className='permissions-denied-secondary-title'>Permission Denied‼️</h3>

      <p className='permissions-denied-description'>
        You do not have permission to access this page. Please contact your administrator if you believe this is an error.
      </p>

      <div className='permissions-denied-button-container'>
        <ContainedButton title={'Go To Login'} onclickHandler={onclickHandler} />
      </div>
    </div>
  );
}
