import React, { createContext, useState } from 'react';
import { DeletionsModeEnum } from './deletionsLogic';

export const DeletionsContext = createContext();

export const DeletionsProvider = ({ children }) => {

  const [deletions, setDeletions] = useState([]);
  const [deletionsCount, setDeletionsCount] = useState(0);
  const [deletionsPagesCount, setDeletionsPagesCount] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [deletionsMode, setDeletionsMode] = useState(DeletionsModeEnum.ALL_DELETIONS);
  const [tableId, setTableId] = useState(null);
  const [tables, setTables] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modelMessage, setModelMessage] = useState({ title: '', message: '', isVisible: false });
  const [messageBox, setMessageBox] = useState({ title: '', message: '', isVisible: false });
  const [reload, setReload] = useState(false);

  return (
  <DeletionsContext.Provider
    value={{
      deletions,
      setDeletions,
      deletionsCount, 
      setDeletionsCount,
      deletionsPagesCount,
      setDeletionsPagesCount,
      currentPageNumber,
      setCurrentPageNumber,
      deletionsMode,
      setDeletionsMode,
      tableId, 
      setTableId,
      tables,
      setTables,
      loading,
      setLoading,
      modelMessage,
      setModelMessage,
      messageBox,
      setMessageBox,
      reload,
      setReload
    }}
  >
      {children}
  </DeletionsContext.Provider>
  );
};
