import {
  fetchScalarData, 
  fetchMultipleData,
  patchData
} from '../../../global/utils/functions/apiService';

const ErrorsModeEnum = Object.freeze({
  ALL_ERRORS: 'all-errors',
  HANDLED_ERRORS: 'handled-errors',
  NOT_HANDLED_ERRORS: 'not-handled-errors'
});

function resetMessage(state) {
  state({
    title: '',
    message: '',
    isVisible: false
  });
}

async function getErrorsPagesCount_All() {
  const result = await fetchScalarData('/Errors/GetErrorsPagesCount_All');
  if (result.success) {
    return result.data.Response.data.count;
  } else {
    console.log('Error fetching all errors pages count:', result.message);
    return 0;
  }
}

async function getErrorsPagesCount_Handled() {
  const result = await fetchScalarData('/Errors/GetErrorsPagesCount_Handled');
  if (result.success) {
    return result.data.Response.data.count;
  } else {
    console.log('Error fetching handled errors pages count:', result.message);
    return 0;
  }
}

async function getErrorsPagesCount_NotHandled() {
  const result = await fetchScalarData('/Errors/GetErrorsPagesCount_NotHandled');
  if (result.success) {
    return result.data.Response.data.count;
  } else {
    console.log('Error fetching not handled errors pages count:', result.message);
    return 0;
  }
}

async function getAllErrors(pageNumber) {
  const url = '/Errors/GetAllErrors';

  const response = await fetchMultipleData(url, pageNumber);
  
  if (response.success) {
    const errors = response?.data?.Response?.data?.errors || [];

    return errors;
  } else {
    console.log(response.message);
    return [];
  }
}

async function getHandledErrors(pageNumber) {
  const url = '/Errors/GetHandledErrors';

  const response = await fetchMultipleData(url, pageNumber);
  
  if (response.success) {
    const errors = response?.data?.Response?.data?.errors || [];

    return errors;
  } else {
    console.log(response.message);
    return [];
  }
}

async function getNotHandledErrors(pageNumber) {
  const url = '/Errors/GetNotHandledErrors';

  const response = await fetchMultipleData(url, pageNumber);
  
  if (response.success) {
    const errors = response?.data?.Response?.data?.errors || [];

    return errors;
  } else {
    console.log(response.message);
    return [];
  }
}

async function getErrorByKey(key, setLoadingState) {
  if (!key) {
    return [];
  }

  try {
    if (setLoadingState) {
      setLoadingState(true);
    }

    const url = `/Errors/GetErrorByKey/${key}`; 
    const response = await fetchMultipleData(url);

    if (response.success) {
      return [response.data.Response.data.error] || [];
    } else {
      console.log(`Error: ${response.message}`);
      return []
    }
  } catch (error) {
    console.log("An unexpected error occurred:", error);
    return []
  } finally {
    if (setLoadingState) {
      setLoadingState(false);
    }
  }
}

async function getErrorById(id, setLoadingState) {
  if (!id) {
    return null;
  }

  try {
    if (setLoadingState) {
      setLoadingState(true);
    }

    const url = `/Errors/GetErrorById/${id}`; 
    const response = await fetchMultipleData(url);

    if (response.success) {
      return response.data.Response.data.error || null;
    } else {
      console.log(`Error: ${response.message}`);
      return null
    }
  } catch (error) {
    console.log("An unexpected error occurred:", error);
    return null
  } finally {
    if (setLoadingState) {
      setLoadingState(false);
    }
  }
}

function handleDescription(description) {
  if (!description)
    return '';
  else if (description === 'null')
    return '';
  else
    return description;
}

async function setDescriptionAsync(errorId, description, currentAdminId, currentAdminPermissions, loaderState, messageState) {
  try {
    
    if (!currentAdminPermissions) {
      return;
    }
    else if ((currentAdminPermissions & 128) !== 128) {
      messageState({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        isVisible: true,
      });
      return;
    }
    
    const url = `/Errors/SetDescription/${errorId}/${description || 'null'}/${currentAdminId}`;
    loaderState(true);
    const response = await patchData(url);

    if (response.message === 'Success.') {
      messageState({
        title: 'Success',
        message: 'The description has been added successfully.',
        isVisible: true
      });
      return true;
    } else {
      messageState({
        title: 'Error',
        message: response.message || 'Failed to add the description. Please check the input information and try again.',
        isVisible: true
      });
      return false;
    }
  } catch (error) {
    messageState({
      title: 'Error',
      message: error.message || 'An unexpected error occurred. Please try again later.',
      isVisible: true
    });
    return false;
  } finally {
    loaderState(false);
  }
}

async function setErrorAsHandled(errorId, currentAdminId, currentAdminPermissions, messageState, loaderState) {
  try {

    if (!currentAdminPermissions) {
      return false;
    }
    else if ((currentAdminPermissions & 128) !== 128) {
      messageState({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        isVisible: true,
      });
      return false;
    }

    loaderState(true);
    const response = await patchData(`/Errors/SetAsHandled/${errorId}/${currentAdminId}`);

    if (response.message === 'Success.') {
      messageState({
        title: 'Success',
        message: 'The error status has been updated to handled successfully.',
        isVisible: true
      });
      return true;
    } else {
      messageState({
        title: 'Error',
        message: response.message || 'Failed to update the error status. Please try again.',
        isVisible: true
      });
      return false;
    }
  } catch (error) {
    messageState({
      title: 'Error',
      message: error.message || 'An unexpected error occurred while marking the error as handled. Please try again later.',
      status: true
    });
    return false;
  } finally {
    loaderState(false);
  }
}

export {
  ErrorsModeEnum,
  resetMessage,
  getErrorsPagesCount_All,
  getErrorsPagesCount_Handled,
  getErrorsPagesCount_NotHandled,
  getAllErrors,
  getHandledErrors,
  getNotHandledErrors,
  getErrorByKey,
  getErrorById,
  handleDescription,
  setDescriptionAsync,
  setErrorAsHandled
}
