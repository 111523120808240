import React from 'react'
import './dashboard.css'
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../../components/header_components/header/Header';
import SideBar from '../../components/sidebar_components/sidebar/SideBar';

export default function Dashboard() {

  const [activeSidebarItem, setActiveSidebarItem] = useState(null);

  const resetActiveItem = () => {
    setActiveSidebarItem(null);
  };

  return (
    <div className='dashboard-container'>
      <Header className='header' resetActiveItem={resetActiveItem} />
      <SideBar className='sidebar' activeItem={activeSidebarItem} setActiveItem={setActiveSidebarItem} />
      
      <div className='body'>
        
        <Outlet />
        
      </div>

    </div>
  )
}
