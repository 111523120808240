function isEmailValid(email) {
  if (!email || email.length < 5 || email.length > 150) {
      return false;
  }
  const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return pattern.test(email);
}

function isUsernameValid(username) {
  if (!username) {
      return false;
  }
  const pattern = /^[a-zA-Z][a-zA-Z0-9_]{3,24}$/;
  return pattern.test(username);
  /*
   * يقبل الحروف الإنجليزية والأرقام والشارحة السفلية وبطول 4 - 25.
   * يجب أن يبدأ اسم المستخدم بحرف.
   */
}

function isPasswordValid(password) {
  if (!password) {
      return false;
  }
  return password.length >= 8 && password.length <= 25;
  // يقبل أي شيء بطول 8 - 25.
}

function checkLength(minLength, maxLength, text) {
  if (!text) {
      return false;
  }
  return text.length >= minLength && text.length <= maxLength;
}

function isText(text) {
  if (!text) {
      return false;
  }

  const pattern = /^[^\d!@#$%^&*()_+{}|:;<>,.?~\-=[\]\\]*$/;
  return pattern.test(text);
  // تقبل فقط النص بأي لغة.
}

function isInt(number) {
  const pattern = /^\d+$/;
  return pattern.test(number);
  // تقبل فقط الأرقام الصحيحة.
}

function isFloat(number) {
  const pattern = /^\d+(\.\d+)?$/;
  return pattern.test(number);
  // تقبل الأرقام الصحيحة والعشرية.
}

export { isEmailValid, isUsernameValid, isPasswordValid, checkLength, isText, isInt, isFloat };