import { initializeApp } from "firebase/app";
import { getStorage } from 'firebase/storage';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAms_0KgFffUJd-9LT0Vuuf_tsNVAyLj5k",
  authDomain: "basestorage-951e3.firebaseapp.com",
  projectId: "basestorage-951e3",
  storageBucket: "basestorage-951e3.appspot.com",
  messagingSenderId: "237530870744",
  appId: "1:237530870744:web:bd37fde47e441f44cc78ad",
  measurementId: "G-LRJVK9N772"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const storage = getStorage(app);

export { storage };
