import { patchData } from "../../../../global/utils/functions/apiService";

function handleDescription(description) {
  if (!description)
    return '';
  else if (description === 'null')
    return '';
  else
    return description;
}

async function setDescriptionAsync(adminId, description, currentAdminId, currentAdminPermissions, reload, reloadState, loaderState, messageState, navigate) {
  try {
    
    if (!currentAdminPermissions) {
      navigate('/login');
    }
    else if ((currentAdminPermissions & 16) !== 16) {
      messageState({
        title: 'Access Denied',
        message: 'You do not have sufficient permissions to perform this action.',
        status: true,
      });
      return;
    }
    
    const url = `/Admins/SetAdminDescription/${adminId}/${description || 'null'}/${currentAdminId}`;
    loaderState(true);
    const response = await patchData(url);

    if (response.success && response.data.Response.data.response) {
      messageState({
        title: 'Success',
        message: 'The description has been added successfully.',
        status: true
      });
    } else {
      messageState({
        title: 'Error',
        message: response.message || 'Failed to add the description. Please check the input information and try again.',
        status: true
      });
    }
  } catch (error) {
    messageState({
      title: 'Error',
      message: error.message || 'An unexpected error occurred. Please try again later.',
      status: true
    });
  } finally {
    loaderState(false);
    reloadState(!reload);
  }
}
export {
  handleDescription,
  setDescriptionAsync
}