function getDate(dateStr) {
  const date = new Date(dateStr);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

function copyToClipboard(value, tooltipState) {
  navigator.clipboard.writeText(value)
  .then(() => {
    if (tooltipState) {
      tooltipState('Copied!');
      setTimeout(() => {
        tooltipState('Click to copy');
      }, 2000);
    }
    return true
  })
  .catch (() => {
    if (tooltipState) {
      tooltipState('Failed to copy');
      setTimeout(() => {
        tooltipState('Click to copy');
      }, 2000);
    }
    return false
  })
}

export {
  getDate,
  copyToClipboard
}