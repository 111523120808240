import React from 'react'
import './cardDropdown.css';

export default function CardDropDown({ status, children }) {
  return (
    <ul className={`card-dropdown-component ${status ? 'card-dropdown-active' : 'card-dropdown-in-active'}`}>
      {children}
    </ul>
  )
}
