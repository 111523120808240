// base
import React, { useState, useContext } from 'react'
import './errorsSearchbar.css';

import { ErrorsContext } from '../../../pages/errors/errors/errorsContext';

// components
import SearchInput from '../../../components/inputs/search_input/SearchInput';

// logic
import {
  ErrorsModeEnum,
  getErrorsPagesCount_All,
  getErrorsPagesCount_Handled,
  getErrorsPagesCount_NotHandled,
  getAllErrors,
  getHandledErrors,
  getNotHandledErrors,
  getErrorByKey
} from '../../../pages/errors/errors/errorsLogic';

export default function ErrorsSearchbar() {

  const {
    errorsMode,
    setErrors,
    setErrorsPagesCount,
    setCurrentPageNumber,
    setLoading
  } = useContext(ErrorsContext);

  const [searchValue, setSearchValue] = useState('');

  const handleOnSearchValueChange = (newValue) => {
    setSearchValue(newValue);
    handleReset(newValue);
  }

  const handleReset = async (newValue) => {
    if (newValue.length === 0) {
      setCurrentPageNumber(1);
      if (errorsMode === ErrorsModeEnum.ALL_ERRORS) {
        setErrorsPagesCount(await getErrorsPagesCount_All());
        setErrors(await getAllErrors(1));
      } else if (errorsMode === ErrorsModeEnum.HANDLED_ERRORS) {
        setErrorsPagesCount(await getErrorsPagesCount_Handled());
        setErrors(await getHandledErrors(1));
      } else if (errorsMode === ErrorsModeEnum.NOT_HANDLED_ERRORS) {
        setErrorsPagesCount(await getErrorsPagesCount_NotHandled());
        setErrors(await getNotHandledErrors(1));
      }
    }
  }

  const handleOnSearchEnter = async () => {
    if (searchValue.length === 0) return;

    let errors;
    errors = await getErrorByKey(searchValue, setLoading);
    setErrors(errors);
  }

  return (
    <div className='errors-searchbar-component'>
      <div className='errors-search-input-container'>
        <SearchInput 
          placeholder={'Error code'} 
          value={searchValue} 
          onChange={(newValue) => handleOnSearchValueChange(newValue)} 
          onEnter={handleOnSearchEnter} 
        />
      </div>
    </div>
  )
}
