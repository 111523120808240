import React from 'react';
import './profileDropDownItem.css';

export default function ProfileDropDownItem({ title, children, borderTop = false, borderBottom = false, itemOnClickHandler }) {

  return (
    <li className={`profile-dropdown-item-container ${borderTop ? 'border-top' : ''} ${borderBottom ? 'border-bottom' : ''}`} onClick={itemOnClickHandler}>
      {children}
      <p>
        {title}
      </p>
    </li>
  );
}
