// base
import React from 'react'
import './transactionsTimeline.css';

// components
import TransactionsItem from '../transactions_item/TransactionsItem';

// logic 
import {
  TransactionTypes
} from '../../../pages/transactions/transactions/transactionsLogic';

export default function TransactionsTimeline({ transactions, type }) {

  const getData = (data) => {
    let transaction = {
      transactionId: null,
      targetId: null,
      userName: null,
      tableName: null,
      data: null,
      date: null
    };

    if (type === TransactionTypes.ADDITIONS) {
      transaction = {
        transactionId: data.additionId,
        targetId: data.targetId,
        userName: data.userName,
        tableName: data.tableName,
        data: data.addedData,
        date: data.addedDate
      };
    } else if (type === TransactionTypes.UPDATES) {
      transaction = {
        transactionId: data.updateId,
        targetId: data.targetId,
        userName: data.userName,
        tableName: data.tableName,
        data: data.updatedData,
        date: data.updateDate
      };
    } else if (type === TransactionTypes.DELETIONS) {
      transaction = {
        transactionId: data.deletionId,
        targetId: data.targetId,
        userName: data.userName,
        tableName: data.tableName,
        data: data.deletionData,
        date: data.deletionDate
      };
    }

    return transaction;
  };

  const newTransactionsArray = transactions.map(transaction => 
    getData(transaction, type)
  );
  
  return (
    <div className='transactions-timeline-component'>

      <div className='line'></div>

      {newTransactionsArray.map((transaction, index) => (

        <div className='transactions-timeline-item-container' key={transaction.transactionId}>
          <TransactionsItem 
            _index={index} 
            type={type}
            targetId={transaction.targetId}
            username={transaction.userName}
            tableName={transaction.tableName}
            data={transaction.data}
            date={transaction.date}
          />
        </div>
      ))}

      <div className={`end-point-for-no-items ${transactions.length === 0 ? 'visible' : ''}`}></div>

    </div>
  )
}
