import {
  fetchScalarData, 
  fetchMultipleData
} from '../../../global/utils/functions/apiService';

const UsersModeEnum = Object.freeze({
  ACTIVE_USERS: 'active-users',
  DELETED_USERS: 'deleted-users',
});

const ActionEnum = Object.freeze({
  NULL: 'null',
  ALERT: 'alert'
});

const getBackgroundColor = (userId) => {
  const colors = ["#FF5733", "#33C3FF", "#A8D8B9", "#FFB84E", "#D1A4FF"];
  return colors[userId % colors.length];
};

function setMessage(title, message, state) {
  state({
    title: title,
    message: message,
    isVisible: true
  });
}

function resetMessage(state) {
  state({
    title: '',
    message: '',
    isVisible: false
  });
}

function executeAlert() {
  alert('helloooo');
}

async function actionExecuter(action) {
  switch (action) {
    case ActionEnum.NULL:
      return;
    case ActionEnum.ALERT:
      executeAlert();
      return;
    default:
      return;
  }
}

async function activeUsersCountAsync() {
  const result = await fetchScalarData('/Users/GetUsersCount');

  if (result.success) {
    return result.data.Response.data.count;
  } else {
    console.log('Error fetching active users count:', result.message);
    return 0;
  }
}

async function deletionUsersCountAsync() {
  const result = await fetchScalarData('/Users/GetDeletionUsersCount');

  if (result.success) {
    return result.data.Response.data.count;
  } else {
    console.log('Error fetching deletion users count:', result.message);
    return 0;
  }
}

async function getActiveUsersPagesCountAsync() {
  const url = '/Users/GetAllUsersPagesCount';

  const response = await fetchScalarData(url);

  if (response.success) {
    return response.data.Response.data.count;
  } else {
    console.log(response.message);
    return 0;
  }
}

async function getDeletionUsersPagesCountAsync() {
  const url = '/Users/GetDeletionUsersPagesCount';

  const response = await fetchScalarData(url);

  if (response.success) {
    return response.data.Response.data.count;
  } else {
    console.log(response.message);
    return 0;
  }
}

async function getActiveUsersAsync(pageNumber) {
  const url = '/Users/GetAllUsers';

  const response = await fetchMultipleData(url, pageNumber);
  if (response.success) {
    return response.data.Response.data.users;
  } else {
    console.log(response.message);
    return []
  }
}

async function getDeletionUsersAsync(pageNumber) {
  const url = '/Users/GetDeletionUsers';

  const response = await fetchMultipleData(url, pageNumber);
  if (response.success) {
    return response.data.Response.data.users;
  } else {
    console.log(response.message);
    return []
  }
}

async function getUsersByFullNameAsync(fullname, setLoadingState) {
  if (!fullname) {
    return [];
  }

  try {
    if (setLoadingState) {
      setLoadingState(true);
    }

    const url = `/Users/GetUsersByFullName/${fullname}`; 
    const response = await fetchMultipleData(url);

    if (response.success) {
      return response.data.Response.data.users || [];
    } else {
      console.log(`Error: ${response.message}`);
      return []
    }
  } catch (error) {
    console.log("An unexpected error occurred:", error);
    return []
  } finally {
    if (setLoadingState) {
      setLoadingState(false);
    }
  }
}

async function getUsersByUserNameOrEmailAsync(usernameOrEmail, setLoadingState) {
  if (!usernameOrEmail) {
    return [];
  }

  try {
    if (setLoadingState) {
      setLoadingState(true);
    }

    const url = `/Users/GetUserByUserNameOrEmail/${usernameOrEmail}`; 
    const response = await fetchMultipleData(url);

    if (response.success) {
      return response.data.Response.data.user ? [response.data.Response.data.user] : [];
    } else {
      console.log(`Error: ${response.message}`);
      return []
    }
  } catch (error) {
    console.log("An unexpected error occurred:", error);
    return []
  } finally {
    if (setLoadingState) {
      setLoadingState(false);
    }
  }
}


export {
  UsersModeEnum,
  ActionEnum,
  getBackgroundColor,
  setMessage,
  resetMessage,
  actionExecuter,
  activeUsersCountAsync,
  deletionUsersCountAsync,
  getActiveUsersPagesCountAsync,
  getDeletionUsersPagesCountAsync,
  getActiveUsersAsync,
  getDeletionUsersAsync,
  getUsersByFullNameAsync,
  getUsersByUserNameOrEmailAsync
}