import React from 'react'
import './messageBox.css';
import CloseIcon from '@mui/icons-material/Close';

export default function MessageBox({ title, message, children, isVisable, onClose }) {

  return (
    <div className={`message-box-component ${isVisable ? 'visable' : ''}`}>
      
      <div className='message-box-content'>

        <div className='message-box-header'>
          <h3 className='message-box-title'>{title}</h3>
          <CloseIcon className='close' fontSize='' onClick={onClose} />
        </div>

        <p className='message-box-body'>{message}</p>

        <div className='message-box-btns-container'>

          {children}

        </div>

      </div>

    </div>
  )
}
