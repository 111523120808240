import {
  fetchMultipleData, fetchScalarData
} from '../../../global/utils/functions/apiService';

const TransactionTypes = Object.freeze({
  ADDITIONS: 'additions',
  UPDATES: 'updates',
  DELETIONS: 'deletions'
});

async function getAdditionsPagesCount(adminId) {
  const url = `/Additions/GetAdditionsPagesCountByAdmin/${adminId}`;

  const response = await fetchScalarData(url);
  if (response.success) {
    return response.data.Response.data.count;
  } else {
    console.log(response.message);
    return 0;
  }
}

async function getDeletionsPagesCount(adminId) {
  const url = `/Deletions/GetDeletionsPagesCountByAdmin/${adminId}`;

  const response = await fetchScalarData(url);
  if (response.success) {
    return response.data.Response.data.count;
  } else {
    console.log(response.message);
    return 0;
  }
}

async function getUpdatesPagesCount(adminId) {
  const url = `/Updates/GetUpdatesPagesCountByAdmin/${adminId}`;

  const response = await fetchScalarData(url);
  if (response.success) {
    return response.data.Response.data.count;
  } else {
    console.log(response.message);
    return 0;
  }
}

async function getPagesCount(adminId, type) {
  switch (type) {
    case TransactionTypes.ADDITIONS:
      return await getAdditionsPagesCount(adminId);
    
    case TransactionTypes.UPDATES:
      return await getUpdatesPagesCount(adminId);

    case TransactionTypes.DELETIONS:
      return await getDeletionsPagesCount(adminId);

    default:
      return -1
  }
}

async function getAdditions(adminId, pageNumber) {
  const url = `/Additions/GetAdditionsByAdmin/${adminId}/${pageNumber}`;

  const response = await fetchMultipleData(url);
  if (response.success) {
    return response.data.Response.data.additions;
  } else {
    console.log(response.message);
    return []
  }
}

async function getUpdates(adminId, pageNumber) {
  const url = `/Updates/GetUpdatesByAdmin/${adminId}/${pageNumber}`;

  const response = await fetchMultipleData(url);
  if (response.success) {
    return response.data.Response.data.updates;
  } else {
    console.log(response.message);
    return []
  }
}

async function getDeletions(adminId, pageNumber) {
  const url = `/Deletions/GetDeletionsByAdmin/${adminId}/${pageNumber}`;

  const response = await fetchMultipleData(url);
  if (response.success) {
    return response.data.Response.data.deletions;
  } else {
    console.log(response.message);
    return []
  }
}

async function getTransactions(adminId, pageNumber, type) {
  switch (type) {
    case TransactionTypes.ADDITIONS:
      return await getAdditions(adminId, pageNumber);
    
    case TransactionTypes.UPDATES:
      return await getUpdates(adminId, pageNumber);

    case TransactionTypes.DELETIONS:
      return await getDeletions(adminId, pageNumber);

    default:
      return []
  }
}

export {
  TransactionTypes,
  getPagesCount,
  getTransactions
}
