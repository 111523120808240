import config from '../../global/config';
import { isEmailValid, isUsernameValid, isPasswordValid } from "../../global/utils/validation";
import axios from 'axios';
import { setCurrentUser } from '../../global/data/global_data';
import { encrypt, decrypt } from '../../global/utils/crypto/aesEncryption'
import { saveCurrentAdminToSession } from '../../global/utils/session_storage/sessionStorage';

function getFromLocalStorage() {
  try {
    let emailOrUserName = localStorage.getItem('emailOrUserName') || '';
    let password = localStorage.getItem('password') || '';

    if (emailOrUserName && password) {
      emailOrUserName = decrypt(emailOrUserName);
      password = decrypt(password);

      return {
        emailOrUserName: emailOrUserName || '',
        password: password || ''
      };
    }
  } catch (error) {
    console.error('Error retrieving data from local storage:', error);
  }
  return { emailOrUserName: '', password: '' };
}

function isLocalStorageHasData() {
  const data = getFromLocalStorage();
  return !!data.emailOrUserName && !!data.password;
}

function logToLocalStorage(userInput) {
  try {
    const emailOrUserName = encrypt(userInput.emailOrUserName);
    const password = encrypt(userInput.password);

    if (emailOrUserName && password) {
      localStorage.setItem('emailOrUserName', emailOrUserName);
      localStorage.setItem('password', password);
    }
  } catch (error) {
    console.error('Error logging data to local storage:', error);
  }
}

function removeFromLocalStorage() {
  localStorage.removeItem('emailOrUserName');
  localStorage.removeItem('password');
}

function validateEmailOrUserName(input) {
  return isEmailValid(input) || isUsernameValid(input) ? true : false
}

function validatePassword(input) {
  return isPasswordValid(input);
}

function onChangeHandler(field, newValue, state) {
  state(prevState => ({
    ...prevState,
    [field]: newValue
  }));
}

function onRememberMeChangeHandler(newValue, state) {
  state(newValue)
}

function setErrorState(isVisible, message, state) {
  state({ isVisible, message })
}

function setLoaderState(isVisible, loaderState) {
  loaderState(isVisible)
}

async function GetUserAsync(userInput, rememberMe, errorState, loaderState, dispatch, navigate) {
  try
  {
    setLoaderState(true, loaderState)
    setErrorState(false, '', errorState);
    
    const url = `${config.apiBaseUrl}/Admins/GetAdminByAuth/${userInput.emailOrUserName}/${userInput.password}`;
    const response = await axios.get(url);
    
    if (response.data.data.admin.permissions === 0)
      setErrorState(true, 'You do not have permission to access this resource.', errorState);
    else {
      saveCurrentAdminToSession(response.data.data.admin)
      dispatch(setCurrentUser(response.data.data.admin));
      navigate('/home');
    }

    if (rememberMe) 
      logToLocalStorage(userInput)
    else
      removeFromLocalStorage(userInput)
  }
  catch (error) 
  {
    if (error.response) 
      {
      switch (error.response.status) 
      {
        case 404:
          setErrorState(true, 'The email or password you entered is incorrect. Please try again.', errorState);
          break;
        case 500:
          setErrorState(true, 'There is a problem with the server. Please try again later.', errorState);
          break;
        default:
          setErrorState(true, 'An unexpected error occurred: ' + error.response.status, errorState);
          break;
      }
    } 
    else 
    {
      setErrorState(true, 'Network error. Please check your internet connection and try again.', errorState);
    }
  }
  finally
  {
    setLoaderState(false, loaderState)
  }
}

async function handleSignInAsync(userInput, rememberMe, errorState, loaderState, dispatch, navigate) {

  if (!validateEmailOrUserName(userInput.emailOrUserName)) {
    setErrorState(true, 'Invalid email or username.', errorState)
    return;
  }

  if (!validatePassword(userInput.password)) {
    setErrorState(true, 'Invalid password.', errorState)
    return; 
  }

  await GetUserAsync(userInput, rememberMe, errorState, loaderState, dispatch, navigate);
}

export {
  setLoaderState,
  getFromLocalStorage,
  isLocalStorageHasData,
  validateEmailOrUserName,
  validatePassword, 
  onChangeHandler, 
  onRememberMeChangeHandler,
  handleSignInAsync
}