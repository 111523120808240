import React from 'react'
import './searchInput.css';

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

export default function SearchInput({ placeholder, value, onChange, onEnter }) {

  const inputId = Math.floor(Math.random() * (1 - 1000 + 1)) + 1;
  const onEnterHandler = () => {
    onEnter();
  }

  const onChangeHandler = (e) => {
    onChange(e.target.value);
  }

  const onClearHandler = () => {
    onChange('');
  }

  const handleEnterDown = (e) => {
    if (e.key === 'Enter' && onEnter)
      onEnter();
  }

  return (
    <div className='search-input-component'>
      
      <SearchIcon className='search-icon' onClick={onEnterHandler}/>

      <input id={inputId} className='search-input'
        type='text' 
        placeholder={placeholder} 
        value={value}
        autoCapitalize="none"
        onChange={onChangeHandler}
        onKeyDown={handleEnterDown}
      />
      <ClearIcon className='clear-icon' onClick={onClearHandler} />

    </div>
  )
}
