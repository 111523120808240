import { fetchMultipleData } from "../../../global/utils/functions/apiService";

async function getUserByIdAsync(userId) {
  const url = `/Users/GetUserById/${userId}`;

  const response = await fetchMultipleData(url);

  if (response.success) {
    return(response.data.Response.data.user);
  } else {
    console.error(response.message);
    return null;
  }
}

async function getUserSessionsAnalyticsAsync(userId) {
  const url = `/Sessions/GetUserSessionsAnalytics/${userId}`;

  const response = await fetchMultipleData(url);

  if (response.success) {
    return {
      activeSessions: response.data.Response.data.activeSessions,
      inActiveSessions: response.data.Response.data.inActiveSessions,
      totalSessions: response.data.Response.data.totalSessions
    };
  } else {
    console.error(response.message);
    return null;
  }
}

async function getActiveSubscriptionAsync(userId) {
  const url = `/UsersSubscriptions/GetActiveUserSubscriptionByUser/${userId}`;

  const response = await fetchMultipleData(url);

  if (response.success) {
    return(response.data.Response.data.subscriptions);
  } else {
    console.error(response.message);
    return null;
  }
}

async function getPaymentsAsync(userId) {
  const url = `/Payments/GetPaymentsByUser/${userId}`;

  const response = await fetchMultipleData(url);

  if (response.success) {
    return response.data.Response.data.payments;
  } else {
    console.error(response.message);
    return null;
  }
}

export {
  getUserByIdAsync,
  getUserSessionsAnalyticsAsync,
  getActiveSubscriptionAsync,
  getPaymentsAsync
}