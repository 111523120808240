import React from 'react';
import './sideBarSectionItem.css';
import { Link } from 'react-router-dom';

export default function SideBarSectionItem({ path, title, isActive, onClick }) {
  return (
    <Link className='sidebar-section-item-link' to={path}>
      <li className={`sidebar-section-item ${isActive ? 'active' : ''}`} onClick={onClick}>
        {title}
      </li>
    </Link>
  )
}
