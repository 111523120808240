import React, { createContext, useState } from 'react';
import { AdditionsModeEnum } from './additionsLogic';

export const AdditionsContext = createContext();

export const AdditionsProvider = ({ children }) => {

  const [additions, setAdditions] = useState([]);
  const [additionsCount, setAdditionsCount] = useState(0);
  const [additionsPagesCount, setAdditionsPagesCount] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [additionsMode, setAdditionsMode] = useState(AdditionsModeEnum.ALL_ADDITIONS);
  const [tableId, setTableId] = useState(null);
  const [tables, setTables] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modelMessage, setModelMessage] = useState({ title: '', message: '', isVisible: false });
  const [messageBox, setMessageBox] = useState({ title: '', message: '', isVisible: false });
  const [reload, setReload] = useState(false);

  return (
  <AdditionsContext.Provider
    value={{
      additions,
      setAdditions,
      additionsCount, 
      setAdditionsCount,
      additionsPagesCount,
      setAdditionsPagesCount,
      currentPageNumber,
      setCurrentPageNumber,
      additionsMode,
      setAdditionsMode,
      tableId, 
      setTableId,
      tables,
      setTables,
      loading,
      setLoading,
      modelMessage,
      setModelMessage,
      messageBox,
      setMessageBox,
      reload,
      setReload
    }}
  >
      {children}
  </AdditionsContext.Provider>
  );
};
