import './sideBar.css';
import SideBarSection from '../sidebar_section/SideBarSection';
import SideBarSectionItem from '../sidebar_section_item/SideBarSectionItem';

import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import AccountTreeIcon from '@mui/icons-material/AccountTree';

export default function SideBar({activeItem, setActiveItem }) {

  const toggleSidebarClass = () => {
    if (window.innerWidth <= 600)
    document.body.classList.toggle('toggle-sidebar');
  };

  const handleItemClick = (itemTitle) => {
    setActiveItem(itemTitle);
    toggleSidebarClass();
  };

  return (
    <aside id='sidebar-container' className='sidebar-container'>
      <ul id='sidebar-menu' className='sidebar-menu'>
        <SideBarSection title={'People'} icon={PeopleAltIcon}>
          <SideBarSectionItem 
            path={'/admins'}
            title={'Admins'} 
            isActive={activeItem === 'Admins'} 
            onClick={() => handleItemClick('Admins')}
          />
          <SideBarSectionItem 
            path={'/users'}
            title={'Users'} 
            isActive={activeItem === 'Users'} 
            onClick={() => handleItemClick('Users')}
          />
        </SideBarSection>

        <SideBarSection title={'Transactions'} icon={TransferWithinAStationIcon}>
          <SideBarSectionItem 
            path={'additions'}
            title={'Additions'} 
            isActive={activeItem === 'Additions'} 
            onClick={() => handleItemClick('Additions')}
          />
          <SideBarSectionItem 
            path={'deletions'}
            title={'Deletions'} 
            isActive={activeItem === 'Deletions'} 
            onClick={() => handleItemClick('Deletions')}
          />
          <SideBarSectionItem 
            path={'updates'}
            title={'Updates'} 
            isActive={activeItem === 'Updates'} 
            onClick={() => handleItemClick('Updates')}
          />
        </SideBarSection>

        <SideBarSection title={'Resources'} icon={PeopleAltIcon}>
          <SideBarSectionItem 
            title={'Courses'} 
            isActive={activeItem === 'Courses'} 
            onClick={() => handleItemClick('Courses')}
          />
          <SideBarSectionItem 
            title={'Typical Questions'} 
            isActive={activeItem === 'Typical Questions'} 
            onClick={() => handleItemClick('Typical Questions')}
          />
          <SideBarSectionItem 
            title={'Acceptance Rates'} 
            isActive={activeItem === 'Acceptance Rates'} 
            onClick={() => handleItemClick('Acceptance Rates')}
          />
          <SideBarSectionItem 
            title={'Acceptance Rates Files'} 
            isActive={activeItem === 'Acceptance Rates Files'} 
            onClick={() => handleItemClick('Acceptance Rates Files')}
          />
          <SideBarSectionItem 
            path={'questions/general'}
            title={'General Questions'} 
            isActive={activeItem === 'General Questions'} 
            onClick={() => handleItemClick('General Questions')}
          />
        </SideBarSection>

        <SideBarSection title={'Base'} icon={PeopleAltIcon}>
          <SideBarSectionItem 
            title={'Academic Years'} 
            isActive={activeItem === 'Academic Years'} 
            onClick={() => handleItemClick('Academic Years')}
          />
          <SideBarSectionItem 
            title={'Branches'} 
            isActive={activeItem === 'Branches'} 
            onClick={() => handleItemClick('Branches')}
          />
          <SideBarSectionItem 
            title={'Materials'} 
            isActive={activeItem === 'Materials'} 
            onClick={() => handleItemClick('Materials')}
          />
          <SideBarSectionItem 
            title={'Typical Questions Types'} 
            isActive={activeItem === 'Typical Questions Types'} 
            onClick={() => handleItemClick('Typical Questions Types')}
          />
          <SideBarSectionItem 
            title={'Subscription Plans'} 
            isActive={activeItem === 'Subscription Plans'} 
            onClick={() => handleItemClick('Subscription Plans')}
          />
        </SideBarSection>

        <SideBarSection title={'System'} icon={AccountTreeIcon}>
          <SideBarSectionItem 
            path={'/errors'}
            title={'Errors'} 
            isActive={activeItem === 'Errors'} 
            onClick={() => handleItemClick('Errors')}
          />

          <SideBarSectionItem 
            title={'etc'} 
            isActive={activeItem === 'etc'} 
            onClick={() => handleItemClick('etc')}
          />
        </SideBarSection>
      </ul>
    </aside>
  );
}
