// base
import React from 'react'
import './transactionsBody.css';

// components
import TransactionsTimeline from '../transactions_timeline/TransactionsTimeline';

export default function TransactionsBody({ transactions, type }) {
  return (
    <div className='transactions-body-component'>
      <TransactionsTimeline transactions={transactions} type={type} />
    </div>
  )
}
