// base
import React, { useState } from 'react'
import './operationsHistoryItem.css';

// icons
import { IoMdAddCircle } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import CopyAllIcon from '@mui/icons-material/CopyAll';

// mui 
import { Tooltip } from '@mui/material';

// logic
import {
  TimelineItemType
} from '../../../pages/operations_history/operations_history/operationsHistoryLogic'

import {
  getDate
} from '../../../global/utils/functions/utils';

export default function OperationsHistoryItem({ _index, type, username, tableName, data, date }) {

  const [isHovered, setIsHovered] = useState(false);
  const [tooltipText, setTooltipText] = useState("Copy");

  const [isOpen, setIsOpen] = useState(true);

  const generateItemMessage = () => {
    if (type === TimelineItemType.ADD) {
      return <p className='item-message'><strong>{username}</strong> added the following entity to the <strong>{tableName}</strong> table on {getDate(date)}.</p>
    }
    else if (type === TimelineItemType.UPDATE) {
      return <p className='item-message'><strong>{username}</strong> updated the following entity in the <strong>{tableName}</strong> table on {getDate(date)}.</p>;
    }
    else if (type === TimelineItemType.DELETE) {
      return <p className='item-message'><strong>{username}</strong> deleted the following entity from the <strong>{tableName}</strong> table on {getDate(date)}.</p>;
    }
  }

  const dataJson = () => {
    try {
      const parsedData = JSON.parse(data);
      return JSON.stringify(parsedData, null, 2);
    } catch {
      return data;
    }
  }

  const handleClick = () => {
    navigator.clipboard.writeText(data)
    .then(() => {
      setTooltipText("Copied");
      setTimeout(() => {
        setTooltipText("Copy");
      }, 2000);
    })
    .catch((err) => {
      console.log("Failed to copy text: ", err);
    });
  };

  return (
    <div className={`operations-history-item-component ${_index % 2 === 0 ? 'left' : 'right'}  ${isOpen ? '' : 'closed'}`}>

      <div className={`operations-history-stage-container ${_index % 2 === 0 ? 'left' : 'right'} ${isHovered ? 'hovered' : ''}`} onClick={() => {setIsOpen(!isOpen)}}>
        <p className='operations-history-stage'>S{_index + 1}</p>
      </div>

      <div className={'item-content'}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        
        <div className='item-icon-container'>
          {
            type === TimelineItemType.ADD ?
              (<IoMdAddCircle fontSize={''} className='item-icon' />) :
            type === TimelineItemType.UPDATE ? (<MdEdit fontSize={''} className='item-icon' />) :
            (<MdDelete fontSize={''} className='item-icon' />)
          }
        </div>

        <div className='item-message-container'>
            {generateItemMessage()}
        </div>

        <div className='item-data-container'>
          <pre className='item-data'>
            <p className='item-data-header'>
              {
                type === TimelineItemType.ADD ? 'new data' :
                type === TimelineItemType.UPDATE ? 'updated data' :
                type === TimelineItemType.DELETE ? 'deleted data' : 'data'
              } 

            <Tooltip title={tooltipText}>
              <CopyAllIcon fontSize={'small'} className='icon' onClick={handleClick} onMouseLeave={() => {setTooltipText("Copy")}}/>
            </Tooltip>
            </p>

            {dataJson()}
          </pre>
        </div>

        <div className='item-date-container'>
          <p className='item-date-label'>happened on:</p>
          <p className='item-date'>{getDate(date)}</p>
        </div>

      </div>
      
    </div>
  )
}
