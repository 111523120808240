import config from '../../../global/config';
import { isEmailValid } from '../../../global/utils/validation';
import axios from 'axios';

function validateEmail(input) {
  return isEmailValid(input);
}

function setErrorState(isVisible, message, state) {
  state({ isVisible, message })
}

function setLoaderState(isVisible, loaderState) {
  loaderState(isVisible)
}

function handleCancel(navigate) {
  navigate('/login');
}

async function isEmailExistAsync(email, loaderState, errorState) {
  try
  {
    setLoaderState(true, loaderState)
    setErrorState(false, '', errorState);
    
    const url = `${config.apiBaseUrl}/Admins/IsAdminEmailExist/${email}`;
    const response = await axios.get(url);
    
    if (!response.data.data.isExist)
      setErrorState(true, 'The email you entered does not exists. Please try again.', errorState);

    return response.data.data.isExist
  }
  catch (error)
  {
    if (error.response)
      {
        switch (error.response.status) 
        {
          case 500:
            setErrorState(true, 'There is a problem with the server. Please try again later.', errorState);
            break;
          default:
            setErrorState(true, 'An unexpected error occurred: ' + error.response.status, errorState);
            break;
        }
    }
    else 
    {
      setErrorState(true, 'Network error. Please check your internet connection and try again.', errorState);
    }
  }
  finally
  {
    setLoaderState(false, loaderState)
  }
}

async function SendPasswordResetConfirmationEmailAsync(email, loaderState, errorState) {
  try
  {
    setLoaderState(true, loaderState)
    setErrorState(false, '', errorState);
    const url = `${config.apiBaseUrl}/Notifications/SendPasswordResetConfirmationEmail/${email}/admin`;
    const response = await axios.get(url);
    
    if (response.data.data.virificationCode === undefined || response.data.data.virificationCode === null)
      setErrorState(true, 'An unexpected error occurred: ', errorState);

    return response.data.data.virificationCode
  }
  catch (error) 
  {
    if (error.response) 
      {
      switch (error.response.status) 
      {
        case 500:
          setErrorState(true, 'There is a problem with the server. Please try again later.', errorState);
          break;
        default:
          setErrorState(true, 'An unexpected error occurred: ' + error.response.status, errorState);
          break;
      }
    } 
    else 
    {
      setErrorState(true, 'Network error. Please check your internet connection and try again.', errorState);
    }
  }
  finally
  {
    setLoaderState(false, loaderState)
  }
}

async function onNextButtonClickHandlerAsync(email, loaderState, errorState, onCodeReceivingHandler, stepsState) {
  
  if (!validateEmail(email)) {
    setErrorState(true, 'Invalid email.', errorState)
    return;
  }

  if (await isEmailExistAsync(email, loaderState, errorState)) {
    const verificationCode = await SendPasswordResetConfirmationEmailAsync(email, loaderState, errorState);
  
    if (verificationCode !== undefined || verificationCode !== null) {
      onCodeReceivingHandler(verificationCode);
      stepsState('2')
    }
  }

}

export {
  validateEmail,
  handleCancel,
  onNextButtonClickHandlerAsync
}
