import React, { useState } from 'react'
import './adminsBody.css'
import AdminCard from '../admin_card/AdminCard';
import Loading from '../../loading/Loading';

export default function AdminsBody({ admins, onAdminsChange }) {

  const [loader, setLoader] = useState(false);

  return (
    <div className='admins-body-component'>
      
        {admins && admins.length > 0 ? (
          admins.map((admin) => (
            <div className='admin-card-container' key={admin.adminId}>
              <AdminCard
                adminId={admin.adminId} 
                isActive={admin.isActive} 
                fullName={admin.fullName} 
                userName={admin.userName} 
                profileImageURL={admin.profileImageURL} 
                isDeleted={admin.isDeleted}
                loaderState={setLoader}
                onAdminsChange={onAdminsChange}
              />
            </div>
          ))
        ) : <p>No admins available.</p>}

        <Loading isVisible={loader} />

    </div>
  )
}
