import config from '../../../global/config';
import axios from 'axios';
import {
  isEmailValid,
  isUsernameValid,
  isPasswordValid,
  checkLength,
  isInt
} from '../../../global/utils/validation';

function handleInputChange(fieldName, value, setState, stage) {

  const validFieldsS1 = ['fullname', 'username', 'email', 'password', 'confirmPassword'];
  
  if (stage === 's1' && validFieldsS1.includes(fieldName)) {
    setState((prevAdmin) => ({
      ...prevAdmin,
      [fieldName]: value
    }));
  } else if (stage === 's2' && fieldName === 'verificationCode') {
    setState((prevAdmin) => ({
      ...prevAdmin,
      [fieldName]: value
    }));
  }
}

function validateEmail(email) {
  return isEmailValid(email);
}

function validateUserName(username) {
  return isUsernameValid(username);
}

function validatePassword(password) {
  return isPasswordValid(password);
}

function validateFullName(fullname) {
  return checkLength(1, 25, fullname);
}

function validateVerificationCode(code) {
  return isInt(code) && code.length === 6;
}

function validateAdmin(admin) {
  if (!validateFullName(admin.fullname))
    return false;

  if (!validateUserName(admin.username))
    return false;

  if (!validateEmail(admin.email))
    return false;

  if (!validatePassword(admin.password))
    return false;

  if (!validatePassword(admin.confirmPassword))
    return false;

  return true;
}

function resetAdmin(adminState, sentCodeState, stageState) {
  adminState({
    fullname: '', 
    username: '', 
    email: '', 
    verificationCode: '', 
    password: '', 
    confirmPassword: ''
  });
  sentCodeState('');
  stageState('s1');
}

async function isEmailExistAsync(email) {
  try
  {
    const url = `${config.apiBaseUrl}/Admins/IsAdminEmailExist/${email}`;
    const response = await axios.get(url);
    return response.data.data.isExist || false;
  }
  catch
  {
    return false;
  }
}

async function isUserNameExistAsync(username) {
  try
  {
    const url = `${config.apiBaseUrl}/Admins/IsAdminUserNameExist/${username}`;
    const response = await axios.get(url);
    return response.data.data.isExist || false;
  }
  catch
  {
    return false;
  }
}

async function sendEmailAsync(email, username) {
  try
  {
    const url = `${config.apiBaseUrl}/Notifications/SendRegistrationConfirmationEmail/${email}/${username}`;
    const response = await axios.get(url);
    
    if (response.data.data.virificationCode === undefined || response.data.data.virificationCode === null) {
      return null;
    }

    return response.data.data.virificationCode
  }
  catch
  {
    return null;
  }
}

async function NewAdminAsync(admin, byAdmin, currentAdminPermissions) {
  try {
    if ((currentAdminPermissions & 32) !== 32)
      return;
    
    const url = `${config.apiBaseUrl}/Admins/NewAdmin`;
    const response = await axios.post(url, {
      permissions: 0,
      fullName: admin.fullname,
      email: admin.email,
      userName: admin.username,
      password: admin.password,
      byAdmin: byAdmin
    });

    if (response.status === 201) {
      return { success: true, message: 'Admin created successfully.' };
    } else {
      return { success: false, message: 'Failed to create admin.' };
    }
  } catch (error) {
    const errorMessage = error.response?.data?.message || 'An error occurred while processing your request.';
    return { success: false, message: errorMessage };
  }
};



export {
  handleInputChange,
  validateEmail,
  validateUserName,
  validatePassword,
  validateFullName, 
  validateVerificationCode,
  validateAdmin,
  resetAdmin,
  isEmailExistAsync,
  isUserNameExistAsync,
  sendEmailAsync,
  NewAdminAsync
}