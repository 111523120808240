// base
import React, { useContext } from 'react';
import './errorsBody.css';

// context
import { ErrorsContext } from '../../../pages/errors/errors/errorsContext';

// components
import ErrorCard from '../error_card/ErrorCard';

export default function ErrorsBody() {
  const { errors } = useContext(ErrorsContext);

  return (
    <div className='errors-body-component'>
      {errors.length > 0 ? (
        errors.map((error, index) => (
          <div key={error.errorId || index} className='error-card-container'>
            <ErrorCard 
              errorId={error.errorId} 
              _index={index + 1} 
              _layer={error.layer} 
              _class={error.class} 
              status={error.isHandled} 
            />
          </div>
        ))
      ) : (
        <div className="no-errors-message">
          No errors found.
        </div>
      )}
    </div>
  )
}
