// base
import React, { useState, useContext, useEffect } from 'react';
import './errorCard.css';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// context 
import { ErrorsContext } from '../../../pages/errors/errors/errorsContext';

// icons
import { FaRegArrowAltCircleDown } from "react-icons/fa";
import { FaRegArrowAltCircleUp } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import CopyAllIcon from '@mui/icons-material/CopyAll';

// mui 
import { Tooltip } from '@mui/material';

// components 
import Loading from '../../loading/Loading';
import ContainedButton from '../../inputs/buttons/contained_button/ContainedButton';

// logic
import {
  getErrorById,
  handleDescription,
  setDescriptionAsync,
  setErrorAsHandled
} from '../../../pages/errors/errors/errorsLogic';
import { getDate } from '../../../global/utils/functions/utils';

export default function ErrorCard({ errorId, _index, _layer, _class, status}) {

  const [tooltipText, setTooltipText] = useState("Copy");

  const {
    setErrors,
    setModelMessage
  } = useContext(ErrorsContext);

  const currentAdminId = useSelector((state) => state.currentUser.adminId);
  const currentAdminPermissions = useSelector((state) => state.currentUser.permissions);

  const [loader, setLoader] = useState(false);
  const [descriptionMode, setDescriptionMode] = useState(false);
  const [descriptionLoader, setDescriptionLoader] = useState(false);
  const [openMode, setOpenMode] = useState(false);
  const [error, setError] = useState({
    source: null,
    method: null,
    message: null,
    stackTrace: null,
    description: null,
    params: null,
    date: null,
    errorKey: null
  })

  const [descriptionInputValue, setDescriptionInputValue] = useState(handleDescription(error.description));

  useEffect(() => {
    setDescriptionInputValue(handleDescription(error.description))
  }, [error.description])

  const onErrorCardOpened = async () => {
    const data = await getErrorById(errorId, setLoader);
    if (data) {
      setError({
        source: data.source,
        method: data.method,
        message: data.message,
        stackTrace: data.stackTrace,
        description: data.description,
        params: data.params,
        date: data.errorDate,
        errorKey: data.errorKey
      });
    }
  }

  const updateErrorDescription = () => {
    setError(prevError => ({
      ...prevError,
      description: descriptionInputValue
    }));
  };
  
  const updateErrorStatus = () => {
    setErrors(prevErrors => 
      prevErrors.map(error => 
        error.errorId === errorId ? { ...error, isHandled: true } : error
      )
    );
  };
  
  const dataJson = () => {
    if (!error.params)
      return null;

    try {
      const parsedData = JSON.parse(error.params);
      return JSON.stringify(parsedData, null, 2);
    } catch {
      return error.params;
    }
  }

  const handleClick = () => {
    navigator.clipboard.writeText(error.params)
    .then(() => {
      setTooltipText("Copied");
      setTimeout(() => {
        setTooltipText("Copy");
      }, 2000);
    })
    .catch((err) => {
      console.log("Failed to copy text: ", err);
    });
  };

  return (
    <div className='error-card-component'>

      <div className='card-header' onClick={async() => {
        if (openMode === true) {
          setOpenMode(false)
        } else {
          setOpenMode(true)
          await onErrorCardOpened();
        }
      }} >
        <div className='label-index'>
          <p className='label index'>#/{_index}</p>
        </div>

        <div className='label-layer'>
          <p className='label'>Layer</p>
          <p className='value'>{_layer}</p>
        </div>

        <div className='label-class'>
          <p className='label'>Class</p>
          <p className='value'>{_class}</p>
        </div>

        <div className='label-status'>
          <p className='label'>Status</p>
          <p className={`error-status value ${status ? 'handled' : 'not-handled'}`}>{status ? 'Handled' : 'Not Handled'}</p>
        </div>

        <div className='icon-container'>
          {openMode ? (
            <FaRegArrowAltCircleUp className='card-icon' fontSize={''} onClick={() => {setOpenMode(!openMode)}} />
          ) : (
            <FaRegArrowAltCircleDown className='card-icon' fontSize={''} onClick={async() => {
              setOpenMode(!openMode)
              await onErrorCardOpened();
            }} />
          )}
        </div>
      </div>

      <div className={`card-body ${openMode ? 'opened' : ''}`}>

        <Loading isVisible={loader} />

        <div className='item'>
          <p className='label'>Source</p>
          <p className='value'>{error.source}</p>
        </div>

        <div className='item'>
          <p className='label'>Method</p>
          <p className='value'>{error.method}</p>
        </div>

        <div className='item'>
          <p className='label'>Message</p>
          <p className='value'>{error.message}</p>
        </div>

        <div className='item'>
          <p className='label'>Stack Trace</p>
          <p className='value'>{error.stackTrace}</p>
        </div>

        <div className='item description'>

          <div className='description-header'>
            <div className='description-info'>
              <p className='label'>Description</p>
              <p className='value'>{handleDescription(error.description) ? handleDescription(error.description) : '~'}</p>
            </div>
            <MdEdit fontSize={''} className='description-icon' onClick={() => {setDescriptionMode(!descriptionMode)}} />
          </div>

          <div className={`description-body ${descriptionMode ? 'opened' : ''}`}>
            <textarea 
              className='description-input' 
              placeholder='Description' 
              maxLength={250} 
              rows="4" 
              value={descriptionInputValue} 
              onChange={(e) => {setDescriptionInputValue(e.target.value)}}
            />

            <div className='description-btn-container'>
              <p className={`description-btn ${descriptionLoader ? 'loading' : ''}`} onClick={async () => {
                if (descriptionInputValue.length > 250)
                  return;
                const response = await setDescriptionAsync(
                  errorId,
                  descriptionInputValue,
                  currentAdminId,
                  currentAdminPermissions,
                  setDescriptionLoader,
                  setModelMessage
                );

                if (response) {
                  updateErrorDescription();
                  setDescriptionMode(false);
                }
              }}>
                Done
              </p>
              <div className={`description-input-loader ${descriptionLoader ? 'active' : ''}`}></div>
            </div>
          </div>

        </div>

        <div className='item'>
          <p className='label'>Params</p>

          <pre className='json-container'>
            <p className='item-data-header'>
              {
                error.params ? 'parameters' : 'no parameters'
              } 

              <Tooltip title={tooltipText}>
                <CopyAllIcon fontSize={'small'} className={`icon ${error.params ? '' : 'not-visible'}`} onClick={handleClick} onMouseLeave={() => {setTooltipText("Copy")}}/>
              </Tooltip>
            </p>

            {dataJson()}
          </pre>

        </div>

        <div className='item error-code'>
          <p className='label'>Error Code</p>
          <p className='value'>{error.errorKey}</p>
        </div>
        
        <div className='item error-history'>
          <p className='label'>View History</p>

          <Link to={`/transactions-history/${errorId}/Errors`}>
            <p className='value'>history</p>
          </Link>

        </div>

        <div className='item date'>
          <p className='label'>Date</p>
          <p className='value'>{getDate(error.date)}</p>
        </div>

        <div className={`item btns ${status ? 'hidden' : ''}`}>
          <div className='btn-container'>
            <ContainedButton 
              title={'Set As Handled'}
              onclickHandler={async() => {
                const response = await setErrorAsHandled(
                  errorId,
                  currentAdminId,
                  currentAdminPermissions,
                  setModelMessage,
                  setLoader
                );

                if (response) {
                  updateErrorStatus();
                }
              }}
            />
          </div>
        </div>

      </div>

    </div>
  )
}
