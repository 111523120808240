// base
import React, { useState, useEffect } from 'react'
import './transactions.css';
import { useParams } from 'react-router-dom';

// components
import TransactionsHeader from '../../../components/transactions_components/transactions_header/TransactionsHeader';
import TransactionsBody from '../../../components/transactions_components/transactions_body/TransactionsBody';
import Loading from '../../../components/loading/Loading';
import Pagination from '@mui/material/Pagination';

// logic
import {
  TransactionTypes,
  getPagesCount,
  getTransactions
} from './transactionsLogic'

export default function Transactions() {

  const { id, table } = useParams();
  const [transactionsData, setTransactionsData] = useState([]);
  const [pagesCount, setPagesCount] = useState(-1);
  const [currentPageNumber, setCurrentPageNumber] = useState(1); // تعيين 1 كقيمة ابتدائية
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    const fetchPagesCount = async () => {
      try {
        setLoader(true);
        const data = await getPagesCount(id, getTransactionsType());
        if (data !== null && data >= 0) {
          setPagesCount(data);
          setCurrentPageNumber(1);
        }
      } catch (error) {
        console.error('An error occurred while fetching pages count:', error);
      } finally {
        setLoader(false);
      }
    };

    fetchPagesCount();
  }, [id, table]);

  useEffect(() => {
    if (pagesCount > 0) {
      const fetchTransactions = async () => {
        try {
          setLoader(true);
          const data = await getTransactions(id, currentPageNumber, getTransactionsType());
          if (data) {
            setTransactionsData(data);
          }
        } catch (error) {
          console.error('An error occurred while fetching transactions:', error);
        } finally {
          setLoader(false);
        }
      };

      fetchTransactions();
    }
  }, [id, table, currentPageNumber, pagesCount]);

  const getTransactionsType = () => {
    if (table === 'additions')
      return TransactionTypes.ADDITIONS;
    else if (table === 'updates')
      return TransactionTypes.UPDATES;
    else if (table === 'deletions')
      return TransactionTypes.DELETIONS;

    return null;
  }

  const handlePaginationChange = (event, value) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setCurrentPageNumber(value)
  }
  
  return (
    <div className='transactions-page-container'>
      <div className={`loading-container ${loader ? 'active' : ''}`}>
        <Loading isVisible={loader} />
      </div>

      <div className='transactions-header-container'>
        <TransactionsHeader 
          count={transactionsData.length}
          type={getTransactionsType()}
        />
      </div>

      <div className='transactions-body-container'>
        <TransactionsBody transactions={transactionsData} type={getTransactionsType()} />
      </div>

      <div className={`transactions-pagination-container ${pagesCount <= 0 ? 'hidden' : ''}`}>
        <Pagination 
          count={pagesCount} 
          page={currentPageNumber}
          variant="outlined" 
          shape="rounded" 
          color="primary" 
          showFirstButton 
          showLastButton 
          size="small"
          onChange={handlePaginationChange}
        />
      </div>
    </div>
  )
}
