import React from 'react';
import './emailVerification.css';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TextInput from '../../inputs/text_input/TextInput'
import Messagelabel from '../../message_label/MessageLabel'
import ContainedButton from '../../inputs/buttons/contained_button/ContainedButton';
import TextButton from '../../inputs/buttons/text_button/TextButton';
import Loading from '../../loading/Loading'
import {
  validateEmail,
  handleCancel,
  onNextButtonClickHandlerAsync
} from './emailVerificationLogic';

export default function EmailVerification({ title, value, onValueChangeHandler, onCodeReceivingHandler, stepsState }) {

  const navigate = useNavigate();
  const [error, setError] = useState({isVisible: false, message: ''})
  const [loader, setLoader] = useState(false);

  return (
    <div className='email-verification-component'>
      <h2 className='email-verification-title'>Forget Password</h2>

      <div className='email-verification-message'>
        <Messagelabel message={error.message} isVisible={error.isVisible} type='error' />
      </div>
      
      <TextInput 
        title={title}
        value={value}
        onChangeHandler={onValueChangeHandler}
        validateInput={validateEmail}
        mandatory={true}
        onEnter={async () => {await onNextButtonClickHandlerAsync(value, setLoader, setError, onCodeReceivingHandler, stepsState)}}
      />

      <div className='email-verification-buttons-container'>
        <div className='email-verification-cancel-button'>
          <TextButton title='Cancel' onclickHandler={() => {handleCancel(navigate)}}/>
        </div>

        <div className='email-verification-next-button'>
          <ContainedButton title='Next' onclickHandler={async () => {await onNextButtonClickHandlerAsync(value, setLoader, setError, onCodeReceivingHandler, stepsState)}}/>
        </div>

      </div>

      <Loading isVisible={loader} />
    </div>
  )
}
