import React from 'react'
import './resetPassword.css';
import { useState } from 'react';
import EmailVerification from '../../components/reset_password_components/email_verification/EmailVerification';
import CodeVerification from '../../components/reset_password_components/code_verification/CodeVerification';
import PasswordVerification from '../../components/reset_password_components/password_verification/PasswordVerification';

import {
  onChangeHandler
} from './resetPasswordLogic';

export default function ResetPassword() {

  const[steps, setSteps] = useState('1');
  const[userInput, setUserInput] = useState({email: '', code: ''})


  return (
    <div className='reset-password-component'>

      <div className='reset-password-form'>

        {steps === '1' && (
          <EmailVerification 
          title={'Email address'} 
          value={userInput.email}
          onValueChangeHandler={(newValue) => {onChangeHandler('email', newValue, setUserInput)}}
          onCodeReceivingHandler={(newValue) => {onChangeHandler('code', newValue, setUserInput)}}
          stepsState={setSteps}
        />
        )}

        {steps === '2' && (
          <CodeVerification 
            title={'Code'}
            code={userInput.code}
            email={userInput.email}
            stepsState={setSteps}
          />
        )}

        {steps === '3' && (
          <PasswordVerification
            email={userInput.email}
            stepsState={setSteps}
          />
        )}

      </div>

    </div>
  )
}
