// base
import React from 'react'

// context
import { AdditionsProvider } from './additionsContext'

// components
import AdditionsMain from '../../components/additions_components/additions_main/AdditionsMain';

export default function Additions() {
  return (
    <AdditionsProvider>
      <AdditionsMain />
    </AdditionsProvider>
  )
}
