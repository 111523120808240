// base
import React from 'react'
import './operationsHistoryTimeline.css';

// components
import OperationsHistoryItem from '../operations_history_item/OperationsHistoryItem';

import {
  TimelineItemType
} from '../../../pages/operations_history/operations_history/operationsHistoryLogic';

export default function OperationsHistoryTimeline({ transactions }) {

  return (
    <div className='operations-history-timeline-component'>

      <div className='line'></div>

      {transactions.map((transaction, index) => (
        <div className='operations-history-timeline-item-container' key={transaction.transactionId}>
          <OperationsHistoryItem 
            _index={index} 
            type={
              transaction.transactionType === 1
                ? TimelineItemType.ADD
                : transaction.transactionType === 2
                ? TimelineItemType.UPDATE
                : transaction.transactionType === 3
                ? TimelineItemType.DELETE
                : null
            }
            username={transaction.userName}
            tableName={transaction.tableName}
            data={transaction.transactionData}
            date={transaction.transactionDate}
          />
        </div>
      ))}

      <div className={`end-point-for-no-items ${transactions.length === 0 ? 'visible' : ''}`}></div>

    </div>
  )
}
