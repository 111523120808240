import React from 'react';
import './analyticalCard.css';

export default function AnalyticalCard({title, icon: Icon, value, isActive, onClick }) {
  return (
    <div className={`analytical-card-component ${isActive ? 'active' : ''}`} onClick={onClick}>

      <p className='analytical-card-title'>      
        {title}
      </p>

      <div className='icon-value-container'>

        <div className='anayltical-card-icon-container'>
          <Icon className='analytical-card-icon' fontSize={''} />
        </div>
        
        <p className='analytical-card-value'>{value}</p>

      </div>

    </div>
  )
}
